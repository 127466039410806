// redux/features/breadcrumbsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState: {
    items: [],
    currentButton: '',
  },
  reducers: {
    setBreadcrumbs: (state, action) => {
      state.items = action.payload;
    },
    appendBreadcrumb: (state, action) => {
      state.items.push(action.payload);
    },
    setButtonName: (state, action) => {
      state.currentButton = action.payload;
    },
  },
});

export const { setBreadcrumbs, appendBreadcrumb, setButtonName } = breadcrumbsSlice.actions;
export default breadcrumbsSlice.reducer;
