// FormTemplatePDF.js
import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  header: {
    backgroundColor: "black",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 35,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  headerText: {
    fontSize: 14,
  },
  footer: {
    backgroundColor: "black",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 35,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerText: {
    fontSize: 12,
  },

  descriptionSection: {
    // marginTop: 35,
  },

  descriptionText: {
    fontSize: 11,
  },

  testsContent: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    rowGap: 5,
  },

  testHeader: {
    height: 30,
    backgroundColor: "black",
    display: "flex",
    justifyContent: "center",
    paddingHorizontal: 5,
  },

  testHeaderText: {
    color: "white",
    fontSize: 14,
  },

  testFunctionsContent: {
    marginTop: 5,
  },

  content: {
    marginTop: 50, // adjust based on header height
    marginBottom: 50, // adjust based on footer height
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 20,
    marginBottom: 10,
  },
  text: {
    fontSize: 14,
  },
  inputField: {
    border: "1px solid #000",
    padding: 5,
    marginTop: 5,
    marginBottom: 10,
    fontSize: 12,
  },
  labelText: {
    fontSize: 12,
    marginBottom: 3,
  },
});

// Create Document Component
const FormTemplatePDF = ({ formTemplate }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.headerText}>{formTemplate.title}</Text>
        </View>
        <View style={styles.content}>
          <View style={styles.descriptionSection}>
            <Text style={styles.descriptionText}>
              {formTemplate.description}
            </Text>
          </View>

          <View style={styles.testsContent}>
            {formTemplate?.tests?.map((test, index) => (
              <View key={index}>
                <View style={styles.testHeader}>
                  <Text style={styles.testHeaderText}>{test.title}</Text>
                </View>
                <View style={styles.testFunctionsContent}>
                  {test.test_functions?.map((testFunction, idx) => (
                    <View key={idx}>
                      <Text style={styles.descriptionText}>
                        {testFunction.display_name}
                      </Text>
                      <Text style={styles.labelText}>
                        Input Field: __________________
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            ))}
          </View>

          {/* <View style={styles.footer}>
            <Text style={styles.footerText}>Page 1</Text>
          </View> */}
        </View>
      </Page>
    </Document>
  );
};

export default FormTemplatePDF;
