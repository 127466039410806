import { palette } from "../palette";

export const MuiSelect = {
  styleOverrides: {
    root: {
      borderRadius: "13px",
      borderWidth: "1.41px",
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: palette.primary.lightGray,
      },

      "&:hover fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: palette.primary.gray,
      },

      "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: palette.primary.main,
        borderRadius: "13px",
      },
    },
  },
};
