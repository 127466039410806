import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import ReactQuill from "react-quill";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, useTheme, FormControlLabel } from "@mui/material";
import { palette } from "../../theme/palette.js";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { testStatuses } from "../../constants/data.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createNewTest, editTest } from "../../redux/features/test.js";
import { getAllCompanies } from "../../redux/features/company.js";
import { getAllTags } from "../../redux/features/tag.js";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const TestForm = ({ test }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companies = useSelector((state) => state.company.companies);
  const tagsList = useSelector((state) => state.tag.tagsList);

  const extractAssociatedIds = (arrayList) => {
    return arrayList?.map((item) => item.id);
  };

  const [newTest, setNewTest] = useState({
    title: test?.title || "",
    version: test?.version || "",
    company_ids: extractAssociatedIds(test?.companies) || [],
    label_ids: extractAssociatedIds(test?.labels) || [],
    description: test?.description || "",
    instructions: test?.instructions || "",
    instructionsUrl: test?.instruction_url || "",
    status: test?.status || "review",
    instruction_audio: test?.instruction_audio || null,
    archive: test?.archive,
  });

  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [audioFileLink, setAudioFileLink] = useState(null);

  const audioFileRef = useRef();

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "archive") {
      setNewTest((prevState) => ({
        ...prevState,
        [name]: event.target.checked,
      }));

      return;
    }

    if (name === "company_ids") {
      setNewTest({
        ...newTest,
        company_ids: [value],
      });
    } else {
      setNewTest({
        ...newTest,
        [name]: value,
      });
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const audioURL = URL.createObjectURL(file);
      setAudioFileLink(audioURL);
      setNewTest((prevTest) => ({
        ...prevTest,
        instruction_audio: file,
      }));
    }
  };
  const handleDescriptionChange = (value) => {
    setNewTest((prevState) => ({
      ...prevState,
      description: value,
    }));
  };

  const handleInstructionsChange = (value) => {
    setNewTest((prevState) => ({
      ...prevState,
      instructions: value,
    }));
  };

  const handlePlayStopAudioFile = () => {
    if (audioFileRef.current) {
      if (isPlayingAudio) {
        audioFileRef.current.pause();
      } else {
        audioFileRef.current.play();
      }
      setIsPlayingAudio(!isPlayingAudio);
    } else {
      console.error("Audio element is not properly referenced.");
    }
  };

  const handleClearAudioFile = () => {
    if (audioFileRef.current) {
      audioFileRef.current.pause();
      audioFileRef.current.currentTime = 0;
    }
    setAudioFileLink(null);
    setNewTest((prevTest) => ({
      ...prevTest,
      instruction_audio: null,
    }));
    setIsPlayingAudio(false);
  };

  useEffect(() => {
    if (audioFileRef.current) {
      audioFileRef.current.addEventListener("ended", () =>
        setIsPlayingAudio(false)
      );
    }
  }, []);

  useEffect(() => {
    dispatch(getAllCompanies(""));
    dispatch(getAllTags(""));
  }, [dispatch]);

  const handleTestSubmit = async (event) => {
    event.preventDefault();

    if (newTest.title === "") {
      toast.error("Please enter test title");
    } else if (newTest.company_id === "") {
      toast.error("Please select a company");
    } else {
      const formData = new FormData();
      formData.append("test[title]", newTest.title);
      formData.append("test[version]", newTest.version);
      formData.append("test[description]", newTest.description);
      formData.append("test[instructions]", newTest.instructions);
      formData.append("test[instruction_url]", newTest.instructionsUrl);
      formData.append("test[status]", newTest.status);
      formData.append("test[instruction_audio]", newTest.instruction_audio);
      formData.append("test[archive]", newTest.archive);
      newTest.label_ids.forEach((label, index) => {
        formData.append(`test[label_ids][${index}]`, label);
      });

      newTest.company_ids.forEach((company_id, index) => {
        formData.append(`test[company_ids][${index}]`, company_id);
      });

      try {
        if (test === null) {
          await dispatch(createNewTest(formData)).unwrap();
          toast.success("Test added successfully");
        } else {
          await dispatch(
            editTest({ test: formData, test_id: test.id })
          ).unwrap();
          toast.success("Test updated successfully");
        }
        navigate("/tests");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <Box>
      <Grid
        container
        columnSpacing={10}
        rowSpacing={4}
        paddingRight={20}
        paddingTop={5}
      >
        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Title
          </Typography>
          <TextField
            value={newTest.title}
            fullWidth
            id="title"
            name="title"
            placeholder="Test Title"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>
        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Version
          </Typography>
          <TextField
            disabled
            value={newTest.version}
            fullWidth
            id="version"
            name="version"
            placeholder="Version"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Account
          </Typography>

          <FormControl fullWidth>
            <Select
              displayEmpty
              id="company_ids"
              name="company_ids"
              value={newTest.company_ids[0] || ""}
              onChange={handleChange}
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <span style={{ color: theme.palette.primary.gray }}>
                      Select Account
                    </span>
                  );
                }
                const selectedCompany =
                  companies &&
                  companies?.find((company) => company.id === selected);
                return selectedCompany?.first_name;
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {companies &&
                companies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.first_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Tags
          </Typography>

          <FormControl fullWidth>
            <Select
              displayEmpty
              multiple
              id="label_ids"
              name="label_ids"
              value={newTest.label_ids}
              onChange={handleChange}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <span style={{ color: theme.palette.primary.gray }}>
                      Select Tag
                    </span>
                  );
                }

                const selectedTags = tagsList
                  .filter((tag) => selected.includes(tag.id))
                  .map((tag) => tag.title)
                  .join(", ");
                return selectedTags;
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {tagsList &&
                tagsList.map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    <Checkbox checked={newTest.label_ids.includes(tag.id)} />
                    <ListItemText primary={tag.title} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8} md={12}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Description
          </Typography>

          <ReactQuill
            value={newTest.description}
            onChange={handleDescriptionChange}
            placeholder="Description"
            style={{ backgroundColor: "#fff", borderRadius: "4px" }}
          />
        </Grid>

        <Grid item xs={8} md={12}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Instructions
          </Typography>
          <ReactQuill
            value={newTest.instructions}
            onChange={handleInstructionsChange}
            placeholder="Instructions"
            style={{ backgroundColor: "#fff", borderRadius: "4px" }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Instructions URL
          </Typography>
          <TextField
            value={newTest.instructionsUrl}
            fullWidth
            id="instructionsUrl"
            name="instructionsUrl"
            placeholder="Instructions URL"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Status
          </Typography>
          <FormControl fullWidth>
            <Select
              displayEmpty
              id="status"
              name="status"
              variant="outlined"
              value={newTest.status}
              onChange={handleChange}
              inputProps={{ "aria-label": "Without label" }}
            >
              {testStatuses.map((status, index) => (
                <MenuItem key={index} value={status.value}>
                  {status.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={8}
          md={6}
          sx={{
            display: "flex",
            columnGap: 1,
            alignItems: "end",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            sx={{
              width: "fit-content",
              paddingX: 2,
              boxShadow: "none",
              backgroundColor: theme.palette.secondary.gray,
              color: theme.palette.primary.main,
              ":hover": {
                backgroundColor: theme.palette.secondary.gray,
                color: theme.palette.primary.main,
              },
            }}
            variant="contained"
            component="label"
            startIcon={<FileUploadOutlinedIcon />}
          >
            Select File
            <input type="file" hidden onChange={handleFileSelect} />
          </Button>
          <Button
            sx={{
              width: "fit-content",
              paddingX: 2,
              boxShadow: "none",
              backgroundColor: theme.palette.secondary.gray,
              color: theme.palette.primary.main,
              ":hover": {
                backgroundColor: theme.palette.secondary.gray,
                color: theme.palette.primary.main,
              },
              "&.Mui-disabled": {
                backgroundColor: theme.palette.secondary.gray,
                color: theme.palette.primary.main,
                opacity: 1,
                boxShadow: "none",
              },
            }}
            variant="contained"
            component="label"
            startIcon={<PlayCircleOutlineOutlinedIcon />}
            onClick={handlePlayStopAudioFile}
            disabled={!newTest.instruction_audio}
          >
            {/* Play/Stop Audio */}
            {isPlayingAudio ? "Stop Audio" : "Play Audio"}
          </Button>
          <Button
            sx={{
              width: "fit-content",
              paddingX: 2,
              boxShadow: "none",
              backgroundColor: theme.palette.secondary.gray,
              color: theme.palette.primary.main,
              ":hover": {
                backgroundColor: theme.palette.secondary.gray,
                color: theme.palette.primary.main,
              },
              "&.Mui-disabled": {
                backgroundColor: theme.palette.secondary.gray,
                color: theme.palette.primary.main,
                opacity: 1,
                boxShadow: "none",
              },
            }}
            variant="contained"
            component="label"
            startIcon={<ClearAllOutlinedIcon />}
            onClick={handleClearAudioFile}
            disabled={!newTest.instruction_audio}
          >
            Clear Audio
          </Button>
          {newTest.instruction_audio && (
            <audio
              ref={audioFileRef}
              src={audioFileLink}
              controls
              style={{ display: "none" }}
            />
          )}
        </Grid>
        <Grid
          item
          xs={8}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                value={newTest.archive}
                checked={newTest.archive}
                id="archive"
                name="archive"
                color="primary"
              />
            }
            label="Archived"
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginRight: 10,
          marginTop: 10,
          minHeight: "100%",
          columnGap: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={handleTestSubmit}
          sx={{ width: 160 }}
        >
          Save Test
        </Button>
      </Box>
    </Box>
  );
};

export default TestForm;
