import { palette } from "../palette";

export const MuiTextField = {
  styleOverrides: {
    root: {
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          display: "none",
        },
      "& input[type=number]": {
        MozAppearance: "textfield",
      },

      "& .MuiOutlinedInput-root": {
        backgroundColor: palette.primary.white,

        "& fieldset": {
          borderColor: palette.primary.lightGray,
          borderWidth: "1.41px",
        },
        "&:hover fieldset": {
          borderColor: palette.primary.gray,
        },
        "&.Mui-focused fieldset": {
          borderColor: palette.primary.main,
          borderRadius: "13px",
          outline: "none",
        },
        "&.Mui-disabled fieldset": {
          borderColor: palette.primary.lightGray,
        },

        "& .MuiSvgIcon-root": {
          fill: palette.primary.main,
        },

        "& .MuiOutlinedInput-input": {
          display: "flex",
          alignItems: "center",
          padding: "6px 20px  !important",
          height: "40px",
          color: palette.primary.black,
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "left",
        },
        color: palette.primary.black,
        fontSize: "15px",
        fontWeight: 500,
        fontFamily: "inter, sans-serif",
        borderRadius: "13px",
        borderWidth: "2px",

        "& .MuiInputAdornment-root": {
          color: palette.primary.main,
        },

        "&.Mui-focused": {
          "& .MuiOutlinedInput-input": {
            color: palette.primary.gray,
            background: palette.primary.white,
            borderRadius: "13px",
          },
          background: palette.primary.white,

          "& .MuiSvgIcon-root": {
            fill: palette.primary.main,
          },
          "& .MuiInputAdornment-root": {
            color: palette.primary.main,
          },
          "& ::placeholder": {
            color: palette.primary.gray,
            textAlign: "left",
            fontWeight: `${700} `,
          },
        },
        "& ::placeholder": {
          color: palette.primary.gray,
          textAlign: "left ",
          fontWeight: 700,
        },
      },

      "& .MuiFormHelperText-root": {
        fontSize: "11px",
        fontWeight: 600,
        backgroundColor: "transparent",
      },
      "&  .MuiFormHelperText-root.Mui-error": {
        backgroundColor: "transparent",
        margin: 0,
        paddingLeft: 10,
      },
      input: {
        "&.Mui-disabled": {
          WebkitTextFillColor: `${palette.primary.gray} !important`,
        },
      },
    },
  },
};
