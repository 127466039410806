export const uberAdminUserRoles = [
  { title: "Account Admin", value: 3 },
  { title: "Reviewer", value: 4 },
  { title: "Examiner", value: 5 },
];

export const testStatuses = [
  {
    title: "Review",
    value: "review",
  },
  {
    title: "Approve",
    value: "approved",
  },
  {
    title: "Public",
    value: "public",
  },
];

export const dataTypes = [
  {
    title: "Decimal",
    value: "Decimal",
  },
  {
    title: "Image",
    value: "Image",
  },
  {
    title: "Editable Image",
    value: "EditableImage",
  },
  {
    title: "Integer",
    value: "Integer",
  },
  {
    title: "Notes",
    value: "Notes",
  },
  {
    title: "Pick List",
    value: "Picklist",
  },
  {
    title: "Read Only Text",
    value: "ReadOnlyText",
  },
  {
    title: "String",
    value: "String",
  },
  {
    title: "Boolean",
    value: "Boolean",
  },
  {
    title: "Signature",
    value: "Signature",
  },
  {
    title: "Date Field",
    value: "Date",
  },
];

export const functionIntegerFormats = [
  {
    title: "9",
    value: "9",
  },
  {
    title: "99",
    value: "99",
  },
  {
    title: "999",
    value: "999",
  },
  {
    title: "9999",
    value: "9999",
  },
  {
    title: "9999",
    value: "9999",
  },
  {
    title: "99999",
    value: "99999",
  },
  {
    title: "999999",
    value: "999999",
  },
  {
    title: "9999999",
    value: "9999999",
  },
  {
    title: "99999999",
    value: "99999999",
  },
];

export const functionDecimalFormats = [
  {
    title: "9.9",
    value: "9.9",
  },
  {
    title: "9.99",
    value: "9.99",
  },
  {
    title: "9.999",
    value: "9.999",
  },
  {
    title: "9.9999",
    value: "9.9999",
  },
  {
    title: "9.99999",
    value: "9.99999",
  },
  {
    title: "99.9",
    value: "99.9",
  },
  {
    title: "99.99",
    value: "99.99",
  },
  {
    title: "99.999",
    value: "99.999",
  },
  {
    title: "99.9999",
    value: "99.9999",
  },
  {
    title: "99.99999",
    value: "99.99999",
  },
  {
    title: "999.9",
    value: "999.9",
  },
  {
    title: "999.99",
    value: "999.99",
  },
  {
    title: "999.999",
    value: "999.999",
  },
  {
    title: "999.9999",
    value: "999.9999",
  },
  {
    title: "999.99999",
    value: "999.99999",
  },
  {
    title: "9999.9",
    value: "9999.9",
  },
  {
    title: "9999.99",
    value: "9999.99",
  },
  {
    title: "9999.999",
    value: "9999.999",
  },
  {
    title: "9999.9999",
    value: "9999.9999",
  },
  {
    title: "9999.99999",
    value: "9999.99999",
  },
  {
    title: "99999.9",
    value: "99999.9",
  },
  {
    title: "99999.99",
    value: "99999.99",
  },
  {
    title: "99999.999",
    value: "99999.999",
  },
  {
    title: "99999.9999",
    value: "99999.9999",
  },
  {
    title: "99999.99999",
    value: "99999.99999",
  },
];

export const states = [
  {
    title: "AK",
    value: "AK",
  },
  {
    title: "AL",
    value: "AL",
  },
  {
    title: "AR",
    value: "AR",
  },
  {
    title: "AZ",
    value: "AZ",
  },
  {
    title: "CA",
    value: "CA",
  },
  {
    title: "CO",
    value: "CO",
  },
  {
    title: "CT",
    value: "CT",
  },
  {
    title: "DC",
    value: "DC",
  },
  {
    title: "DE",
    value: "DE",
  },
  {
    title: "FL",
    value: "FL",
  },
  {
    title: "GA",
    value: "GA",
  },
  {
    title: "HI",
    value: "HI",
  },
  {
    title: "IA",
    value: "IA",
  },
  {
    title: "ID",
    value: "ID",
  },
  {
    title: "IL",
    value: "IL",
  },
  {
    title: "IN",
    value: "IN",
  },
  {
    title: "KS",
    value: "KS",
  },
  {
    title: "KY",
    value: "KY",
  },
  {
    title: "LA",
    value: "LA",
  },
  {
    title: "MA",
    value: "MA",
  },
  {
    title: "MD",
    value: "MD",
  },
  {
    title: "ME",
    value: "ME",
  },
  {
    title: "MI",
    value: "MI",
  },
  {
    title: "MN",
    value: "MN",
  },
  {
    title: "MO",
    value: "MO",
  },
  {
    title: "MS",
    value: "MS",
  },
  {
    title: "MT",
    value: "MT",
  },
  {
    title: "NC",
    value: "NC",
  },
  {
    title: "ND",
    value: "ND",
  },
  {
    title: "NE",
    value: "NE",
  },
  {
    title: "NH",
    value: "NH",
  },
  {
    title: "NJ",
    value: "NJ",
  },
  {
    title: "NM",
    value: "NM",
  },
  {
    title: "NV",
    value: "NV",
  },
  {
    title: "NY",
    value: "NY",
  },
  {
    title: "OH",
    value: "OH",
  },
  {
    title: "OK",
    value: "OK",
  },
  {
    title: "OR",
    value: "OR",
  },
  {
    title: "PA",
    value: "PA",
  },
  {
    title: "RI",
    value: "RI",
  },
  {
    title: "SC",
    value: "SC",
  },
  {
    title: "SD",
    value: "SD",
  },
  {
    title: "TN",
    value: "TN",
  },
  {
    title: "TX",
    value: "TX",
  },
  {
    title: "UT",
    value: "UT",
  },
  {
    title: "VA",
    value: "VA",
  },
  {
    title: "VT",
    value: "VT",
  },
  {
    title: "WA",
    value: "WA",
  },
  {
    title: "WI",
    value: "WI",
  },
  {
    title: "WV",
    value: "WV",
  },
  {
    title: "WY",
    value: "WY",
  },
];

export const gender = [
  {
    title: "M",
    value: "M"
  },
  {
    title: "F",
    value: "F"
  }
]

export const countries = [
  {
    title: "United States",
    value: "United States"
  },
  {
    title: "Canada",
    value: "Canada"
  }
]
export const statusOptions = [
  {
    title: "Draft",
    value: "draft",
  },
  {
    title: "Edits Needed",
    value: "edits_needed"
  },
  {
    title: "In Review",
    value: "in_review"
  },
  {
    title: "Published",
    value: "published"
  }
]
export const penColors = ["#000000", "#FF0000", "#00FF00", "#0000FF"];
