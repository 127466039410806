import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog.jsx";
import ChangeStatusDialog from "../dialogs/ChangeStatusDialog.jsx";
import { getAllUsers } from "../../redux/features/user";
import { getAllGroups } from "../../redux/features/group";
import { deleteExam } from "../../redux/features/exam";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import Link from "@mui/material/Link";
import PrintIcon from "@mui/icons-material/Print"; // Import Print Icon
import { frontEndApiBaseUrl } from "../../constants/urls.js";
import { statusOptions } from "../../constants/data.js";

const TableList = ({
  exams,
  totalExams,
  page,
  rowsPerPage,
  handleExamListItemClick,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);
  const groups = useSelector((state) => state.group?.groupsList);
  const workers = useSelector((state) => state.user?.usersList);
  const handleDeleteExamClick = (event, exam) => {
    event.preventDefault();
    setSelectedExam(exam);
    setOpenDelete(true);
  };

  const handleChangeStatusClick = (event, exam) => {
    event.preventDefault();
    setSelectedExam(exam);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedExam(null);
  };

  const handleDeleteCancel = () => {
    setOpenDelete(false);
    setSelectedExam(null);
  };

  const handleDeleteConfirmation = async () => {
    if (selectedExam) {
      await dispatch(deleteExam({ exam_id: selectedExam.id }));
      setOpenDelete(false);
      navigate("/exams");
    }
  };

  const getGroupNameById = (groupId) => {
    const group = groups?.find((g) => g.id === groupId);
    return group ? group.name : "Group not found";
  };

  const getSubjectNameById = (subjectId) => {
    const subject = workers?.find((s) => s.id === subjectId);
    return subject
      ? `${subject.first_name} ${subject.last_name}`
      : "Subject not found";
  };

  useEffect(() => {
    dispatch(
      getAllGroups({
        searchQuery: "",
        page: "",
        rowsPerPage: "",
      })
    );
    dispatch(
      getAllUsers({
        searchValue: "",
        page: page,
        rowsPerPage: rowsPerPage,
        role: "subject",
      })
    );
  }, [dispatch, page, rowsPerPage]);

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ marginTop: 2, maxHeight: "10%" }}>
        <Table stickyHeader aria-label="tests table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  width: "10%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Exam Title
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "15%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Worker
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "15%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Group
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "10%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Link
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "5%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Date
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "5%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Status
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "5%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exams?.map((exam) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={exam.id}
                onClick={() => handleExamListItemClick(exam)}
                sx={{
                  cursor: "pointer",
                }}
              >
                <TableCell align="left" sx={{ width: "20%" }}>
                  {exam.exam_title}
                </TableCell>
                <TableCell align="left" sx={{ width: "20%" }}>
                  {getSubjectNameById(exam.subject_id)}
                </TableCell>
                <TableCell align="left" sx={{ width: "20%" }}>
                  {getGroupNameById(exam.group_id)}
                </TableCell>
                <TableCell align="left" sx={{ width: "15%" }}>
                  <Link
                    href={`${frontEndApiBaseUrl}/api/v1/exams/${exam.id}/public_exam`}
                    underline="hover"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    target="_blank" // Add this line
                    rel="noopener noreferrer" // Add this line for security
                  >
                    {"Exam Link"}
                  </Link>
                </TableCell>
                <TableCell align="left" sx={{ width: "15%" }}>
                  {new Date(exam.created_at).toLocaleDateString("en-GB")}
                </TableCell>
                <TableCell align="left" sx={{ width: "20%" }}>
                  {statusOptions.find((option) => option.value === exam?.status)
                    ?.title || "Unknown Status"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ display: "flex", alignItems: "center", width: "30%" }}
                >
                  <Tooltip title="Change Status">
                    <IconButton
                      sx={{ p: "5px" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleChangeStatusClick(event, exam);
                      }}
                      id={exam.id}
                    >
                      <SettingsIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Tooltip>
                  {exam.status === "draft" && (
                    <Tooltip title="Delete">
                      <IconButton
                        sx={{ p: "5px" }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteExamClick(event, exam);
                        }}
                        id={exam.id}
                      >
                        <DeleteOutlinedIcon sx={{ color: "red" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Print">
                    <IconButton
                      sx={{ p: "5px" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        navigate(`/exam-report-viewer/${exam.id}`);
                      }}
                      id={exam.id}
                    >
                      <PrintIcon sx={{ color: "blue" }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteConfirmationDialog
        open={openDelete}
        handleCancel={handleDeleteCancel}
        titleText="Confirm Delete"
        contentText="Are you sure you want to delete"
        title={selectedExam?.exam_title}
        suffixText="from exams list?"
        handleDelete={handleDeleteConfirmation}
      />
      <ChangeStatusDialog
        open={open}
        handleCancel={handleCancel}
        titleText="Status Options"
        exam={selectedExam}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalExams}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
          }}
        />
      </Box>
    </Box>
  );
};

export default TableList;
