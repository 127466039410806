import Box from "@mui/material/Box";
import TestForm from "./TestForm.jsx";
import { useSelector } from "react-redux";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";

const EditTest = () => {
  const test = useSelector((state) => state.test.currentTest);
  const isLoading = useSelector((state) => state.test.isLoading);

  return (
      <Box>
      {isLoading ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <TestForm test={test} />
      )}
      </Box>
  );
};

export default EditTest;
