// components/form_template/FormTemplateDetails.jsx
import React, { useEffect, useState } from "react";
import { Box, Paper, Button, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFormTemplate } from "../../redux/features/form_template.js";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";
import ReportPreview from "./ReportPreview.jsx";
import { pdf } from "@react-pdf/renderer";
import FormTemplatePDF from "./FormTemplatePDF";
import TemplateForm from "./TemplateForm.jsx";
import StimulsoftReportViewer from "../common/StimulsoftReportViewer.jsx"; // Import StimulsoftReportDesigner
import { Add as AddIcon } from "@mui/icons-material";
import EditReportDesign from "./EditReportDesign.jsx";
import LinkTests from "./LinkTests.jsx";
import {
  appendBreadcrumb,
  setButtonName,
} from "../../redux/features/breadcrumbsSlice";

const FormTemplateDetails = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();

  const formTemplate = useSelector(
    (state) => state.formTemplate.currentFormTemplate
  );
  const isLoading = useSelector((state) => state.formTemplate.isLoading);

  const [selectedTab, setSelectedTab] = useState(1);

  // const handleEditReportTemplateClick = (event, formTemplate) => {
  //   event.preventDefault();
  //   navigate(`/form-templates/edit-report-design/${formTemplate.id}`);
  // };

  // const handlePDFGenerateClick = async (event, formTemplate) => {
  //   event.preventDefault();

  //   const doc = <FormTemplatePDF formTemplate={formTemplate} />;
  //   const asPdf = pdf([]); // {} is important, throws without an argument
  //   asPdf.updateContainer(doc);
  //   const blob = await asPdf.toBlob();

  //   const url = URL.createObjectURL(blob);
  //   window.open(url, "_blank");
  // };

  useEffect(() => {
    dispatch(
      getFormTemplate({
        formTemplateId: id,
      })
    );
  }, [dispatch, id]);

  const handleTabChange = (value) => {
    setSelectedTab(value);

    let breadcrumbName = "";
    let buttonName = "";

    switch (value) {
      case 1:
        buttonName = "Edit Form";
        break;
      case 2:
        buttonName = "Report Preview";
        break;
      case 3:
        buttonName = "Report Design";
        break;
      case 4:
        buttonName = "Form Tests";
        break;
      default:
        buttonName = "Unknown";
        break;
    }

    // Update button name in Redux
    dispatch(setButtonName(buttonName));

    // Optionally, you can also update breadcrumbs if needed
    breadcrumbName = buttonName;
    dispatch(appendBreadcrumb(breadcrumbName));
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          columnGap: 2,
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleTabChange(1)}
            sx={{
              boxShadow: "none",
              width: 150,
              backgroundColor:
                selectedTab === 1 ? theme.palette.primary.main : "#E7E8EC",
              color: selectedTab === 1 ? "white" : theme.palette.primary.main,
              ":hover": {
                backgroundColor:
                  selectedTab === 1 ? theme.palette.primary.main : "#E7E8EC",
                color: selectedTab === 1 ? "white" : theme.palette.primary.main,
              },
            }}
          >
            Edit Form Info
          </Button>
          <Button
            variant="contained"
            onClick={() => handleTabChange(4)}
            sx={{
              boxShadow: "none",
              width: 150,
              backgroundColor:
                selectedTab === 4 ? theme.palette.primary.main : "#E7E8EC",
              color: selectedTab === 4 ? "white" : theme.palette.primary.main,
              ":hover": {
                backgroundColor:
                  selectedTab === 4 ? theme.palette.primary.main : "#E7E8EC",
                color: selectedTab === 4 ? "white" : theme.palette.primary.main,
              },
            }}
          >
            Form Tests
          </Button>
          {/* <Button
            variant="contained"
            onClick={(event) => handlePDFGenerateClick(event, formTemplate)}
            sx={{
              boxShadow: "none",
              width: 150,
              backgroundColor:
                selectedTab === 2 ? theme.palette.primary.main : "#E7E8EC",
              color: selectedTab === 2 ? "white" : theme.palette.primary.main,
              ":hover": {
                backgroundColor:
                  selectedTab === 2 ? theme.palette.primary.main : "#E7E8EC",
                color: selectedTab === 2 ? "white" : theme.palette.primary.main,
              },
            }}
          >
            Report Preview
          </Button> */}
          <Button
            variant="contained"
            onClick={() => handleTabChange(3)}
            sx={{
              boxShadow: "none",
              width: 150,
              backgroundColor:
                selectedTab === 3 ? theme.palette.primary.main : "#E7E8EC",
              color: selectedTab === 3 ? "white" : theme.palette.primary.main,
              ":hover": {
                backgroundColor:
                  selectedTab === 3 ? theme.palette.primary.main : "#E7E8EC",
                color: selectedTab === 3 ? "white" : theme.palette.primary.main,
              },
            }}
          >
            Report Design
          </Button>

          <Button
            variant="contained"
            onClick={() => handleTabChange(5)} // Change this tab to show StimulsoftReportDesigner
            sx={{
              boxShadow: "none",
              width: 150,
              backgroundColor:
                selectedTab === 5 ? theme.palette.primary.main : "#E7E8EC",
              color: selectedTab === 5 ? "white" : theme.palette.primary.main,
              ":hover": {
                backgroundColor:
                  selectedTab === 5 ? theme.palette.primary.main : "#E7E8EC",
                color: selectedTab === 5 ? "white" : theme.palette.primary.main,
              },
            }}
          >
            Designer View
          </Button>
        </Box>
      </Box>

      {isLoading ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <Box
          sx={{
            padding: 3,
            marginTop: 3,
            height: "100%",
            borderRadius: "16px",
            boxShadow: "0 0 15px 0 #0000001a",
            overflow: "scroll",
          }}
          component={Paper}
        >
          {formTemplate ? (
            <>
              {selectedTab === 1 ? (
                <TemplateForm formTemplate={formTemplate} />
              ) : selectedTab === 2 ? (
                <ReportPreview />
              ) : selectedTab === 3 ? (
                <EditReportDesign />
              ) : selectedTab === 4 ? (
                <LinkTests formTemplate={formTemplate} />
              ) : selectedTab === 5 ? (
                <StimulsoftReportViewer formTemplateId={formTemplate.id} />
              ) : null}
            </>
          ) : (
            <GradientCircularProgress open={isLoading} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default FormTemplateDetails;
