import React, { useEffect, useRef } from "react";
import { Stimulsoft } from "stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor";
import "stimulsoft-reports-js/Scripts/stimulsoft.designer";
import "stimulsoft-reports-js/Scripts/stimulsoft.reports";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getFormTemplate } from "../../redux/features/form_template.js";

const StimulsoftReportViewer = ({ formTemplateId }) => {
  const dispatch = useDispatch();
  const designerRef = useRef(null);
  const currentFormTemplate = useSelector(
    (state) => state.formTemplate.currentFormTemplate
  );

  console.log("currentFormTemplate", currentFormTemplate);

  useEffect(() => {
    if (currentFormTemplate) {
      try {
        console.log("Initializing Stimulsoft viewer");
        const viewer = new Stimulsoft.Viewer.StiViewer(
          null,
          "StiViewer",
          false
        );

        Stimulsoft.StiOptions.WebServer.url = "https://adaptar.workerfit.com";

        console.log("Creating new report instance");
        const report = new Stimulsoft.Report.StiReport();

        // Utility function to generate a GUID
        function generateGuid() {
          return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
              var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
              return v.toString(16);
            }
          );
        }

        // Initialize merged report object
        const mergedReport = {
          ReportVersion: "2024.3.3",
          ReportGuid: generateGuid(),
          ReportName: "Merged Report",
          ReportAlias: "Merged Report",
          ReportFile: "/report/MergedReport.mrt",
          ReportCreated: `/Date(${Date.now()})/`,
          ReportChanged: `/Date(${Date.now()})/`,
          EngineVersion: "EngineV2",
          CalculationMode: "Interpretation",
          ReportUnit: "Centimeters",
          Dictionary: {
            DataSources: {},
            Databases: {},
          },
          Pages: [
            {
              Ident: "StiPage",
              Name: "Page1",
              Guid: generateGuid(), // Replaced with provided GUID
              Interaction: { Ident: "StiInteraction" },
              Border: ";;2;;;;;solid:Black",
              Brush: "solid:",
              PageWidth: 21.01,
              PageHeight: 29.69,
              Watermark: { TextBrush: "solid:50,0,0,0" },
              Components: {
                0: {
                  Ident: "StiPageHeaderBand", // Replaced with provided Ident
                  Name: "PageHeaderBand1", // Replaced with provided Name
                  CanGrow: true,
                  ClientRectangle: "0,0.4,19.01,0.8", // Replaced with provided ClientRectangle
                  Interaction: { Ident: "StiInteraction" },
                  Border: ";;;;;;;solid:Black",
                  Brush: "solid:",
                  Components: {
                    0: {
                      Ident: "StiText",
                      Name: "Text1",
                      ClientRectangle: "0,0.2,8.2,0.6", // Replaced with provided ClientRectangle
                      Interaction: { Ident: "StiInteraction" },
                      Text: { Value: currentFormTemplate.title }, // Replaced with provided Value
                      Border: ";;;;;;;solid:Black",
                      Brush: "solid:",
                      TextBrush: "solid:Black",
                      Font: ";14;Bold;",
                    },
                  },
                },
              },
            },
          ],
        };

        // Helper function to get the next available key
        function getNextKey(existingDataSources) {
          let maxKey = -1;
          Object.keys(existingDataSources).forEach((key) => {
            const numKey = parseInt(key, 10);
            if (!isNaN(numKey) && numKey > maxKey) {
              maxKey = numKey;
            }
          });
          return maxKey + 1;
        }

        // Helper function to merge data sources with unique aliases for new sources
        function mergeDataSources(existing, newDataSources) {
          Object.entries(newDataSources).forEach(([key, value]) => {
            // Check if a data source with the same Ident, NameInSource, and SqlCommand already exists
            const existingSource = Object.values(existing).find(
              (source) =>
                source.Ident === value.Ident &&
                source.NameInSource === value.NameInSource &&
                source.SqlCommand === value.SqlCommand
            );

            if (!existingSource) {
              // Generate a new key for the new data source
              const newKey = Object.keys(existing).length;
              const newAlias = `${value.Alias}_${newKey}`; // Example: Body Size Measures - Fetch Test_1

              // Add the new data source with a unique alias
              existing[newKey] = {
                ...value,
                Alias: newAlias,
                Name: `${value.Name} ${newKey}`, // Optionally make the name unique too
              };
            }
          });
        }

        // Helper function to merge databases, ensuring unique aliases for each connection string
        function mergeDatabases(existing, newDatabases) {
          Object.entries(newDatabases).forEach(([key, value]) => {
            // Check if a database with the same connection string already exists
            const existingDatabase = Object.values(existing).find(
              (db) =>
                db.ConnectionStringEncrypted === value.ConnectionStringEncrypted
            );

            if (!existingDatabase) {
              // Generate a new key for the database and a unique alias
              const newKey = Object.keys(existing).length;
              const newAlias = `${value.Alias}_${newKey}`; // Example: PostgreSQL_1, PostgreSQL_2, etc.

              // Add the new database with a unique alias
              existing[newKey] = {
                ...value,
                Alias: newAlias,
                Name: `${value.Name} ${newKey}`, // Optionally make the name unique too
              };
            }
          });
        }

        // Concatenate report data from all tests
        const reports = currentFormTemplate.tests
          .map((test) => test.report_data)
          .filter((data) => data !== null);
        if (reports.length === 0) {
          // If no reports are present, add a default page
          mergedReport.Pages.push({
            Ident: "StiPage",
            Name: "Page1",
            Guid: generateGuid(),
            Interaction: { Ident: "StiInteraction" },
            Border: ";;2;;;;;solid:Black",
            Brush: "solid:",
            PageWidth: 21.01,
            PageHeight: 29.69,
            Watermark: { TextBrush: "solid:50,0,0,0" },
          });
        } else {
          reports.forEach((reportData, index) => {
            try {
              const parsedReport = JSON.parse(reportData);

              // Merge DataSources and Databases

              mergeDatabases(
                mergedReport.Dictionary.Databases,
                parsedReport.Dictionary.Databases
              );
              mergeDataSources(
                mergedReport.Dictionary.DataSources,
                parsedReport.Dictionary.DataSources
              );

              // Handle Pages property
              const pages = Array.isArray(parsedReport.Pages)
                ? parsedReport.Pages
                : Object.values(parsedReport.Pages || {});
              pages.forEach((page, pageIndex) => {
                page.Name = `${page.Name}_Merged_${index}`;
                page.KeepReportOnSinglePage = true; // Keep content on a single page if possible
                console.log("page", page);
                mergedReport.Pages.push(page);
              });
            } catch (parseError) {
              console.error(
                `Error parsing report data for test ${index}:`,
                parseError
              );
              toast.error(`Error processing report data for test ${index}`);
            }
          });
        }
        console.log(mergedReport, "mergedReport");

        // Utility function to measure the height of components
        const measureComponentHeight = (component) => {
          const clientRectangle = component.ClientRectangle || "0,0,0,0";
          const height = parseFloat(clientRectangle.split(",")[3]); // Get the height from the rectangle
          return height;
        };

        // Utility function to merge pages dynamically
        const mergePagesDynamically = (pages, pageHeight) => {
          if (pages.length <= 1) return pages;

          const mergedPages = [];
          let currentPage = pages[0];
          let availableHeight = pageHeight; // Total available height on the page

          // Iterate over all pages
          for (let i = 1; i < pages.length; i++) {
            const currentPageComponents = pages[i].Components;

            // Iterate over all components on the current page
            if (currentPageComponents) {
              Object.keys(currentPageComponents).forEach((key) => {
                const component = currentPageComponents[key];
                const componentHeight = measureComponentHeight(component);

                // Check if the component fits in the available space on the current page
                if (availableHeight >= componentHeight) {
                  // Add the component to the current page
                  const newKey = Object.keys(currentPage.Components).length;
                  currentPage.Components[newKey] = component;
                  availableHeight -= componentHeight; // Reduce the available height
                } else {
                  // If not enough space, push the current page to mergedPages
                  mergedPages.push(currentPage);

                  // Start a new page
                  currentPage = { ...pages[i], Components: {} };
                  currentPage.Components["0"] = component; // Add the component to the new page
                  availableHeight = pageHeight - componentHeight; // Reset available height for the new page
                }
              });
            }
          }

          // Add the last page if it contains components
          if (Object.keys(currentPage.Components).length > 0) {
            mergedPages.push(currentPage);
          }

          return mergedPages;
        };

        // Later in the effect
        const pageHeight = 29.69; // Set your page height (in the same unit as your measurements)
        if (reports.length != 0) {
          const pages = mergePagesDynamically(mergedReport.Pages, pageHeight);
          mergedReport.Pages = pages;
        }

        console.log("Updated Merged Report", mergedReport);

        // Load the concatenated report data
        report.load(mergedReport);

        console.log("Assigning report to viewer");
        viewer.report = report;

        console.log("Rendering viewer to selected element");
        if (designerRef.current) {
          viewer.renderHtml(designerRef.current);
        } else {
          console.error("DesignerRef is not set");
          toast.error("Failed to render report viewer");
        }
      } catch (error) {
        console.error("Error initializing report viewer:", error);
        toast.error("Failed to initialize report viewer");
      }
    } else {
      dispatch(getFormTemplate({ formTemplateId }));
    }
  }, [dispatch, formTemplateId, currentFormTemplate]);

  return (
    <div
      ref={designerRef}
      style={{ height: "100%", width: "100%", position: "relative" }}
    ></div>
  );
};

export default StimulsoftReportViewer;
