import React, { useEffect, useRef, useState } from "react";
import { Stimulsoft } from "stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor";
import "stimulsoft-reports-js/Scripts/stimulsoft.designer";
import "stimulsoft-reports-js/Scripts/stimulsoft.reports";
import { saveReport, loadReport } from "../../redux/features/test";
import { useDispatch, useSelector } from "react-redux";
import { fetchFunctionFields } from "../../redux/features/test.js";
import { toast } from "react-toastify";

const StimulsoftReportDesigner = ({ reportFile, testId }) => {
  const designerRef = useRef(null);
  const dispatch = useDispatch();

  const reportJson = useSelector((state) => state.test.reportJson);
  const currentTest = useSelector((state) => state.test.currentTest);
  const functionFields = useSelector((state) => state.test.functionFields);
  console.log("currentTest.title", currentTest.title);

  var myFunc = function (value) {
    if (!Stimulsoft.Data.Extensions.ListExt.isList(value))
      return Stimulsoft.Base.Helpers.StiValueHelper.tryToNumber(value);
    return Stimulsoft.Data.Functions.Funcs.skipNulls(
      Stimulsoft.Data.Extensions.ListExt.toList(value)
    )
      .tryCastToNumber()
      .sum();
  };

  useEffect(() => {
    // Initialize Stimulsoft
    dispatch(loadReport(testId));
    const designer = new Stimulsoft.Designer.StiDesigner(
      null,
      "StiDesigner",
      false
    );

    Stimulsoft.StiOptions.WebServer.url = "https://adaptar.workerfit.com";

    // Load the report file
    const report = new Stimulsoft.Report.StiReport();
    if (reportJson) {
      console.log("File Present");
      report.loadDocument(reportJson);
    } else {
      console.log("File Absent");
      report.loadFile(reportFile);
    }

    // Intercept the save event
    designer.onSaveReport = function (args) {
      args.preventDefault = true; // Prevent default save action

      // Call your custom saveReport function
      dispatch(saveReport({ testId: testId, report: report }));
    };
    // Clear existing datasources
    report.dictionary.dataSources.clear();

    // Clear existing databases
    report.dictionary.databases.clear();

    // Define the connection string
    const connectionString =
      "Server=adaptar.workerfit.com; Port=5432; Database=examfit_production; User Id=examfit; Password=AdminDB7721;";

    // Create the PostgreSQL data source
    const dataSource = new Stimulsoft.Report.Dictionary.StiPostgreSQLDatabase(
      "PostgreSQL Connection", // Name of the data source
      "PostgreSQL",
      connectionString // Connection string
    );

    // Add the PostgreSQL data source to the report's dictionary
    report.dictionary.databases.add(dataSource);

    // Create a PostgreSQL source with a query
    const postgreSQLSource =
      new Stimulsoft.Report.Dictionary.StiPostgreSQLSource(
        "PostgreSQL Connection", // Name of the data source
        `${currentTest.title} - Fetch Test`, // Name for the query
        `${currentTest.title} - Fetch Test`, // Alias of the data source
        `SELECT * FROM tests WHERE id='${testId}';` // SQL query
      );

    const testFunctionDataSource =
      new Stimulsoft.Report.Dictionary.StiPostgreSQLSource(
        "PostgreSQL Connection", // Name of the data source
        `${currentTest.title} - Fetch Test Functions`, // Query name
        `${currentTest.title} - Fetch Test Functions`, // Alias
        `SELECT * FROM test_functions WHERE test_id = '${testId}';` // SQL query
      );

    const picklistOptionDataSource =
      new Stimulsoft.Report.Dictionary.StiPostgreSQLSource(
        "PostgreSQL Connection", // Name of the data source
        `${currentTest.title} - Fetch Picklist Options`, // Query name
        `${currentTest.title} - Fetch Picklist Options`, // Alias
        `SELECT * FROM picklist_options WHERE test_function_id IN (SELECT id FROM test_functions WHERE test_id = '${testId}');` // SQL query
      );

    // Add the PostgreSQL source to the report's dictionary
    report.dictionary.dataSources.add(postgreSQLSource);
    report.dictionary.dataSources.add(testFunctionDataSource);
    report.dictionary.dataSources.add(picklistOptionDataSource);

    // Manually define and add columns
    const columns = [
      {
        name: "id",
        caption: "ID",
        dataType: "Integer",
      },
      {
        name: "title",
        caption: "Title",
        dataType: "String",
      },
      {
        name: "description",
        caption: "Description",
        dataType: "Text",
      },
      {
        name: "instructions",
        caption: "Instructions",
        dataType: "Text",
      },
      {
        name: "instruction_url",
        caption: "Instruction URL",
        dataType: "String",
      },
      {
        name: "status",
        caption: "Status",
        dataType: "Integer",
      },
      {
        name: "created_by",
        caption: "Created By",
        dataType: "Integer",
      },
      {
        name: "created_at",
        caption: "Created At",
        dataType: "Datetime",
      },
      {
        name: "updated_at",
        caption: "Updated At",
        dataType: "Datetime",
      },
      {
        name: "archive",
        caption: "Archive",
        dataType: "Boolean",
      },
    ];

    if (functionFields) {
      functionFields.forEach((field) => {
        // Create a separate query for each function_name
        const specificFunctionSource =
          new Stimulsoft.Report.Dictionary.StiPostgreSQLSource(
            "PostgreSQL Connection", // Name of the data source
            `${field.function_name} - Field`, // Query name
            `${field.function_name} - Field`, // Alias
            `SELECT value FROM function_fields WHERE test_function_id IN 
           (SELECT id FROM test_functions WHERE test_id = '${testId}') 
           AND function_name = '${field.function_name}';` // SQL query filtering by function_name
          );

        // Add this specific function source to the report's dictionary
        report.dictionary.dataSources.add(specificFunctionSource);

        // Define the column for the specific function name
        const dataColumn = new Stimulsoft.Report.Dictionary.StiDataColumn(
          "value", // Name of the column, since the query only retrieves value
          field.function_name, // Set the function name as the column caption
          "String", // Assuming the value is a string
          specificFunctionSource // Bind the column to this specific function source
        );

        // Add the column to the specific function source
        specificFunctionSource.columns.add(dataColumn);
      });
    } else {
      dispatch(fetchFunctionFields({ testId }));
    }

    // Add the columns to the PostgreSQL source
    columns.forEach((column) => {
      const dataColumn = new Stimulsoft.Report.Dictionary.StiDataColumn(
        column.name,
        column.caption,
        column.dataType,
        postgreSQLSource
      );
      postgreSQLSource.columns.add(dataColumn);
    });

    // Define columns for TestFunction data source
    const testFunctionColumns = [
      { name: "id", caption: "ID", dataType: "Integer" },
      { name: "test_id", caption: "Test ID", dataType: "Integer" },
      { name: "detail", caption: "Detail", dataType: "Text" },
      { name: "data_type", caption: "Data Type", dataType: "String" },
      { name: "created_by", caption: "Created By", dataType: "Integer" },
      { name: "created_at", caption: "Created At", dataType: "Datetime" },
      { name: "updated_at", caption: "Updated At", dataType: "Datetime" },
    ];

    // Add columns to the TestFunction data source
    testFunctionColumns.forEach((column) => {
      const dataColumn = new Stimulsoft.Report.Dictionary.StiDataColumn(
        column.name,
        column.caption,
        column.dataType,
        testFunctionDataSource
      );
      testFunctionDataSource.columns.add(dataColumn);
    });

    // Define columns for PicklistOption data source
    const PicklistOptionColumns = [
      { name: "id", caption: "ID", dataType: "Integer" },
      {
        name: "test_function_id",
        caption: "Test Function ID",
        dataType: "Integer",
      },
      { name: "option_text", caption: "Option Text", dataType: "String" },
      { name: "created_at", caption: "Created At", dataType: "Datetime" },
      { name: "updated_at", caption: "Updated At", dataType: "Datetime" },
    ];

    // Add columns to the PicklistOption data source
    PicklistOptionColumns.forEach((column) => {
      const dataColumn = new Stimulsoft.Report.Dictionary.StiDataColumn(
        column.name,
        column.caption,
        column.dataType,
        picklistOptionDataSource
      );
      picklistOptionDataSource.columns.add(dataColumn);
    });

    // Define test function relations
    const testFunctionSource = report.dictionary.dataSources.getByName(
      `${currentTest.title} - Fetch Test Functions`
    );
    const testSource = report.dictionary.dataSources.getByName(
      `${currentTest.title} - Fetch Test`
    );

    testFunctionSource.masterDataSource = testSource; // Set Test as master
    testFunctionSource.relation =
      new Stimulsoft.Report.Dictionary.StiDataRelation(
        "TestFunctionRelation",
        "test_id", // Foreign key in TestFunction
        "id" // Primary key in Test
      );

    // Define picklist relations
    const picklistOptionSource = report.dictionary.dataSources.getByName(
      `${currentTest.title} - Fetch Picklist Options`
    );

    picklistOptionSource.masterDataSource = testFunctionSource; // Set TestFunction as master
    picklistOptionSource.relation =
      new Stimulsoft.Report.Dictionary.StiDataRelation(
        "PicklistOptionRelation",
        "test_function_id", // Foreign key in PicklistOption
        "id" // Primary key in TestFunction
      );

    Stimulsoft.Report.Dictionary.StiFunctions.addFunction(
      "MyCategory",
      "MySum",
      "MySum",
      "MySum",
      "",
      Number,
      "Return Description",
      [Object],
      ["value"],
      ["Descriptions"],
      myFunc
    );

    // Assign the report to the designer
    designer.report = report;

    // Render the designer
    designer.renderHtml(designerRef.current);
  }, [reportJson, reportFile, dispatch, testId]); // Include testId in the dependency array

  return <div ref={designerRef} style={{ width: "100%", height: "100vh" }} />;
};

export default StimulsoftReportDesigner;
