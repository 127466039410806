import EmptyListMessage from "../common/EmptyListMessage";

const ReportPreview = () => {
  return (
    <EmptyListMessage
      messageHeader="Feature Under Development"
      message="The Report Preview feature is under development, and will be ready for testing soon."
    />
  );
};

export default ReportPreview;
