import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  InputBase,
  IconButton,
  Button,
  Link,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrganization } from "../../redux/features/organization.js";
import GradientCircularProgress from "../../elements/GradientCircularProgress";
import EmptyList from "../common/EmptyList.jsx";

import TableList from "./TableList.jsx";

const Organizations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organizationList = useSelector(
    (state) => state.organization.organizationList
  );
  const isLoading = useSelector((state) => state.organization.isLoading);
  const totalOrganizationCount = useSelector(
    (state) => state.organization.totalOrganizationCount
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOrganizationClick = (event, organization) => {
    event.preventDefault();

    navigate(`/organizations/edit-organization/${organization.id}`);
  };

  useEffect(() => {
    dispatch(
      getAllOrganization({
        searchQuery: searchQuery,
        page: page,
        rowsPerPage: rowsPerPage,
      })
    );
  }, [dispatch, page, rowsPerPage, searchQuery]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Organization
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
          }}
        >
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search test"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search test" }}
              type="search"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Paper>

          <Link>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/organizations/new-organization");
              }}
            >
              Create Organization
            </Button>
          </Link>
        </Box>
      </Box>

      {isLoading && !searchQuery && organizationList.length === 0 ? (
        <GradientCircularProgress open={isLoading} />
      ) : organizationList && organizationList.length === 0 ? (
        <EmptyList
          messageHeader={
            searchQuery
              ? "No organizations found."
              : "No organizations available."
          }
          message={
            searchQuery
              ? "Try adjusting your search or"
              : "It looks like there are no organizations added yet. To get started,"
          }
          buttonText="create a organizations"
          route="/organizations/new-organization"
        />
      ) : (
        <TableList
          organizationList={organizationList}
          page={page}
          rowsPerPage={rowsPerPage}
          totalOrganizationCount={totalOrganizationCount}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrganizationClick={handleOrganizationClick}
        />
      )}
    </Box>
  );
};

export default Organizations;
