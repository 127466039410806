import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import TagForm from "./TagForm.jsx";

const EditTag = () => {
  const theme = useTheme();
  const location = useLocation();

  const { tag } = location.state;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Edit Tag
        </Typography>
      </Box>

      <TagForm tag={tag} />
    </Box>
  );
};

export default EditTag;
