import { red, blueGrey, grey } from "@mui/material/colors";

const purple = "#333D7A";
const light = "#7D25EC";
const dark = "#1B2559";
const white = "#ffffff";
const black = "#000000";
const gray = "#8E8E8E";
const lightGray = "#D8D8D8";
const mediumGray = "#DCDCDC";

export const palette = {
  primary: {
    main: purple,
    light: light,
    dark: dark,
    black: black,
    white: white,
    gray: gray,
    lightGray: lightGray,
  },
  secondary: {
    main: white,
    gray: "#E7E8EC",
    mediumGray: mediumGray,
  },
  error: {
    contrastText: white,
    dark: red[900],
    main: red[600],
    light: red[400],
  },
  link: purple,
  icon: blueGrey[600],
  background: {
    default: white,
    paper: white,
  },
  divider: grey[200],
};
