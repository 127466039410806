import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog.jsx";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import {
  deleteReportTemplate,
  getReportTemplate,
} from "../../redux/features/report_template.js";
import PrintIcon from "@mui/icons-material/Print";
import ReportTemplatePDF from "./ReportTemplatePDF.jsx";
import { pdf } from "@react-pdf/renderer";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";

const ReportTemplateDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const reportTemplate = useSelector(
    (state) => state.reportTemplate.currentReportTemplate
  );
  const isLoading = useSelector((state) => state.reportTemplate.isLoading);

  const [open, setOpen] = useState(false);
  const [selectedReportTemplate, setSelectedReportTemplate] = useState(null);

  const handleEditReportTemplateClick = (event, reportTemplate) => {
    event.preventDefault();

    navigate(`/report-templates/edit-report-template/${reportTemplate.id}`);
  };

  const handleDeleteReportTemplateClick = (event, reportTemplate) => {
    event.preventDefault();
    setSelectedReportTemplate(reportTemplate);
    setOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    if (selectedReportTemplate) {
      await dispatch(
        deleteReportTemplate({ reportTemplateId: selectedReportTemplate.id })
      );
      navigate("/report-templates");
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedReportTemplate(null);
  };

  useEffect(() => {
    dispatch(getReportTemplate({ reportTemplateId: id }));
  }, [dispatch, id]);

  const handleReportPDFGenerateClick = async (event, reportTemplate) => {
    event.preventDefault();

    const doc = <ReportTemplatePDF reportTemplate={reportTemplate} />;
    const asPdf = pdf([]); // {} is important, throws without an argument
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();

    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  return (
    <Box>
      {isLoading ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <Box
          sx={{
            padding: 3,
            marginTop: 2,
            height: "72vh",
            borderRadius: "16px",
            boxShadow: "0 0 15px 0 #0000001a",
            overflow: "scroll",
          }}
          component={Paper}
        >
          {reportTemplate ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: 3,
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 1,
                    justifyContent: "right",
                  }}
                >
                  <Tooltip title="Edit">
                    <IconButton
                      sx={{ p: "10px" }}
                      onClick={(event) =>
                        handleEditReportTemplateClick(event, reportTemplate)
                      }
                      id={reportTemplate.id}
                    >
                      <ModeEditOutlineRoundedIcon
                        sx={{ color: theme.palette.primary.main }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      sx={{ p: "10px" }}
                      onClick={(event) =>
                        handleDeleteReportTemplateClick(event, reportTemplate)
                      }
                      id={reportTemplate.id}
                    >
                      <DeleteOutlinedIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Generate PDF">
                    <IconButton
                      sx={{ p: "10px" }}
                      onClick={(event) =>
                        handleReportPDFGenerateClick(event, reportTemplate)
                      }
                      id={reportTemplate.id}
                    >
                      <PrintIcon sx={{ color: "green" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Typography
                  color="primary"
                  sx={{
                    fontSize: "20px",
                    lineHeight: "24.2px",
                    fontWeight: 500,
                    marginBottom: "4px",
                  }}
                >
                  Title
                </Typography>

                <Typography
                  color="black"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "17px",
                    fontWeight: 300,
                  }}
                >
                  {reportTemplate.title}
                </Typography>
              </Box>

              <Box>
                <Typography
                  color="primary"
                  sx={{
                    fontSize: "20px",
                    lineHeight: "24.2px",
                    fontWeight: 500,
                    marginBottom: "4px",
                  }}
                >
                  Subtitle
                </Typography>

                <Typography
                  color="black"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "17px",
                    fontWeight: 300,
                  }}
                >
                  {reportTemplate.subtitle}
                </Typography>
              </Box>

              <DeleteConfirmationDialog
                open={open}
                handleCancel={handleCancel}
                titleText="Confirm Delete"
                contentText="Are you sure you want to delete"
                title={selectedReportTemplate?.title}
                suffixText="from report templates list?"
                handleDelete={handleDeleteConfirmation}
              />
            </Box>
          ) : (
            <GradientCircularProgress open={isLoading} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ReportTemplateDetails;
