import Box from "@mui/material/Box";
import { Button, Typography, useTheme } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import TemplateForm from "./TemplateForm.jsx";
import { Add as AddIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getFormTemplate } from "../../redux/features/form_template.js";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";

const EditFormTemplate = () => {
  const { id } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formTemplate = useSelector(
    (state) => state.formTemplate.currentFormTemplate
  );
  const isLoading = useSelector((state) => state.formTemplate.isLoading);

  useEffect(() => {
    dispatch(
      getFormTemplate({
        formTemplateId: id,
      })
    );
  }, [dispatch, id]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Edit Form Template
        </Typography>

        <Box
          sx={{
            display: "flex",
            columnGap: 2,
          }}
        >
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              navigate(
                `/form-templates/link-tests-to-form-template/${formTemplate.id}`
              );
            }}
          >
            Link Tests
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <TemplateForm formTemplate={formTemplate} />
      )}
    </Box>
  );
};

export default EditFormTemplate;
