import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog.jsx";
import { deleteGroup } from "../../redux/features/group";
import { deleteFormTemplate } from "../../redux/features/form_template.js";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const TableList = ({
  groups,
  totalGroups,
  page,
  rowsPerPage,
  handleGroupListItemClick,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleDeleteGroupClick = (event, group) => {
    event.preventDefault();
    setSelectedGroup(group);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedGroup(null);
  };

  const handleDeleteConfirmation = async () => {
    if (selectedGroup) {
      await dispatch(deleteGroup({ groupId: selectedGroup.id }));
      setOpen(false);
      navigate("/groups");
    }
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ marginTop: 2, maxHeight: 460 }}>
        <Table stickyHeader aria-label="tests table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Name
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Address
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                City
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                State
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Zip
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups?.map((group) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={group.id}
                onClick={() => handleGroupListItemClick(group)}
                sx={{
                  cursor: "pointer",
                }}
              >
                <TableCell align="left" sx={{ width: "20%" }}>
                  {group.name}
                </TableCell>
                <TableCell align="left" sx={{ width: "20%" }}>
                  {group.address}
                </TableCell>
                <TableCell align="left" sx={{ width: "20%" }}>
                  {group.city}
                </TableCell>
                <TableCell align="left" sx={{ width: "20%" }}>
                  {group.state}
                </TableCell>
                <TableCell align="left" sx={{ width: "20%" }}>
                  {group.zip_code}
                </TableCell>
                <TableCell align="left" sx={{ width: "20%" }}>
                  <Tooltip title="Delete">
                    <IconButton
                      sx={{ p: "10px" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteGroupClick(event, group);
                      }}
                      id={group.id}
                    >
                      <DeleteOutlinedIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteConfirmationDialog
        open={open}
        handleCancel={handleCancel}
        titleText="Confirm Delete"
        contentText="Are you sure you want to delete"
        title={selectedGroup?.name}
        suffixText="from groups list?"
        handleDelete={handleDeleteConfirmation}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalGroups}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
          }}
        />
      </Box>
    </Box>
  );
};

export default TableList;
