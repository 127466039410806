import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllCompanies } from "../../redux/features/company";
import { useDispatch } from "react-redux";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useSelector } from "react-redux";
import GradientCircularProgress from "../../elements/GradientCircularProgress";
import CompanyListItem from "./CompanyListItem";
import EmptyList from "../common/EmptyList";

const Companies = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const companies = useSelector((state) => state.company.companies);
  const isLoading = useSelector((state) => state.company.isLoading);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(getAllCompanies(searchValue));
  }, [dispatch, searchValue]);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Accounts
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
          }}
        >
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search google maps" }}
              onChange={handleSearch}
              value={searchValue}
            />
          </Paper>
          <Link>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/companies/add-company");
              }}
            >
              Create Account
            </Button>
          </Link>
        </Box>
      </Box>

      {isLoading && !searchValue && companies.length === 0 ? (
        <GradientCircularProgress open={isLoading} />
      ) : companies && companies.length === 0 ? (
        <EmptyList
          messageHeader={
            searchValue ? "No companies found." : "No companies available."
          }
          message={
            searchValue
              ? "Try adjusting your search or"
              : "It looks like there are no companies added yet. To get started,"
          }
          buttonText="create a company"
          route="/companies/add-company"
        />
      ) : (
        <ImageList
          sx={{ width: "100%", height: "100%", marginTop: 4 }}
          cols={4}
          gap={16}
        >
          {companies &&
            companies.map((company, index) => (
              <ImageListItem key={index} sx={{ cursor: "pointer" }}>
                <CompanyListItem company={company} />
              </ImageListItem>
            ))}
        </ImageList>
      )}
    </Box>
  );
};

export default Companies;
