import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import WorkerForm from "./WorkerForm.jsx";

const NewWorker = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          New Worker
        </Typography>
      </Box>
      <WorkerForm user={null} />
    </Box>
  );
};

export default NewWorker;

