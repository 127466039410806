import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drawerOpen: true,
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    handleDrawerOpen: (state) => {
      state.drawerOpen = true;
    },
    handleDrawerClose: (state) => {
      state.drawerOpen = false;
    },
  },
});

export default utilsSlice.reducer;
export const { handleDrawerOpen, handleDrawerClose } = utilsSlice.actions;
