import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  ToggleButton,
  Typography,
  TextField,
  useTheme,
} from "@mui/material";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import CropIcon from "@mui/icons-material/Crop";
import EditIcon from "@mui/icons-material/Edit";
import { ReactSketchCanvas } from "react-sketch-canvas";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageEditor = () => {
  const theme = useTheme();
  const imgRef = useRef(null);
  const canvasRef = useRef(null);

  const [imgSrc, setImgSrc] = useState("");
  const [height, setHeight] = useState("500px");
  const [width, setWidth] = useState("auto");
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [isCropping, setIsCropping] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [selectedImageName, setSelectedImageName] = useState("");
  const [imgHeight, setImgHeight] = useState(0);
  const [imgWidth, setImgWidth] = useState(0);
  const [imgMaxHeight, setImgMaxHeight] = useState("");
  const [imgMaxWidth, setImgMaxWidth] = useState("");

  async function handleFileSelect(e) {
    try {
      const file = e.target.files[0];
      if (!file) return;
      setCrop(undefined);
      setAspect(null);
      setIsCropping(false);
      setIsDrawing(false);

      const imgDataUrl = await readFileAsDataURL(file);
      setImgSrc(imgDataUrl);
      setSelectedImageName(file.name);
    } catch (error) {
      console.error("Error handling file select:", error);
    }
  }

  async function readFileAsDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  function onImageLoad(e) {
    const { width, height, naturalWidth, naturalHeight } = e.currentTarget;
    const aspectRatio = width / height;
    const newCrop = {
      unit: "px",
      x: 0,
      y: 0,
      width: width,
      height: height,
    };

    setAspectRatio(aspectRatio);
    setCrop(newCrop);
    setCompletedCrop(newCrop);
    setHeight(height);
    setWidth(width);
    setImgHeight(naturalHeight);
    setImgWidth(naturalWidth);
    setImgMaxHeight(naturalHeight);
    setImgMaxWidth(naturalWidth);
  }

  const handleRotateClockwise = () => {
    if (!isDrawing) {
      setRotate((prevRotate) => (prevRotate + 90) % 360);
      const width = imgRef.current.width;
      const height = imgRef.current.height;
      setWidth(`${height}px`);
      setHeight(`${width}px`);
      setAspectRatio(width / height);
      const newCrop = {
        unit: "px",
        x: 0,
        y: 0,
        width: height,
        height: width,
      };
      setCrop(newCrop);
      setCompletedCrop(newCrop);
    }
  };

  const handleRotateCounterclockwise = () => {
    if (!isDrawing) {
      setRotate((prevRotate) => (prevRotate - 90 + 360) % 360);
      const width = imgRef.current.width;
      const height = imgRef.current.height;
      setWidth(`${height}px`);
      setHeight(`${width}px`);
      setAspectRatio(width / height);
      const newCrop = {
        unit: "px",
        x: 0,
        y: 0,
        width: height,
        height: width,
      };
      setCrop(newCrop);
      setCompletedCrop(newCrop);
    }
  };

  function handleCropClick() {
    if (!completedCrop || !imgRef.current) {
      return;
    }

    const canvas = document.createElement("canvas");
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = completedCrop.width * scaleX;
    canvas.height = completedCrop.height * scaleY;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      imgRef.current,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );

    const dataUrl = canvas.toDataURL("image/jpeg");
    setImgSrc(dataUrl);
    setHeight(completedCrop.height);
    setWidth(completedCrop.width);
    setIsCropping(false);
  }

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(null); // Switch to free aspect ratio
      setCrop(null); // Reset crop to allow free cropping
    } else {
      setAspect(1); // Set 1:1 aspect ratio

      if (imgRef.current) {
        const { width, height } = imgRef.current;
        const newCrop = centerAspectCrop(width, height, 1);
        setCrop(newCrop);
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
      }
    }
  }

  function handleResizeClick() {
    if (!imgRef.current) return;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const width = imgRef.current.naturalWidth;
    const height = imgRef.current.naturalHeight;
    const MAX_WIDTH = 800; // Define max width
    const MAX_HEIGHT = 800; // Define max height
    let newWidth = width;
    let newHeight = height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        newHeight = (height * MAX_WIDTH) / width;
        newWidth = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        newWidth = (width * MAX_HEIGHT) / height;
        newHeight = MAX_HEIGHT;
      }
    }

    canvas.width = newWidth;
    canvas.height = newHeight;

    ctx.drawImage(imgRef.current, 0, 0, newWidth, newHeight);

    const dataUrl = canvas.toDataURL("image/jpeg", 0.7);
    setImgSrc(dataUrl);
  }

  const handleCropping = () => {
    const width = imgRef.current.width;
    const height = imgRef.current.height;
    const newCrop = {
      unit: "px",
      x: 0,
      y: 0,
      width: height,
      height: width,
    };
    setCrop(newCrop);
    setCompletedCrop(newCrop);
    setIsCropping(!isCropping);
    setIsDrawing(false);
  };

  const handleDrawing = () => {
    const width = imgRef.current.width;
    const height = imgRef.current.height;
    setWidth(`${width}px`);
    setHeight(`${height}px`);
    setIsDrawing(true);
    setIsCropping(false);
  };

  const handleHeightChange = (event) => {
    setImgHeight(event.target.value);
  };

  const handleWidthChange = (event) => {
    setImgWidth(event.target.value);
  };

  console.log("width", width);
  console.log("height", height);
  console.log("crop", crop);
  console.log("imgRef", imgRef);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: imgSrc && "82vh",
        border: imgSrc && "solid",
        borderRadius: 2,
        padding: imgSrc && 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          columnGap: 2,
        }}
      >
        <Button
          variant="contained"
          component="label"
          sx={{
            width: "fit-content",
            paddingX: 2,
            boxShadow: "none",
            backgroundColor: theme.palette.secondary.gray,
            color: theme.palette.primary.main,
            ":hover": {
              backgroundColor: theme.palette.secondary.gray,
              color: theme.palette.primary.main,
              boxShadow: "none",
            },
          }}
          startIcon={<FileUploadOutlinedIcon />}
        >
          {imgSrc ? "Change Image" : "Select Image"}
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleFileSelect}
          />
        </Button>

        <ToggleButton
          value="check"
          selected={isCropping}
          onClick={handleCropping}
          disabled={!imgSrc}
          sx={toggleButtonStyle}
        >
          <CropIcon />
        </ToggleButton>

        <ToggleButton
          value="check"
          selected={isDrawing}
          onClick={handleDrawing}
          disabled={!imgSrc}
          sx={toggleButtonStyle}
        >
          <EditIcon />
        </ToggleButton>

        <ToggleButton
          value="check"
          selected={!!aspect}
          onChange={handleToggleAspectClick}
          disabled={!imgSrc || isDrawing}
          sx={toggleButtonStyle}
        >
          1:1
        </ToggleButton>

        <IconButton
          variant="contained"
          onClick={handleRotateCounterclockwise}
          disabled={isDrawing || !imgSrc}
        >
          <RotateLeftIcon />
        </IconButton>

        <IconButton
          variant="contained"
          onClick={handleRotateClockwise}
          disabled={isDrawing || !imgSrc}
        >
          <RotateRightIcon />
        </IconButton>

        <Button
          variant="outlined"
          sx={{ width: 82 }}
          onClick={handleCropClick}
          disabled={!imgSrc || !isCropping}
        >
          Crop
        </Button>

        <TextField
          label="Width"
          type="number"
          value={imgWidth}
          onChange={handleWidthChange}
          style={{ width: 82 }}
          InputProps={{ inputProps: { min: 0, max: imgMaxWidth } }}
          disabled={!imgSrc}
        />
        <TextField
          label="Height"
          variant="outlined"
          type="number"
          value={imgHeight}
          onChange={handleHeightChange}
          style={{ width: 82 }}
          InputProps={{ inputProps: { min: 0, max: imgMaxHeight } }}
          disabled={!imgSrc}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          marginTop: 2,
        }}
      >
        {imgSrc && isDrawing && (
          <ReactSketchCanvas
            ref={canvasRef}
            width={width}
            height="500px"
            strokeWidth={4}
            strokeColor="black"
            backgroundImage={imgSrc}
          />
        )}

        {imgSrc && isCropping && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{
                height: "500px",
                width: width,
                objectFit: "contain",
                transform: `rotate(${rotate}deg)`,
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}

        {imgSrc && !isCropping && !isDrawing && (
          <img
            ref={imgRef}
            alt="Crop meeee"
            src={imgSrc}
            style={{
              height: "500px",
              width: width,
              objectFit: "contain",
              transform: `rotate(${rotate}deg)`,
            }}
            onLoad={onImageLoad}
          />
        )}
      </Box>
      {imgSrc && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            marginTop: 1,
            marginBottom: 1,
          }}
        >
          <Typography>{selectedImageName}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ImageEditor;

const toggleButtonStyle = {
  border: "none",
  borderRadius: "100%",
  ":disabled": {
    border: "none",
  },
};
