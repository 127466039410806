import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  InputBase,
  IconButton,
  Button,
  Link,
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/features/user.js";
import GradientCircularProgress from "../../elements/GradientCircularProgress";
import TableList from "./TableList.jsx";

const Workers = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const workers = useSelector((state) => state.user.usersList);
    const isLoading = useSelector((state) => state.user.isLoading);
    const totalWorkersCount = useSelector((state) => state.user.totalUsersCount);
    const [searchQuery, setSearchQuery] = useState(""); 
    const role = "subject";
    const [focused, setFocused] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleWorkerListItemClick = (user) => {
      navigate(`/workers/edit-worker/${user.id}`);
    };
  
    useEffect(() => {
      dispatch(
        getAllUsers({
          searchValue: searchQuery,
          page: page,
          rowsPerPage: rowsPerPage,
          role: role
        })
      );
    }, [dispatch, page, rowsPerPage, searchQuery, role]);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    const handleSearch = (event) => {
      const query = event.target.value;
      setSearchQuery(query);
    };
  
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Workers
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
          }}
        >
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search test"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search test" }}
              type="search"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Paper>

          <Link>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/workers/new-worker");
              }}
            >
              Create Worker
            </Button>
          </Link>
        </Box>
      </Box>

      {isLoading && !searchQuery ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <Box sx={{ mt: 3 }}>
        <TableList 
            workers={workers}
            totalWorkers={totalWorkersCount}
            page={page}
            rowsPerPage={rowsPerPage}
            handleWorkerListItemClick={handleWorkerListItemClick}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
        </Box>
      )}
    </Box>
  );
};

export default Workers;
