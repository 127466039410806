import React from "react";
import { Box, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const EmptyListMessage = ({ messageHeader, message }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        paddingTop: 25,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
        <InfoOutlinedIcon fontSize="small" />
        <Typography variant="h6" component="div">
          &nbsp;{messageHeader}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography color="text.secondary" fontSize={15}>
          {message}&nbsp;
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyListMessage;
