import { Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog";
import { useState } from "react";
import { deleteCompany } from "../../redux/features/company";

const CompanyListItem = ({ company }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleCompanyEdit = (event, company) => {
    event.preventDefault();
    navigate(`/companies/edit-company/${company.id}`);
  };

  const handleCompanyDelete = (event, company) => {
    event.preventDefault();
    setSelectedCompany(company);
    setOpen(true);
  };

  const handleDeleteConfirmation = () => {
    if (selectedCompany) {
      dispatch(deleteCompany({ companyId: selectedCompany.id }));
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedCompany(null);
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
        padding: 2,
        borderRadius: "16px",
        boxShadow: "0px 0px 10px 0 #0000001a",
      }}
    >
      <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        image={company.company_logo}
        sx={{
          borderRadius: "15px",
          position: "relative",
        }}
      />

      <IconButton
        variant="outlined"
        color="primary"
        component="label"
        onClick={(event) => handleCompanyDelete(event, company)}
        sx={{
          width: "28px",
          height: "28px",
          backgroundColor: "white",
          borderRadius: "50%",
          position: "absolute",
          top: "25px",
          right: "27px",
          ":hover": {
            backgroundColor: "white",
          },
        }}
      >
        <DeleteOutlinedIcon
          sx={{ color: theme.palette.primary.main, fontSize: "20px" }}
        />
      </IconButton>
      <CardContent sx={{ padding: 0, marginTop: 2 }}>
        <Typography
          sx={{
            fontSize: "14.5px",
            fontWeight: "bold",
            color: theme.palette.primary.dark,
            fontWeight: 700,
          }}
        >
          {`${company.account_name?.trim() || ""}`}
        </Typography>
        <Typography
          sx={{ color: "#A3AED0", fontSize: "12px", fontWeight: 700 }}
        >
          Phone: {company.phone}
        </Typography>
      </CardContent>

      <CardActions
        sx={{
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontWeight: 700,
          }}
        >
          Created: {dayjs(company.created_at).format("MMMM DD, YYYY")}
        </Typography>
        <Button
          variant="contained"
          sx={{
            width: "85px",
            fontSize: "11px",
            backgroundColor: theme.palette.primary.light,
            paddingX: 1,
            paddingY: 0.5,
            ":hover": {
              backgroundColor: theme.palette.primary.light,
            },
          }}
          onClick={(event) => handleCompanyEdit(event, company)}
        >
          Edit Details
        </Button>
      </CardActions>

      <DeleteConfirmationDialog
        open={open}
        handleCancel={handleCancel}
        titleText="Confirm Delete"
        contentText="Are you sure you want to delete"
        title={`${selectedCompany?.first_name?.trim() || ""} ${
          selectedCompany?.last_name?.trim() || ""
        }`}
        suffixText="from companies list?"
        handleDelete={handleDeleteConfirmation}
      />
    </Card>
  );
};

export default CompanyListItem;
