import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, useTheme, FormControlLabel } from "@mui/material";
import { palette } from "../../theme/palette.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createNewGroup, editGroup } from "../../redux/features/group.js";
import { getRoleWiseUser } from "../../redux/features/user.js";
import { getFormTemplates } from "../../redux/features/form_template.js";
import { getAllCompanies } from "../../redux/features/company.js";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OutlinedInput from "@mui/material/OutlinedInput";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { states, countries } from "../../constants/data.js";
import ReactQuill from "react-quill";

const GroupForm = ({ group }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showGroupDetails, setShowGroupDetails] = useState(false);
  const [showExaminerDetails, setShowExaminerDetails] = useState(false);
  const [showExamTemplatesDetails, setShowExamTemplatesDetails] =
    useState(false);
  const [showSubjectsDetails, setShowSubjectsDetails] = useState(false);
  const [showReviewersDetails, setShowReviewersDetails] = useState(false);
  const roleWiseUser = useSelector((state) => state.user?.roleWiseUser);
  const companies = useSelector((state) => state.company.companies);
  const exam_templates = useSelector(
    (state) => state.formTemplate?.formTemplates
  );
  const [selectedImageName, setSelectedImageName] = useState("");
  const [logoFileLink, setLogoFileLink] = useState(group?.logo || null);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  useEffect(() => {
    if (group?.logo) {
      setSelectedImageName(group.logo.split("/").pop());
    } else if (countries.length > 0) {
      setNewGroup((prevGroup) => ({
        ...prevGroup,
        country: countries[0].value,
      }));
    }

    const fetchData = async () => {
      await dispatch(getRoleWiseUser("")).unwrap();
      await dispatch(getFormTemplates(""));
      await dispatch(getAllCompanies(""));
    };
    fetchData();
  }, [dispatch, group]);

  const [newGroup, setNewGroup] = useState({
    name: group?.name || "",
    address: group?.address || "",
    address_2: group?.address_2 || "",
    city: group?.city || "",
    state: group?.state || "",
    country: group?.country || "",
    zip_code: group?.zip_code || "",
    group_code: group?.group_code || "",
    phone: group?.phone || "",
    fax: group?.fax || "",
    comments: group?.comments || "",
    logo: group?.logo || null,
    enabled: group?.enabled || false,
    company_ids: group?.company_ids || [],
    examiner_ids: group?.examiner_ids || [],
    visit_template_ids: group?.visit_templates_ids || [],
    subject_ids: group?.subject_ids || [],
    reviewer_ids: group?.reviewer_ids || [],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "examiners") {
      const {
        target: { value },
      } = event;

      setNewGroup((prevGroup) => {
        const newExaminers = Array.isArray(value) ? value : [value];
        const flattenedExaminers = newExaminers.flat(Infinity);
        const uniqueExaminers = [...new Set(flattenedExaminers)];

        return {
          ...prevGroup,
          examiner_ids: uniqueExaminers,
        };
      });
    }
    // else if (name === "company_ids"){
    //   const {
    //     target: { value },
    //   } = event;
    //   setNewGroup((prevGroup) => {
    //     const currentCompany = prevGroup.company_ids;
    //     const newCompany =
    //       typeof value === "string" ? value.split(",") : value;
    //     const updatedCompany = [
    //       ...currentCompany,
    //       ...newCompany.filter(
    //         (company) => !currentCompany.includes(company)
    //       ),
    //     ];
    //     return {
    //       ...prevGroup,
    //       company_ids: updatedCompany,
    //     };
    //   });
    // }
    else if (name === "company_ids") {
      const {
        target: { value },
      } = event;

      setNewGroup((prevGroup) => {
        const newCompany = Array.isArray(value) ? value : [value];
        const flattenedCompany = newCompany.flat(Infinity);
        const uniqueCompanyIds = [...new Set(flattenedCompany)];

        return {
          ...prevGroup,
          company_ids: uniqueCompanyIds,
        };
      });
    } else if (name === "exam_templates") {
      const {
        target: { value },
      } = event;

      setNewGroup((prevGroup) => {
        const newExamTemplates = Array.isArray(value) ? value : [value];
        const flattenedExamTemplates = newExamTemplates.flat(Infinity);
        const uniqueExamTemplates = [...new Set(flattenedExamTemplates)];

        return {
          ...prevGroup,
          visit_template_ids: uniqueExamTemplates,
        };
      });
    } else if (name === "subjects") {
      const {
        target: { value },
      } = event;

      setNewGroup((prevGroup) => {
        const newSubjects = Array.isArray(value) ? value : [value];
        const flattenedSubjects = newSubjects.flat(Infinity);
        const uniqueSubjects = [...new Set(flattenedSubjects)];

        return {
          ...prevGroup,
          subject_ids: uniqueSubjects,
        };
      });
    } else if (name === "reviewers") {
      const {
        target: { value },
      } = event;
      setNewGroup((prevGroup) => {
        const newReviewers = Array.isArray(value) ? value : [value];
        const flattenedReviewers = newReviewers.flat(Infinity);
        const uniqueReviewers = [...new Set(flattenedReviewers)];

        return {
          ...prevGroup,
          reviewer_ids: uniqueReviewers,
        };
      });
    } else if (name === "enabled") {
      setNewGroup((prevGroup) => ({
        ...prevGroup,
        [name]: event.target.checked,
      }));

      return;
    } else {
      setNewGroup({
        ...newGroup,
        [name]: value,
      });
    }
  };

  const handleCommentsChange = (value) => {
    setNewGroup((prevState) => ({
      ...prevState,
      comments: value,
    }));
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(examiner, examiners, theme) {
    return {
      fontWeight:
        examiners?.indexOf(examiner) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const handleLogoSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const logoURL = URL.createObjectURL(file);
      setLogoFileLink(logoURL);
      setNewGroup((prevGroup) => ({
        ...prevGroup,
        logo: file,
      }));
      setSelectedImageName(file.name);
    }
  };

  const handleGroupSubmit = async (event) => {
    event.preventDefault();
    if (newGroup.name === "") {
      toast.error("Please enter group name");
    } else {
      const formData = new FormData();
      formData.append("group[name]", newGroup.name);
      formData.append("group[address]", newGroup.address);
      formData.append("group[address_2]", newGroup.address_2);
      formData.append("group[city]", newGroup.city);
      formData.append("group[state]", newGroup.state);
      formData.append("group[country]", newGroup.country);
      formData.append("group[group_code]", newGroup.group_code);
      formData.append("group[zip_code]", newGroup.zip_code);
      formData.append("group[phone]", newGroup.phone);
      formData.append("group[fax]", newGroup.fax);
      formData.append("group[comments]", newGroup.comments);
      formData.append("group[enabled]", newGroup.enabled);
      // formData.append("group[company_ids]", newGroup.company_ids);

      if (newGroup.logo && newGroup.logo instanceof File) {
        formData.append("group[logo]", newGroup.logo);
      } else {
        // Handle cases where `logo` is not a file, if needed
        formData.append("group[logo]", "null");
      }

      newGroup.examiner_ids.forEach((examiner) => {
        formData.append("group[examiner_ids][]", examiner);
      });
      newGroup.subject_ids.forEach((subject) => {
        formData.append("group[subject_ids][]", subject);
      });
      newGroup.reviewer_ids.forEach((reviewer) => {
        formData.append("group[reviewer_ids][]", reviewer);
      });
      newGroup.visit_template_ids.forEach((exam_template) => {
        formData.append("group[visit_template_ids][]", exam_template);
      });
      newGroup.company_ids.forEach((company_id) => {
        formData.append("group[company_ids][]", company_id);
      });

      try {
        if (group === null) {
          await dispatch(createNewGroup(formData)).unwrap();
          toast.success("Group added successfully");
        } else {
          await dispatch(
            editGroup({ group: formData, group_id: group.id })
          ).unwrap();
          toast.success("Group updated successfully");
        }
        navigate("/groups");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <Box>
      <Accordion style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          selected={showGroupDetails}
          onClick={() => setShowGroupDetails(!showGroupDetails)}
        >
          Group Info
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            columnSpacing={10}
            rowSpacing={4}
            paddingRight={20}
            paddingTop={5}
          >
            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Name
              </Typography>
              <TextField
                value={newGroup.name}
                fullWidth
                id="name"
                name="name"
                placeholder="Name"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>
            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Address
              </Typography>
              <TextField
                value={newGroup.address}
                fullWidth
                id="address"
                name="address"
                placeholder="Address 1"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Address 2
              </Typography>
              <TextField
                value={newGroup.address_2}
                fullWidth
                id="address_2"
                name="address_2"
                placeholder="Address 2"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Group Code
              </Typography>
              <TextField
                value={newGroup.group_code}
                fullWidth
                id="group_code"
                name="group_code"
                placeholder="Group Code"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={theme.palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Account
              </Typography>

              <FormControl fullWidth>
                <Select
                  id="company_ids"
                  name="company_ids"
                  multiple
                  value={newGroup.company_ids}
                  input={<OutlinedInput label="Company" />}
                  onChange={handleChange}
                >
                  {companies &&
                    companies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.first_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                City
              </Typography>
              <TextField
                value={newGroup.city}
                fullWidth
                id="city"
                name="city"
                placeholder="City"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                State/Province
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="state"
                  id="state"
                  name="state"
                  value={newGroup.state}
                  label="State"
                  onChange={handleChange}
                >
                  {states &&
                    states.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Zip/Postal Code
              </Typography>
              <TextField
                value={newGroup.zip_code}
                fullWidth
                type="text"
                id="zip_code"
                name="zip_code"
                placeholder="Zip Code"
                // onChange={handleChange}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  if (value.length <= 9) {
                    const formattedValue =
                      value.length > 5
                        ? `${value.slice(0, 5)}-${value.slice(5, 9)}`
                        : value;
                    handleChange({
                      target: { name: "zip_code", value: formattedValue },
                    });
                  }
                }}
                inputProps={{ maxLength: 10 }}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Phone
              </Typography>
              <TextField
                value={newGroup.phone}
                fullWidth
                id="phone"
                name="phone"
                placeholder="Phone Number"
                type="text"
                inputProps={{ maxLength: 14 }}
                // onChange={handleChange}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  if (value.length <= 10) {
                    if (value.length > 6) {
                      value = `(${value.slice(0, 3)}) ${value.slice(
                        3,
                        6
                      )}-${value.slice(6, 10)}`;
                    } else if (value.length > 3) {
                      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
                    } else if (value.length > 0) {
                      value = `(${value.slice(0, 3)}`;
                    }
                    handleChange({ target: { name: "phone", value } });
                  }
                }}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Country
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="country"
                  id="country"
                  name="country"
                  value={newGroup.country}
                  label="Country"
                  onChange={handleChange}
                >
                  {countries &&
                    countries.map((country) => (
                      <MenuItem key={country.value} value={country.value}>
                        {country.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                FAX
              </Typography>
              <TextField
                value={newGroup.fax}
                fullWidth
                id="fax"
                name="fax"
                placeholder="FAX"
                type="text"
                inputProps={{ maxLength: 14 }}
                // onChange={handleChange}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  if (value.length <= 10) {
                    if (value.length > 6) {
                      value = `(${value.slice(0, 3)}) ${value.slice(
                        3,
                        6
                      )}-${value.slice(6, 10)}`;
                    } else if (value.length > 3) {
                      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
                    } else if (value.length > 0) {
                      value = `(${value.slice(0, 3)}`;
                    }
                    handleChange({ target: { name: "fax", value } });
                  }
                }}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Comments
              </Typography>
              <ReactQuill
                value={newGroup.comments}
                fullWidth
                id="comments"
                name="comments"
                placeholder="Comments"
                onChange={handleCommentsChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                {selectedImageName || "Logo"}
              </Typography>

              <Button variant="outlined" color="primary" component="label">
                {selectedImageName ? "Change Logo" : "Select Logo"}
                <input
                  hidden
                  id="logo"
                  name="logo"
                  accept="image/*"
                  type="file"
                  onChange={handleLogoSelect}
                />
              </Button>
            </Grid>

            <Grid item xs={8} md={6}>
              <FormControlLabel
                sx={{ marginTop: 4 }}
                control={
                  <Checkbox
                    onChange={(e) => handleChange(e, null)}
                    value={newGroup.enabled}
                    checked={newGroup.enabled}
                    id="enabled"
                    name="enabled"
                    color="primary"
                  />
                }
                label="Enabled"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          selected={showExaminerDetails}
          onClick={() => setShowExaminerDetails(!showExaminerDetails)}
        >
          Examiners
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            columnSpacing={10}
            rowSpacing={4}
            paddingRight={20}
            paddingTop={5}
          >
            <Grid item xs={8} md={6}>
              <FormControl fullWidth>
                <Typography id="examiners">Examiners</Typography>
                <Select
                  labelId="examiners"
                  id="examiners"
                  name="examiners"
                  multiple
                  value={newGroup.examiner_ids}
                  onChange={handleChange}
                  input={<OutlinedInput label="Examiners" />}
                >
                  {roleWiseUser?.examiners.map((examiner) => (
                    <MenuItem key={examiner.id} value={examiner.id}>
                      {examiner.last_name}, {examiner.first_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          selected={showExamTemplatesDetails}
          onClick={() => setShowExamTemplatesDetails(!showExamTemplatesDetails)}
        >
          Forms
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            columnSpacing={10}
            rowSpacing={4}
            paddingRight={20}
            paddingTop={5}
          >
            <Grid item xs={8} md={6}>
              <FormControl fullWidth>
                <Typography id="demo-multiple-exam-templates-label">
                  Forms
                </Typography>
                <Select
                  fullWidth
                  labelId="exam_templates"
                  id="exam_templates"
                  multiple
                  name="exam_templates"
                  value={newGroup.visit_template_ids}
                  onChange={handleChange}
                  input={<OutlinedInput label="Exam Templates" />}
                  MenuProps={MenuProps}
                >
                  {exam_templates?.map((exam_template) => (
                    <MenuItem
                      key={exam_template.id}
                      value={exam_template.id}
                      style={getStyles(
                        exam_template.id,
                        newGroup.visit_template_ids,
                        theme
                      )}
                    >
                      {exam_template.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          selected={showSubjectsDetails}
          onClick={() => setShowSubjectsDetails(!showSubjectsDetails)}
        >
          Workers
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            columnSpacing={10}
            rowSpacing={4}
            paddingRight={20}
            paddingTop={5}
          >
            <Grid item xs={8} md={6}>
              <FormControl fullWidth>
                <Typography id="demo-multiple-subjects-label">
                  Workers
                </Typography>
                <Select
                  labelId="demo-multiple-subjects-label"
                  id="demo-multiple-subjects-label"
                  multiple
                  name="subjects"
                  value={newGroup.subject_ids}
                  onChange={handleChange}
                  input={<OutlinedInput label="Subjects" />}
                  MenuProps={MenuProps}
                >
                  {roleWiseUser?.subjects.map((subject) => (
                    <MenuItem
                      key={subject.id}
                      value={subject.id}
                      style={getStyles(subject.id, newGroup.subject_ids, theme)}
                    >
                      {subject.last_name}, {subject.first_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          selected={showReviewersDetails}
          onClick={() => setShowReviewersDetails(!showReviewersDetails)}
        >
          Reviewers
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            columnSpacing={10}
            rowSpacing={4}
            paddingRight={20}
            paddingTop={5}
          >
            <Grid item xs={8} md={6}>
              <FormControl fullWidth>
                <Typography id="demo-multiple-reviewers-label">
                  Reviewers
                </Typography>
                <Select
                  labelId="demo-multiple-reviewers-label"
                  id="demo-multiple-reviewers-label"
                  multiple
                  name="reviewers"
                  value={newGroup.reviewer_ids}
                  onChange={handleChange}
                  input={<OutlinedInput label="Reviewers" />}
                  MenuProps={MenuProps}
                >
                  {roleWiseUser?.reviewers.map((reviewer) => (
                    <MenuItem
                      key={reviewer.id}
                      value={reviewer.id}
                      style={getStyles(
                        reviewer.id,
                        newGroup.reviewer_ids,
                        theme
                      )}
                    >
                      {reviewer.last_name}, {reviewer.first_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginTop: 5,
          minHeight: "100%",
          columnGap: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={handleGroupSubmit}
          sx={{ width: 160 }}
        >
          Save Group
        </Button>
      </Box>
    </Box>
  );
};

export default GroupForm;
