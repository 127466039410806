import { MuiButton } from "./MuiButton";
import { MuiTextField } from "./MuiTextField";
import { MuiDrawer } from "./MuiDrawer"
import { MuiSelect } from "./MuiSelect";


export const overrides = {
    MuiButton,
    MuiTextField,
    MuiDrawer,
    MuiSelect
};