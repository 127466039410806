import React, { useState, useEffect } from "react";
import { useTheme, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { editExam } from "../../redux/features/exam.js";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { statusOptions } from "../../constants/data";
import ReactQuill from "react-quill";
import { getRoleWiseUser } from "../../redux/features/user.js";
import { toast } from "react-toastify";

const ChangeStatusDialog = ({ open, handleCancel, titleText, exam }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newStatus, setNewStatus] = useState({
    status: "",
    reviewer_id: "",
    comments: "",
  });
  const roleWiseUser = useSelector((state) => state.user?.roleWiseUser);
  useEffect(() => {
    dispatch(getRoleWiseUser("")).unwrap();
    if (exam) {
      setNewStatus({
        status: exam?.status || "", // controlled from the start
        reviewer_id: exam?.reviewer_id || "",
        comments: exam?.comments || "",
      });
    }
  }, [exam]);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setNewStatus({
      ...newStatus,
      [name]: value,
    });
  };

  const handleCommentsChange = (value) => {
    setNewStatus((prevState) => ({
      ...prevState,
      comments: value,
    }));
  };

  const handleStatusSubmit = async (event) => {
    const formData = new FormData();
    formData.append("exam[status]", newStatus.status);
    formData.append("exam[reviewer_id]", newStatus.reviewer_id);
    formData.append("exam[comments]", newStatus.comments);

    try {
      await dispatch(editExam({ exam: formData, exam_id: exam.id })).unwrap();
      toast.success("Status updated successfully");
      handleCancel();
      navigate("/exams");
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{titleText}</DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <DialogContentText id="alert-dialog-description">
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Status
          </Typography>
          <FormControl fullWidth>
            <Select
              fullWidth
              id="status"
              name="status"
              value={newStatus?.status}
              input={<OutlinedInput label="Status" />}
              onChange={handleChange}
            >
              {statusOptions &&
                statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.value} value={statusOption.value}>
                    {statusOption.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
              marginTop: "10px",
            }}
          >
            Reviewer
          </Typography>
          <FormControl fullWidth>
            <Select
              fullWidth
              id="reviewer_id"
              name="reviewer_id"
              value={newStatus?.reviewer_id}
              input={<OutlinedInput label="Reviewer" />}
              onChange={handleChange}
            >
              {roleWiseUser &&
                roleWiseUser.users
                  .filter((user) => user?.roles?.includes("reviewer")) // Check if the roles array includes "reviewer"
                  .map((reviewer) => (
                    <MenuItem key={reviewer.id} value={reviewer.id}>
                      {reviewer.last_name}, {reviewer.first_name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
              marginTop: "10px",
            }}
          >
            Comments
          </Typography>
          <ReactQuill
            id="comments"
            name="comments"
            value={newStatus?.comments}
            onChange={handleCommentsChange}
            placeholder="Comments"
            style={{ backgroundColor: "#fff", borderRadius: "4px" }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          sx={{ color: theme.palette.primary.main, fontSize: "15px" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleStatusSubmit}
          autoFocus
          sx={{ color: "green", fontSize: "15px" }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeStatusDialog;
