import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";
import OrganizationForm from "./OrganizationForm.jsx";
import { getOrganization } from "../../redux/features/organization.js";

const EditOrganization = () => {
  const { id } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const organization = useSelector(
    (state) => state.organization.currentOrganization
  );

  const isLoading = useSelector((state) => state.organization.isLoading);

  useEffect(() => {
    dispatch(getOrganization({ organizationId: id }));
  }, [dispatch, id]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Edit Organization
        </Typography>
      </Box>

      {isLoading ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <OrganizationForm organization={organization} />
      )}
    </Box>
  );
};

export default EditOrganization;
