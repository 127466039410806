import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  deleteTestFunction,
  getAllTestFunctions,
} from "../../redux/features/test_function";
import { updateOrder } from "../../redux/features/test";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import GradientCircularProgress from "../../elements/GradientCircularProgress";
import EmptyListMessage from "../common/EmptyListMessage";

const TestFunctions = ({ test, onEditTestFunction = () => {} }) => {
  const dispatch = useDispatch();

  const testsFunctionsList = useSelector(
    (state) => state.testFunction.testFunctionsList
  );
  const isLoading = useSelector((state) => state.testFunction.isLoading);

  const [open, setOpen] = useState(false);
  const [selectedTestFunction, setSelectedTestFunction] = useState(null);
  const [testFunctions, setTestFunctions] = useState([]);

  useEffect(() => {
    dispatch(getAllTestFunctions({ test_id: test.id }));
  }, [dispatch, test.id]);

  useEffect(() => {
    setTestFunctions(testsFunctionsList);
  }, [testsFunctionsList]);

  const handleDeleteTestFunctionClick = (event, testFunction) => {
    event.preventDefault();
    setSelectedTestFunction(testFunction);
    setOpen(true);
  };

  const handleDeleteConfirmation = () => {
    if (selectedTestFunction) {
      dispatch(
        deleteTestFunction({
          testId: test.id,
          testFunctionId: selectedTestFunction.id,
        })
      );
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedTestFunction(null);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedList = Array.from(testFunctions);
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);

    setTestFunctions(reorderedList);

    const updatedOrder = reorderedList.map((item) => item.id);
    dispatch(updateOrder({ testId: test.id, order: updatedOrder }));
  };

  return (
    <Box>
      {isLoading ? (
        <GradientCircularProgress open={isLoading} />
      ) : testFunctions && testFunctions.length === 0 ? (
        <EmptyListMessage
          messageHeader="No test fields available."
          message="It looks like there are no test fields added to this test yet."
        />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppableTestFunctions">
            {(provided) => (
              <List
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{ width: "100%" }}
              >
                {testFunctions &&
                  testFunctions.map((testFunction, index) => (
                    <Draggable
                      key={testFunction.id}
                      draggableId={testFunction.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          disablePadding
                          sx={{
                            backgroundColor: "#F6F6F6",
                            marginBottom: 1,
                            borderRadius: "13px",
                          }}
                        >
                          <ListItemButton
                            role={undefined}
                            dense
                            sx={{
                              height: "55px",
                              cursor: "pointer",
                            }}
                            onClick={() => onEditTestFunction(testFunction)}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                paddingLeft: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  lineHeight: "19.36px",
                                }}
                              >
                                {testFunction?.display_name}
                                {testFunction?.function_name
                                  ? ` | ${testFunction.function_name}`
                                  : ""}
                                {testFunction?.data_type
                                  ? ` | ${testFunction.data_type}`
                                  : ""}
                                {testFunction?.detail &&
                                testFunction?.data_type !== "ReadOnlyText"
                                  ? ` | ${testFunction.detail}`
                                  : ""}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  lineHeight: "14.52px",
                                  fontWeight: 300,
                                }}
                              ></Typography>
                            </Box>
                          </ListItemButton>
                          <Box
                            sx={{
                              paddingRight: 2,
                              display: "flex",
                              columnGap: 1,
                            }}
                          >
                            <IconButton
                              sx={{ p: "10px" }}
                              onClick={(event) =>
                                handleDeleteTestFunctionClick(
                                  event,
                                  testFunction
                                )
                              }
                              id={testFunction.id}
                            >
                              <DeleteOutlinedIcon sx={{ color: "red" }} />
                            </IconButton>
                          </Box>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      )}

      <DeleteConfirmationDialog
        open={open}
        handleCancel={handleCancel}
        titleText="Confirm Delete"
        contentText="Are you sure you want to delete"
        title={selectedTestFunction?.display_name}
        suffixText="from test functions list?"
        handleDelete={handleDeleteConfirmation}
      />
    </Box>
  );
};

export default TestFunctions;
