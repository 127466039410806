import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Checkbox,
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { getAllGroups } from "../../redux/features/group.js";
import { createNewExam, editExam } from "../../redux/features/exam.js";
import {
  getFormTemplate,
  getFormTemplates,
} from "../../redux/features/form_template.js";
import { getAllUsers } from "../../redux/features/user.js";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { statusOptions } from "../../constants/data";

const steps = [
  "Choose Group",
  "Choose Worker",
  "Choose Template",
  "Choose Tests",
  "Send a Survey",
];

export default function HorizontalNonLinearStepper({ exam }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const groups = useSelector((state) => state.group?.groupsList);
  const workers = useSelector((state) => state.user?.usersList);
  const forms = useSelector((state) => state.formTemplate?.formTemplates);
  const selectedForm = useSelector(
    (state) => state.formTemplate?.currentFormTemplate
  );
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [subjectDetails, setSubjectDetails] = useState([]);
  const [formDetails, setFormDetails] = useState([]);

  const [newExam, setNewExam] = useState({
    group_id: exam?.group_id || "",
    subject_id: exam?.subject_id || "",
    visit_template_id: exam?.visit_template_id || "",
    test_ids: exam?.test_ids || [],
    exam_title: exam?.exam_title || "",
    exam_sent: exam?.exam_sent || false,
    subject_email: exam?.subject_email || "",
    status: exam?.status || "",
    reviewer_id: exam?.reviewer_id || "",
    comments: exam?.comments || "",
    custom_invitation: exam?.custom_invitation || "",
  });

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const getSubjectEmailById = (subjectId) => {
    const subject = workers?.find((s) => s.id === subjectId);
    return subject.email;
  };

  useEffect(() => {
    dispatch(
      getAllGroups({
        searchQuery: "",
        page: "",
        rowsPerPage: "",
      })
    );
    dispatch(
      getAllUsers({
        searchValue: searchQuery,
        page: page,
        rowsPerPage: rowsPerPage,
        role: "subject",
      })
    );
    dispatch(getFormTemplates(""));
    setNewExam((prevExam) => ({
      ...prevExam,
      status: statusOptions[0].value,
    }));
  }, [dispatch, page, rowsPerPage, searchQuery]);

  useEffect(() => {
    if (selectedForm?.tests?.length && !exam) {
      setNewExam((prevExam) => ({
        ...prevExam,
        test_ids: selectedForm?.tests.map((test) => test.id), // Only store the test ids
      }));
    }
  }, [selectedForm]);

  useEffect(() => {
    if (newExam.group_id) {
      const matchedGroup = groups.find(
        (group) => group.id === newExam.group_id
      );
      setSelectedGroup(matchedGroup || null);
    } else {
      setSelectedGroup(null);
    }
    if (!exam?.subject_email && newExam.subject_id) {
      newExam.subject_email = getSubjectEmailById(newExam.subject_id);
    }
  }, [newExam.group_id, groups, newExam.subject_id]);

  useEffect(() => {
    if (newExam.visit_template_id) {
      dispatch(getFormTemplate({ formTemplateId: newExam.visit_template_id }));
    }
  }, [newExam.visit_template_id, forms]);

  useEffect(() => {
    if (selectedGroup?.subject_ids?.length > 0) {
      const matchedSubjects = workers.filter((subject) =>
        selectedGroup.subject_ids.includes(subject.id)
      );
      setSubjectDetails(matchedSubjects);
    } else {
      setSubjectDetails([]);
    }
    if (selectedGroup?.visit_templates_ids?.length > 0) {
      const matchedForms = forms.filter((form) =>
        selectedGroup.visit_templates_ids.includes(form.id)
      );
      setFormDetails(matchedForms);
    } else {
      setFormDetails([]);
    }
  }, [
    selectedGroup?.subject_ids,
    selectedGroup?.visit_template_ids,
    workers,
    forms,
  ]);

  const handleChange = async (event) => {
    const { name, value } = event.target;

    setNewExam((prevExam) => {
      let updatedExam = {
        ...prevExam,
        [name]: value,
      };

      // Empty subject_email if exam_sent is "false"
      if (name === "exam_sent" && value === "false") {
        updatedExam.subject_email = "";
        updatedExam.custom_invitation = "";
      }

      return updatedExam;
    });
  };

  const handleToggle = (testId) => {
    const updatedTests = newExam.test_ids.includes(testId)
      ? newExam.test_ids.filter((id) => id !== testId)
      : [...newExam.test_ids, testId];
    setNewExam({
      ...newExam,
      test_ids: updatedTests,
    });
  };

  const handleNext = () => {
    let hasError = false;

    if (activeStep === 0 && !newExam.group_id) {
      toast.error("Please select a group.");
      hasError = true;
    }
    if (activeStep === 1 && !newExam.subject_id) {
      toast.error("Please select a subject.");
      hasError = true;
    }
    if (activeStep === 2 && !newExam.visit_template_id) {
      toast.error("Please select a template.");
      hasError = true;
    }
    if (activeStep === 3 && newExam.test_ids.length === 0) {
      toast.error("Please select at least one test.");
      hasError = true;
    }

    if (hasError) return;

    const newActiveStep = isLastStep() ? totalSteps() - 1 : activeStep + 1; // Do not increment if it's the last step
    setActiveStep(newActiveStep);
    if (newActiveStep === totalSteps() - 1) {
      setCompleted({ ...completed, [newActiveStep]: true }); // Mark last step as complete
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleExamSubmit = async (event) => {
    const formData = new FormData();
    formData.append("exam[group_id]", newExam.group_id);
    formData.append("exam[subject_id]", newExam.subject_id);
    formData.append("exam[visit_template_id]", newExam.visit_template_id);
    formData.append("exam[exam_title]", selectedForm.title);
    formData.append("exam[status]", newExam.status);
    formData.append("exam[reviewer_id]", newExam.reviewer_id);
    formData.append("exam[comments]", newExam.comments);
    formData.append("exam[subject_email]", newExam.subject_email);
    formData.append("exam[exam_sent]", newExam.exam_sent);
    formData.append("exam[custom_invitation]", newExam.custom_invitation);

    newExam.test_ids.forEach((test) => {
      formData.append("exam[test_ids][]", test);
    });

    try {
      if (exam === null) {
        await dispatch(createNewExam(formData)).unwrap();
        toast.success("Exam added successfully");
      } else {
        await dispatch(editExam({ exam: formData, exam_id: exam.id })).unwrap();
        toast.success("Exam updated successfully");
      }
      navigate("/exams");
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleComplete = () => {
    // If it's the last step
    if (isLastStep()) {
      // Check if all steps are completed
      if (completedSteps() === totalSteps() - 1) {
        handleExamSubmit(); // Submit the form
      } else {
        setCompleted({
          ...completed,
          [activeStep]: true,
        });
        handleNext(); // Move to the next step
      }
    } else {
      // For steps other than the last step
      setCompleted({
        ...completed,
        [activeStep]: true,
      });
      handleNext();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ marginTop: 5, width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel onClick={handleStep(index)}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Card
        sx={{
          marginTop: 5,
          padding: 5,
          paddingLeft: 10,
          borderRadius: "16px",
          boxShadow: "0px 0px 10px 0 #0000001a",
        }}
      >
        {activeStep === 0 && (
          <Box>
            <Grid
              container
              columnSpacing={10}
              rowSpacing={4}
              paddingRight={20}
              paddingTop={5}
            >
              <Grid item xs={8} md={6}>
                <Typography
                  color={theme.palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}
                >
                  Group
                  <Typography
                    component="span"
                    sx={{ color: "red", marginLeft: "2px" }}
                  >
                    *
                  </Typography>
                </Typography>

                <FormControl fullWidth>
                  <Select
                    id="group_id"
                    name="group_id"
                    value={newExam.group_id}
                    input={<OutlinedInput label="Group" />}
                    onChange={handleChange}
                  >
                    {groups &&
                      groups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        )}
        {activeStep === 1 && (
          <Box>
            <Grid
              container
              columnSpacing={10}
              rowSpacing={4}
              paddingRight={20}
              paddingTop={5}
            >
              <Grid item xs={8} md={6}>
                <Typography
                  color={theme.palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}
                >
                  Worker
                  <Typography
                    component="span"
                    sx={{ color: "red", marginLeft: "2px" }}
                  >
                    *
                  </Typography>
                </Typography>

                <FormControl fullWidth>
                  <Select
                    id="subject_id"
                    name="subject_id"
                    value={newExam.subject_id}
                    input={<OutlinedInput label="Worker" />}
                    onChange={handleChange}
                  >
                    {subjectDetails &&
                      subjectDetails.map((subjectDetail) => (
                        <MenuItem
                          key={subjectDetail.id}
                          value={subjectDetail.id}
                        >
                          {subjectDetail.last_name}, {subjectDetail.first_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        )}
        {activeStep === 2 && (
          <Box>
            <Grid
              container
              columnSpacing={10}
              rowSpacing={4}
              paddingRight={20}
              paddingTop={5}
            >
              <Grid item xs={8} md={6}>
                <Typography
                  color={theme.palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}
                >
                  Forms
                  <Typography
                    component="span"
                    sx={{ color: "red", marginLeft: "2px" }}
                  >
                    *
                  </Typography>
                </Typography>

                <FormControl fullWidth>
                  <Select
                    id="visit_template_id"
                    name="visit_template_id"
                    value={newExam.visit_template_id}
                    input={<OutlinedInput label="Form" />}
                    onChange={handleChange}
                  >
                    {formDetails &&
                      formDetails.map((formDetail) => (
                        <MenuItem key={formDetail.id} value={formDetail.id}>
                          {formDetail.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        )}
        {activeStep === 3 && (
          <Box>
            <Typography>
              Available Tests:
              <Typography
                component="span"
                sx={{ color: "red", marginLeft: "2px" }}
              >
                *
              </Typography>
            </Typography>
            <List>
              {selectedForm?.tests?.map((test) => {
                const labelId = `checkbox-list-label-${test.id}`;
                return (
                  <ListItem
                    key={test.id}
                    disablePadding
                    sx={{
                      backgroundColor: "#F6F6F6",
                      marginBottom: 1,
                      borderRadius: "13px",
                    }}
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleToggle(test.id)} // Call the toggle function with the test id
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={newExam.test_ids.includes(test.id)} // Check if the test is in the test_ids array
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={test.title} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )}
        {activeStep === 4 && (
          <Box>
            <FormLabel component="legend">Send Survey:</FormLabel>
            <RadioGroup
              aria-label="exam_sent"
              name="exam_sent"
              value={newExam.exam_sent}
              onChange={handleChange}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
            {(newExam.exam_sent === "true" || newExam.exam_sent === true) && (
              <Grid
                container
                columnSpacing={10}
                rowSpacing={4}
                paddingRight={20}
                paddingTop={5}
              >
                <Grid item xs={8} md={6}>
                  <TextField
                    label="Email"
                    name="subject_email"
                    value={newExam.subject_email}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField
                    label="Custom Invitation"
                    name="custom_invitation" // Add this name to manage its value
                    value={newExam.custom_invitation} // Update this in your state
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline // Set multiline for text area
                    rows={4} // Set the number of visible rows
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </Card>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {activeStep + 1}
            </Typography> */}
            <Box
              sx={{
                marginTop: 5,
                display: "flex",
                flexDirection: "row",
                pt: 2,
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleComplete}>
                {activeStep === totalSteps() - 1 &&
                completedSteps() === totalSteps() - 1
                  ? "Finish"
                  : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
