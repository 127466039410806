import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Slider,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
  Input,
} from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import { penColors } from "../../constants/data";

const SignatureField = ({
  signatureCanvasRef,
  fieldValue,
  signatureName,
  onPrintNameChange,
  error
}) => {
  const [signaturePenColor, setSignaturePenColor] = useState("#000000"); // Default color
  const [signaturePenWidth, setSignaturePenWidth] = useState(2); // Default width
  const formattedDate = new Date().toLocaleDateString(); // Format the current date
  const theme = useTheme();

  const handleSignatureSliderChange = (event, newValue) => {
    setSignaturePenWidth(newValue);
  };
  console.log(error, 'error')

  useEffect(() => {
    if (fieldValue && signatureCanvasRef.current) {
      // Load the existing signature if fieldValue is provided
      const img = new Image();
      img.crossOrigin = "anonymous"; // Enable CORS
      img.src = fieldValue;

      img.onload = () => {
        // Clear the canvas and draw the image
        signatureCanvasRef.current.clear();
        signatureCanvasRef.current
          .getCanvas()
          .getContext("2d")
          .drawImage(img, 0, 0);
      };
    }
  }, [fieldValue]);

  return (
    <Box className="signature-container">
      <SignatureCanvas
        ref={signatureCanvasRef}
        penColor={signaturePenColor}
        backgroundColor="#f5f7fa"
        minWidth={signaturePenWidth}
        maxWidth={signaturePenWidth}
        canvasProps={{
          width: 500,
          height: 200,
          className: "signature-canvas",
          style: { border: error ? "2px solid red" : "1px solid #ccc" },
        }}
        helperText={error}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 1,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            color={theme.palette.primary.gray}
            sx={{ mr: 1, fontSize: "12px", fontWeight: 600 }}
          >
            Print Name:
          </Typography>
          <Input
            sx={{
              marginBottom: 1,
              fontSize: "12px",
              lineHeight: "16.63px",
              fontWeight: 600,
            }}
            onChange={onPrintNameChange}
            defaultValue={signatureName}
          />
        </Box>
        <Typography
          color={theme.palette.primary.gray}
          sx={{ fontSize: "12px", fontWeight: 600 }}
        >
          {formattedDate}
        </Typography>
      </Box>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Slider
            step={1}
            min={1}
            max={5}
            marks
            value={signaturePenWidth}
            onChange={handleSignatureSliderChange}
            valueLabelDisplay="auto"
          />
          <RadioGroup
            row
            value={signaturePenColor}
            onChange={(e) => setSignaturePenColor(e.target.value)}
            sx={{ width: "60%", padding: "0px", ml: 2 }}
          >
            {penColors.map((color) => (
              <FormControlLabel
                key={color}
                value={color}
                control={
                  <Radio
                    sx={{
                      color: color,
                      padding: "0px",
                      "&.Mui-checked": { color: color },
                    }}
                  />
                }
              />
            ))}
          </RadioGroup>
          <Button
            variant="outlined"
            onClick={() => signatureCanvasRef.current.clear()}
            sx={{ width: "fit-content", paddingY: 0 }}
          >
            Clear
          </Button>
        </Box>
        {error && <Typography color="error">This field is Required.</Typography>}
      </Box>
    </Box>
  );
};

export default SignatureField;
