import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, useTheme } from "@mui/material";
import { palette } from "../../theme/palette.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createCompany, editCompany } from "../../redux/features/company.js";
import { getAllTags } from "../../redux/features/tag.js";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import ReactQuill from "react-quill";

const CompanyForm = ({ company }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tagsList = useSelector((state) => state.tag.tagsList);

  const extractAssociatedIds = (arrayList) => {
    return arrayList?.map((item) => item.id);
  };

  const [newCompany, setNewCompany] = useState({
    account_name: company?.account_name || "",
    account_url: company?.account_url || "",
    comments: company?.comments || "",
    address: company?.address || "",
    first_name: company?.first_name || "",
    last_name: company?.last_name || "",
    email: company?.email || "",
    phone: company?.phone || "",
    label_ids: extractAssociatedIds(company?.labels) || [],
    company_logo: company?.company_logo || null,
  });

  const [selectedImageName, setSelectedImageName] = useState("");

  const [logoFileLink, setLogoFileLink] = useState(
    company?.company_logo || null
  );

  const handleChange = (event) => {
    setNewCompany({
      ...newCompany,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogoSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const logoURL = URL.createObjectURL(file);
      setLogoFileLink(logoURL);
      setNewCompany((prevTest) => ({
        ...prevTest,
        company_logo: file,
      }));
      setSelectedImageName(file.name);
    }
  };

  const handleCommentsChange = (value) => {
    setNewCompany((prevState) => ({
      ...prevState,
      comments: value,
    }));
  };

  useEffect(() => {
    dispatch(getAllTags(""));
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newCompany.account_name === "") {
      toast.error("Please enter Account Name");
    } else if (newCompany.address === "") {
      toast.error("Please enter Account Address");
    } else {
      const formData = new FormData();
      formData.append("company[account_name]", newCompany.account_name);
      formData.append("company[account_url]", newCompany.account_url);
      formData.append("company[comments]", newCompany.comments);
      formData.append("company[first_name]", newCompany.first_name);
      formData.append("company[last_name]", newCompany.last_name);
      formData.append("company[email]", newCompany.email);
      formData.append("company[phone]", newCompany.phone);
      formData.append("company[address]", newCompany.address);
      formData.append("company[company_logo]", newCompany.company_logo);

      if (newCompany.company_logo && newCompany.company_logo instanceof File) {
        formData.append("company[company_logo]", newCompany.company_logo);
      } else {
        // Handle cases where `logo` is not a file, if needed
        formData.append("company[company_logo]", "null");
      }

      if (newCompany?.label_ids) {
        newCompany.label_ids.forEach((label) => {
          formData.append("company[label_ids][]", label);
        });
      }

      try {
        if (company === null) {
          await dispatch(createCompany({ company: formData })).unwrap();
          toast.success("Company added successfully");
        } else {
          await dispatch(
            editCompany({ company: formData, companyId: company.id })
          ).unwrap();
          toast.success("Company updated successfully");
        }
        navigate("/companies");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <Box>
      <Grid
        container
        columnSpacing={10}
        rowSpacing={4}
        paddingRight={20}
        paddingTop={5}
      >
        {/* <Grid item xs={8} md={6}>
          <Box
            sx={{
              position: "relative",
              width: "fit-content",
            }}
          >
            <Avatar
              alt="Company Logo"
              src={logoFileLink}
              sx={{ width: "160px", height: "160px" }}
            />

            <IconButton
              variant="outlined"
              color="primary"
              component="label"
              sx={{
                width: "48px",
                height: "48px",
                border: "2px solid",
                borderColor: "white",
                backgroundColor: theme.palette.primary.main,
                borderRadius: "50%",
                position: "absolute",
                bottom: "0px",
                right: "0px",
                ":hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              <AddPhotoAlternateOutlinedIcon sx={{ color: "white" }} />
              <input
                hidden
                id="company_logo"
                name="company_logo"
                accept="image/*"
                type="file"
                onChange={handleLogoSelect}
              />
            </IconButton>
          </Box>
        </Grid> */}

        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Account Name
            <Typography
              component="span"
              sx={{ color: "red", marginLeft: "2px" }}
            >
              *
            </Typography>
          </Typography>
          <TextField
            value={newCompany.account_name}
            fullWidth
            id="account_name"
            name="account_name"
            placeholder="Account Name"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            {selectedImageName || "Account Image"}
          </Typography>

          <Button variant="outlined" color="primary" component="label">
            {selectedImageName ? "Change Logo" : "Select Logo"}
            <input
              hidden
              id="logo"
              name="logo"
              accept="image/*"
              type="file"
              onChange={handleLogoSelect}
            />
          </Button>
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Account URL
          </Typography>
          <TextField
            value={newCompany.account_url}
            fullWidth
            id="account_url"
            name="account_url"
            placeholder="Account URL"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Account Address
            <Typography
              component="span"
              sx={{ color: "red", marginLeft: "2px" }}
            >
              *
            </Typography>
          </Typography>
          <TextField
            value={newCompany.address}
            fullWidth
            id="address"
            name="address"
            placeholder="Account Address"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Contact First Name
          </Typography>
          <TextField
            value={newCompany.first_name}
            fullWidth
            id="first_name"
            name="first_name"
            placeholder="Contact First Name"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Contact Last Name
          </Typography>
          <TextField
            value={newCompany.last_name}
            fullWidth
            id="last_name"
            name="last_name"
            placeholder="Contact Last Name"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Email
          </Typography>
          <TextField
            value={newCompany.email}
            fullWidth
            id="email"
            name="email"
            placeholder="Email"
            type="email"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Phone
          </Typography>
          <TextField
            value={newCompany.phone}
            fullWidth
            id="phone"
            name="phone"
            placeholder="Phone number"
            type="text"
            onChange={(e) => {
              let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              if (value.length <= 10) {
                if (value.length > 6) {
                  value = `(${value.slice(0, 3)}) ${value.slice(
                    3,
                    6
                  )}-${value.slice(6, 10)}`;
                } else if (value.length > 3) {
                  value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
                } else if (value.length > 0) {
                  value = `(${value.slice(0, 3)}`;
                }
                handleChange({ target: { name: "phone", value } });
              }
            }}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Tags
          </Typography>

          <FormControl fullWidth>
            <Select
              displayEmpty
              multiple
              id="label_ids"
              name="label_ids"
              value={newCompany.label_ids}
              onChange={handleChange}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <span style={{ color: theme.palette.primary.gray }}>
                      Select Tags
                    </span>
                  );
                }

                const selectedTags = tagsList
                  .filter((tag) => selected.includes(tag.id))
                  .map((tag) => tag.title)
                  .join(", ");
                return selectedTags;
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {tagsList &&
                tagsList.map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    <Checkbox
                      checked={newCompany.label_ids?.includes(tag.id)}
                    />
                    <ListItemText primary={tag.title} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Comments
          </Typography>

          <ReactQuill
            value={newCompany.comments}
            onChange={handleCommentsChange}
            placeholder="Comments"
            style={{ backgroundColor: "#fff", borderRadius: "4px" }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginRight: 10,
          marginTop: 10,
          minHeight: "100%",
          columnGap: 2,
        }}
      >
        <Button variant="contained" onClick={handleSubmit} sx={{ width: 160 }}>
          Save Account
        </Button>
      </Box>
    </Box>
  );
};

export default CompanyForm;
