import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  InputBase,
  IconButton,
  Button,
  Link,
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon } from "@mui/icons-material";
import { getAllExams } from "../../redux/features/exam.js";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GradientCircularProgress from "../../elements/GradientCircularProgress";
import TableList from "./TableList.jsx";

const Exams = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const exams = useSelector((state) => state.exam?.examsList);
  const isLoading = useSelector((state) => state.exam?.isLoading);
  const totalExamsCount = useSelector(
    (state) => state.exam?.totalExamsCount
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [focused, setFocused] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    dispatch(
        getAllExams({
            searchQuery: searchQuery,
            page: page,
            rowsPerPage: rowsPerPage,
        })
    );
  }, [dispatch, page, rowsPerPage, searchQuery]);

  const handleExamListItemClick = (exam) => {
    navigate(`/exams/edit-exam/${exam.id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Exams
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
          }}
        >
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search test"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search test" }}
              type="search"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Paper>

          <Link>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/exams/new-exam");
              }}
            >
              Create Exam
            </Button>
          </Link>
        </Box>
      </Box>

      {isLoading && !searchQuery ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <TableList
          exams={exams}
          totalExams={totalExamsCount}
          page={page}
          rowsPerPage={rowsPerPage}
          handleExamListItemClick={handleExamListItemClick}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

export default Exams;
