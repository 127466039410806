import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import TagForm from "./TagForm.jsx";

const NewTag = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          New Tag
        </Typography>
      </Box>

      <TagForm tag={null} />
    </Box>
  );
};

export default NewTag;
