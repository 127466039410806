import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  createNewTestFunction,
  editTestFunction,
  copyTestFunction,
} from "../../redux/features/test_function";
import { toast } from "react-toastify";
import { dataTypes } from "../../constants/data.js";
import AddIcon from "@mui/icons-material/Add";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import IconButton from "@mui/material/IconButton";
import {
  functionIntegerFormats,
  functionDecimalFormats,
} from "../../constants/data.js";

const fetchPickListOptions = (testFunction) => {
  if (testFunction?.picklist_options.length > 0) {
    const options = testFunction?.picklist_options.map(
      (pickList) => pickList.option_text
    );
    return options;
  }
};

const TestFunctionForm = ({ test, testFunction, handleTabChange }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [newTestFunction, setNewTestFunction] = useState({
    display_name: testFunction?.display_name || "",
    function_name: testFunction?.function_name || "",
    detail: testFunction?.detail || "",
    read_only_detail: testFunction?.read_only_detail || "",
    data_type: testFunction?.data_type || "",
    format: testFunction?.format || "",
    character_limit: testFunction?.character_limit || "",
    min_value: testFunction?.min_value || "",
    max_value: testFunction?.max_value || "",
    required: testFunction?.required || false,
    line_break: testFunction?.line_break || false,
    pick_list_options: fetchPickListOptions(testFunction) || [""],
  });

  const handleChange = (event, index = null) => {
    const { name, value } = event.target;

    // Handle data_type change
    if (name === "data_type") {
      if (
        newTestFunction.data_type === "ReadOnlyText" &&
        value !== "ReadOnlyText"
      ) {
        setNewTestFunction((prevState) => ({
          ...prevState,
          [name]: value,
          detail: "", // Clear detail if changing from ReadOnlyText
          required: false,
        }));
      } else {
        setNewTestFunction((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
      return;
    }

    if (name === "required" || name === "line_break") {
      setNewTestFunction((prevState) => ({
        ...prevState,
        [name]: event.target.checked,
      }));

      return;
    }

    if (index !== null) {
      setNewTestFunction((prevState) => {
        const updatedOptions = prevState.pick_list_options.map((option, i) =>
          i === index ? value : option
        );
        return { ...prevState, pick_list_options: updatedOptions };
      });
    } else {
      setNewTestFunction((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleEditorChange = (content) => {
    setNewTestFunction((prevState) => ({
      ...prevState,
      detail: content,
    }));
  };

  const handleAddOption = () => {
    setNewTestFunction((prevState) => ({
      ...prevState,
      pick_list_options: [...prevState.pick_list_options, ""],
    }));
  };

  const handleRemoveOption = (index) => {
    setNewTestFunction((prevState) => ({
      ...prevState,
      pick_list_options: prevState.pick_list_options.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleTestFunctionSubmit = async (event) => {
    event.preventDefault();

    if (newTestFunction.display_name === "") {
      toast.error("Please enter display name");
    } else if (newTestFunction.function_name === "") {
      toast.error("Please enter function name");
    } else if (newTestFunction.data_type === "") {
      toast.error("Please enter data type");
    } else {
      try {
        if (testFunction === null) {
          await dispatch(
            createNewTestFunction({
              test_function: newTestFunction,
              test_id: test.id,
            })
          ).unwrap();
          toast.success("Test field added successfully");
        } else {
          await dispatch(
            editTestFunction({
              testFunction: newTestFunction,
              testId: test.id,
              testFunctionId: testFunction.id,
            })
          ).unwrap();
          toast.success("Test field updated successfully");
        }
        handleTabChange(2);
        resetForm();
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const handleCopyTestFieldSubmit = async (event) => {
    event.preventDefault();

    try {
      await dispatch(
        copyTestFunction({
          testFunction: newTestFunction,
          testId: test.id,
          testFunctionId: testFunction.id,
        })
      ).unwrap();
      toast.success("Test field copied successfully");
      handleTabChange(2);
      resetForm();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const resetForm = () =>
    setNewTestFunction({
      display_name: "",
      function_name: "",
      detail: "",
      read_only_detail: "",
      data_type: "",
      min_value: 0,
      max_value: "",
      required: false,
      pick_list_options: [""],
    });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container columnSpacing={10} rowSpacing={4}>
        <Grid item xs={8} md={6}>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Display Name
          </Typography>
          <TextField
            value={newTestFunction.display_name}
            fullWidth
            id="display_name"
            name="display_name"
            placeholder="Display Name"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>
        <Grid item xs={8} md={6}>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Field Name
          </Typography>
          <TextField
            value={newTestFunction.function_name}
            fullWidth
            id="function_name"
            name="function_name"
            placeholder="Function Name"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>
        <Grid item xs={8} md={6}>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            {newTestFunction.data_type === "ReadOnlyText"
              ? "Description"
              : "Details"}
          </Typography>
          {newTestFunction.data_type === "ReadOnlyText" ? (
            <ReactQuill
              value={newTestFunction.detail}
              onChange={handleEditorChange}
              theme="snow"
              style={{ height: "300px" }}
              modules={{
                toolbar: [
                  [{ header: [false, 1, 2, 3] }, { font: [] }], // Added "H3" header and normal text
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["bold", "italic", "underline"],
                  [{ align: [] }],
                ],
                clipboard: {
                  matchVisual: false,
                },
              }}
              formats={[
                "header",
                "font",
                "list",
                "bullet",
                "bold",
                "italic",
                "underline",
                "link",
                "image",
                "align",
              ]}
            />
          ) : (
            <TextField
              value={newTestFunction.detail}
              fullWidth
              id="detail"
              name="detail"
              placeholder="Details"
              onChange={handleChange}
              sx={{ marginTop: 0 }}
            />
          )}
        </Grid>
        <Grid
          item
          xs={8}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Data Type
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <FormControl fullWidth>
              <Select
                displayEmpty
                id="data_type"
                name="data_type"
                variant="outlined"
                value={newTestFunction.data_type}
                onChange={(e) => handleChange(e, null)}
                inputProps={{ "aria-label": "Without label" }}
              >
                {dataTypes.map((status, index) => (
                  <MenuItem key={index} value={status.value}>
                    {status.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {newTestFunction.data_type === "Picklist" && (
              <IconButton
                variant="outlined"
                color="primary"
                onClick={handleAddOption}
                sx={{
                  width: "fit-content",
                  height: "fit-content",
                  border: "1px solid",
                  borderColor: "primary",
                  borderRadius: "50%",
                }}
              >
                <AddIcon />
              </IconButton>
            )}
          </Box>

          {newTestFunction.data_type === "ReadOnlyText" && (
            <Grid sx={{ paddingTop: 4 }}>
              <Typography
                color={theme.palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Details
              </Typography>
              <TextField
                value={newTestFunction.read_only_detail}
                fullWidth
                id="read_only_detail"
                name="read_only_detail"
                placeholder="Details"
                onChange={handleChange}
                sx={{ marginTop: 0 }}
              />
            </Grid>
          )}
        </Grid>
        {(newTestFunction.data_type === "Integer" ||
          newTestFunction.data_type === "Decimal") && (
          <>
            <Grid item xs={8} md={6}>
              <Typography
                color={theme.palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Min Value
              </Typography>
              <TextField
                value={
                  newTestFunction.data_type === "Integer"
                    ? parseInt(newTestFunction.min_value, 10)
                    : newTestFunction.min_value
                }
                fullWidth
                id="min_value"
                name="min_value"
                placeholder="Min Value"
                type="number"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>
            <Grid item xs={8} md={6}>
              <Typography
                color={theme.palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Max Value
              </Typography>
              <TextField
                value={
                  newTestFunction.data_type === "Integer"
                    ? parseInt(newTestFunction.max_value, 10)
                    : newTestFunction.max_value
                }
                fullWidth
                id="max_value"
                name="max_value"
                placeholder="Max Value"
                type="number"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>
          </>
        )}

        {newTestFunction.data_type === "Picklist" && (
          <>
            {newTestFunction.pick_list_options.map((option, index) => (
              <Grid item xs={8} md={6} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    columnGap: 2,
                  }}
                >
                  <TextField
                    value={option}
                    fullWidth
                    id={`picklist_option_${index}`}
                    placeholder={`Option ${index + 1}`}
                    onChange={(e) => handleChange(e, index)}
                  />

                  <IconButton
                    variant="outlined"
                    color="primary"
                    onClick={() => handleRemoveOption(index)}
                    sx={{
                      width: "fit-content",
                      height: "fit-content",
                      border: "1px solid",
                      borderColor: "primary",
                      borderRadius: "50%",
                    }}
                  >
                    <RemoveOutlinedIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </>
        )}
        {(newTestFunction.data_type === "Integer" ||
          newTestFunction.data_type === "Decimal") && (
          <Grid item xs={8} md={6}>
            <Typography
              color={theme.palette.primary.gray}
              sx={{
                fontSize: "13.74px",
                lineHeight: "16.63px",
                marginBottom: "10px",
                fontWeight: 700,
              }}
            >
              Format
            </Typography>
            <FormControl fullWidth>
              <Select
                displayEmpty
                id="format"
                name="format"
                variant="outlined"
                value={newTestFunction.format}
                onChange={(e) => handleChange(e, null)}
                inputProps={{ "aria-label": "Without label" }}
              >
                {newTestFunction.data_type === "Integer"
                  ? functionIntegerFormats.map((format, index) => (
                      <MenuItem key={index} value={format.value}>
                        {format.title}
                      </MenuItem>
                    ))
                  : functionDecimalFormats.map((format, index) => (
                      <MenuItem key={index} value={format.value}>
                        {format.title}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {(newTestFunction.data_type === "String" ||
          newTestFunction.data_type === "Notes") && (
          <Grid item xs={8} md={6}>
            <Typography
              color={theme.palette.primary.gray}
              sx={{
                fontSize: "13.74px",
                lineHeight: "16.63px",
                marginBottom: "10px",
                fontWeight: 700,
              }}
            >
              Characters Limit
            </Typography>
            <TextField
              value={parseInt(newTestFunction.character_limit, 10)}
              fullWidth
              id="character_limit"
              name="character_limit"
              placeholder="Characters Limit"
              type="number"
              onChange={handleChange}
              sx={{
                marginTop: 0,
              }}
            />
          </Grid>
        )}

        <Grid item xs={8} md={6} sx={{ display: "flex", alignItems: "end" }}>
          {newTestFunction.data_type !== "ReadOnlyText" && (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => handleChange(e, null)}
                  value={newTestFunction.required}
                  checked={newTestFunction.required}
                  id="required"
                  name="required"
                  color="primary"
                />
              }
              label="Required"
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleChange(e, null)}
                value={newTestFunction.line_break}
                checked={newTestFunction.line_break}
                id="line_break"
                name="line_break"
                color="primary"
              />
            }
            label="Line break"
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          columnGap: 2,
        }}
      >
        {testFunction !== null && (
          <Button
            variant="contained"
            onClick={handleCopyTestFieldSubmit}
            sx={{ width: 175 }}
          >
            Copy Test Field
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleTestFunctionSubmit}
          sx={{ width: 175 }}
        >
          Save Test Field
        </Button>
      </Box>
    </Box>
  );
};

export default TestFunctionForm;
