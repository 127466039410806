import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { PDFViewer } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TestFunctions from "../test_function/TestFunctions.jsx";
import TestFunctionForm from "../test_function/TestFunctionForm.jsx";
import { getTest, setSelectedTab } from "../../redux/features/test.js";
import TestLayout from "../common/TestLayout.jsx";
import TestPreview from "./TestPreview.jsx";
import StimulsoftReportDesigner from "../common/StimulsoftReportDesigner.jsx"; // Import your StimulsoftReportDesigner component
import { pdf } from "@react-pdf/renderer";
import EditTest from "./EditTest.jsx";
import TestTemplatePDF from "./TestTemplatePDF.jsx";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";

const TestDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const test = useSelector((state) => state.test.currentTest);
  const selectedTab = useSelector((state) => state.test.selectedTab);
  const isLoading = useSelector((state) => state.test.isLoading);
  const reportFile = "/report/Report.mrt";
  const [editingTestFunction, setEditingTestFunction] = useState(null);

  useEffect(() => {
    dispatch(getTest({ testId: id }));
  }, [dispatch, id]);

  const handleEditTestFunction = (testFunction) => {
    setEditingTestFunction(testFunction);
    dispatch(setSelectedTab(3));
  };
  const tabButtons = [
    { value: 1, label: "Edit Test" },
    { value: 2, label: "Test Fields" },
    { value: 4, label: "Preview", route: `/tests/test-preview/${id}` },
    { value: 5, label: "PDF Preview" },
  ];

  return (
    <TestLayout
      tabButtons={tabButtons}
      selectedTab={selectedTab}
      showAddTestFieldsButton={true} // Show the button when in TestDetails
      setEditingTestFunction={setEditingTestFunction}
    >
      {test ? (
        <>
          {selectedTab === 1 ? (
            <EditTest />
          ) : selectedTab === 2 ? (
            <TestFunctions
              test={test}
              onEditTestFunction={handleEditTestFunction}
            />
          ) : selectedTab === 3 ? (
            <TestFunctionForm
              test={test}
              testFunction={editingTestFunction}
              handleTabChange={(value) => dispatch(setSelectedTab(value))}
            />
          ) : selectedTab === 4 ? (
            <TestPreview test={test} />
          ) : selectedTab === 5 ? (
            <StimulsoftReportDesigner reportFile={reportFile} testId={id} /> // Render StimulsoftReportDesigner
          ) : null}
        </>
      ) : null}
    </TestLayout>
  );
};

export default TestDetails;
