import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const EmptyList = ({ messageHeader, message, buttonText, route }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        paddingTop: 25,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
        <InfoOutlinedIcon fontSize="small" />
        <Typography variant="h6" component="div">
        &nbsp;{messageHeader}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography color="text.secondary" fontSize={15}>
          {message}&nbsp;
        </Typography>
        <Button
          variant="text"
          onClick={() => navigate(route)}
          sx={{ fontSize: "15px" }}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default EmptyList;
