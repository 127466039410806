import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog.jsx";
import { deleteFormTemplate } from "../../redux/features/form_template.js";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const TableList = ({
  formTemplatesList,
  page,
  rowsPerPage,
  totalFormTemplatesCount,
  handleChangePage,
  handleChangeRowsPerPage,
  handleFormTemplateClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [selectedFormTemplate, setSelectedFormTemplate] = useState(null);

  const renderTags = (tags) => {
    return tags.map((tag) => tag.title).join(", ");
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedTest(null);
  };

  const handleDeleteFormTemplateClick = (event, formTemplate) => {
    event.preventDefault();
    setSelectedFormTemplate(formTemplate);
    setOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    if (selectedFormTemplate) {
      await dispatch(
        deleteFormTemplate({ formTemplateId: selectedFormTemplate.id })
      );
      navigate("/form-templates");
      setOpen(false);
    }
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 460 }}>
        <Table stickyHeader aria-label="form templates table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Title
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Version
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Status
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Tags
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formTemplatesList.map((formTemplate) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={formTemplate.id}
                  onClick={(event) =>
                    handleFormTemplateClick(event, formTemplate)
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <TableCell align="left" sx={{ width: "20%" }}>
                    {formTemplate.title}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "20%" }}>
                    {formTemplate.version}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "20%" }}>
                    {formTemplate.status}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "20%" }}>
                    {renderTags(formTemplate.labels)}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "20%" }}>
                    <Tooltip title="Delete">
                      <IconButton
                        sx={{ p: "10px" }}
                        onClick={(event) =>{
                          event.stopPropagation();
                          handleDeleteFormTemplateClick(event, formTemplate)
                        }}
                        id={formTemplate.id}
                      >
                        <DeleteOutlinedIcon sx={{ color: "red" }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteConfirmationDialog
        open={open}
        handleCancel={handleCancel}
        titleText="Confirm Delete"
        contentText="Are you sure you want to delete"
        title={selectedTest?.title}
        suffixText="from tests list?"
        handleDelete={handleDeleteConfirmation}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalFormTemplatesCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
          }}
        />
      </Box>
    </Box>
  );
};

export default TableList;
