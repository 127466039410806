import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  InputBase,
  IconButton,
  Button,
  Link,
  useTheme,
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllReportTemplates } from "../../redux/features/report_template.js";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import EmptyList from "../common/EmptyList.jsx";
import StimulsoftReportDesigner from './StimulsoftReportDesigner';
import { Stimulsoft } from "stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor";
import CombinedReportViewer from './StimulsoftReportViewer'

const ReportTemplates = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const reportFile = "../../../reports/report.mrt";
  const reportTemplatesList = useSelector(
    (state) => state.reportTemplate.reportTemplatesList
  );
  const reports = reportTemplatesList[2]?.visit_template?.tests
  const combinedReport = {
    ReportVersion: "2024.3.3",
    ReportGuid: "combinedReportGuid",
    ReportName: "Combined Report",
    ReportAlias: "Combined Report",
    ReportFile: "/report/CombinedReport.mrt",
    ReportCreated: new Date().toISOString(),
    EngineVersion: "EngineV2",
    CalculationMode: "Interpretation",
    ReportUnit: "Centimeters",
    Dictionary: {
        DataSources: {},
        Databases: {}
    },
    Pages: []
};

reports?.forEach(report => {
  const reportData = JSON.parse(report.report_data);
  // Merge DataSources
  Object.entries(reportData.Dictionary.DataSources).forEach(([key, dataSource]) => {
      combinedReport.Dictionary.DataSources[`Source_${report.id}_${key}`] = dataSource;
  });

  // Merge Pages
  Object.keys(reportData.Pages).forEach(pageKey => {
    const page = reportData.Pages[pageKey];

    // Assuming you have a Stimulsoft report object initialized
    const stiPage = new Stimulsoft.Report.Components.StiPage();
    stiPage.name = page.Name;
    stiPage.guid = page.Guid;

    // Set page properties like Border, Brush, PageWidth, PageHeight, etc.
    stiPage.border = page.Border;
    stiPage.brush = page.Brush;
    stiPage.pageWidth = page.PageWidth;
    stiPage.pageHeight = page.PageHeight;

    // Handle the Components within each page
    Object.keys(page.Components).forEach(componentKey => {
        const component = page.Components[componentKey];

        if (component.Ident === "StiDataBand") {
            const dataBand = new Stimulsoft.Report.Components.StiDataBand();
            dataBand.name = component.Name;
            dataBand.clientRectangle = component.ClientRectangle;
            dataBand.border = component.Border;
            dataBand.brush = component.Brush;
            dataBand.dataSourceName = component.DataSourceName;

            // Handle sub-components within the DataBand
            Object.keys(component.Components).forEach(subComponentKey => {
                const subComponent = component.Components[subComponentKey];

                if (subComponent.Ident === "StiText") {
                    const textComponent = new Stimulsoft.Report.Components.StiText();
                    textComponent.name = subComponent.Name;
                    textComponent.clientRectangle = subComponent.ClientRectangle;
                    textComponent.text = subComponent.Text.Value;
                    textComponent.border = subComponent.Border;
                    textComponent.brush = subComponent.Brush;
                    textComponent.textBrush = subComponent.TextBrush;

                    dataBand.components.add(textComponent);
                }
            });

            stiPage.components.add(dataBand);
        }
    });

    report.pages?.add(stiPage);
});

});

const combinedReportJSON = JSON.stringify(combinedReport);
const report = new Stimulsoft.Report.StiReport();
report.loadFile('/report/Report.mrt'); // Load your .mrt file

// Create a new data source
const dataSet = new Stimulsoft.System.Data.DataSet("CombinedData");
dataSet.readJson(combinedReportJSON);

// Add the combined data to the report
report.regData("CombinedData", "CombinedData", dataSet);

// Render the report
const viewer = new Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);
viewer.report = report;
viewer.renderHtml("viewerContent");

  const isLoading = useSelector((state) => state.reportTemplate.isLoading);

  const [searchQuery, setSearchQuery] = useState("");

  const handleReportTemplateClick = (event, reportTemplate) => {
    event.preventDefault();

    navigate(`/report-templates/report-template-details/${reportTemplate.id}`);
  };

  useEffect(() => {
    dispatch(getAllReportTemplates({ searchQuery: searchQuery }));
  }, [dispatch, searchQuery]);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Report Templates
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
          }}
        >
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={searchQuery}
              onChange={handleSearch}
            />
          </Paper>

          <Link>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/report-templates/new-report-template");
              }}
            >
              Create Report Template
            </Button>
          </Link>
        </Box>
      </Box>

      {isLoading && !searchQuery && reportTemplatesList.length === 0 ? (
        <GradientCircularProgress open={isLoading} />
      ) : reportTemplatesList && reportTemplatesList.length === 0 ? (
        <EmptyList
          messageHeader={
            searchQuery
              ? "No report templates found."
              : "No report templates available."
          }
          message={
            searchQuery
              ? "Try adjusting your search or"
              : "It looks like there are no report templates added yet. To get started,"
          }
          buttonText="create a report template"
          route="/report-templates/new-report-template"
        />
      ) : (
        <Grid item xs={8} md={6} sx={{ maxHeight: "75vh", overflow: "auto" }}>
          <Box
            sx={{
              maxHeight: "75vh",
              overflow: "auto",
              paddingTop: 2,
            }}
          >
            <List sx={{ width: "100%" }}>
              {reportTemplatesList &&
                reportTemplatesList.map((reportTemplate, index) => {
                  return (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{
                        backgroundColor: "#F6F6F6",
                        marginBottom: 1,
                        borderRadius: "13px",
                      }}
                    >
                      <ListItemButton
                        role={undefined}
                        dense
                        sx={{
                          height: "55px",
                          cursor: "pointer",
                        }}
                        onClick={(event) =>
                          handleReportTemplateClick(event, reportTemplate)
                        }
                      >
                        <ListItemIcon>
                          <ArticleOutlinedIcon
                            sx={{ color: theme.palette.primary.main }}
                          />
                        </ListItemIcon>
                        <Box
                          sx={{
                            width: "100%",
                            paddingLeft: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              lineHeight: "19.36px",
                            }}
                          >
                            {reportTemplate.title} - Report
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              lineHeight: "14.52px",
                              fontWeight: 300,
                            }}
                          ></Typography>
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          </Box>
        </Grid>
      )}
      <Box>
        {/* <Typography
          color="primary"
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}>Show Stimulsoft Designer Here!</Typography>
          <StimulsoftReportDesigner reportFile={reportFile} /> */}
          <Typography
          color="primary"
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}>Show Stimulsoft Viewer Here!</Typography>
          <div id="viewerContent"></div>
        </Box>
    </Box>
  );
};

export default ReportTemplates;
