import React, { useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
  Paper,
  InputBase,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import EmptyListMessage from "../common/EmptyListMessage";

const AssociatedTestsPanel = ({
  onDragEnd,
  associatedTests,
  handleToggle,
  test_ids,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredTests = associatedTests.filter((test) =>
    test.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  return (
    <Grid item xs={8} md={6}>
      <Typography>Form Tests</Typography>
      <Paper
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton sx={{ p: "10px" }} disabled>
          <SearchIcon />
        </IconButton>
        <InputBase
          placeholder="Search associated tests..."
          inputProps={{ "aria-label": "search associated tests" }}
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          endAdornment={
            searchQuery && (
              <IconButton onClick={handleClearSearch}>
                <CloseOutlinedIcon />
              </IconButton>
            )
          }
        />
      </Paper>
      {filteredTests.length === 0 ? (
        <EmptyListMessage
          messageHeader={
            searchQuery ? "No associated tests found." : "No tests associated."
          }
          message={
            searchQuery
              ? "No associated tests match your search query. Please try again with a different search term."
              : "It looks like there are no tests associated yet. To link tests to this form template, select available tests and press save button."
          }
        />
      ) : (
        <Box
          sx={{
            maxHeight: "60vh",
            overflow: "auto",
            paddingTop: 2,
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppableTests">
              {(provided) => (
                <List {...provided.droppableProps} ref={provided.innerRef}>
                  {filteredTests.map((test, index) => {
                    const labelId = `checkbox-list-label-${test.id}`;
                    return (
                      <Draggable
                        key={test.id}
                        draggableId={test.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            disablePadding
                            sx={{
                              backgroundColor: "#F6F6F6",
                              marginBottom: 1,
                              borderRadius: "13px",
                            }}
                          >
                            <ListItemButton
                              role={undefined}
                              onClick={handleToggle(test.id)}
                              dense
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={test_ids.indexOf(test.id) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText id={labelId} primary={test.title} />
                            </ListItemButton>
                          </ListItem>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      )}
    </Grid>
  );
};

export default AssociatedTestsPanel;
