import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../constants/urls";
import { getToken } from "../../auth/AuthToken";
import { toast } from "react-toastify";

const initialState = {
  tagsList: [],
  isLoading: false,
};

export const createTag = createAsyncThunk(
  "tags/createTag",
  async ({ tag }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/labels`;
    try {
      const response = await axios.post(
        url,
        { label: tag },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editTag = createAsyncThunk(
  "tags/editTag",
  async ({ tag, tag_id }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/labels/${tag_id}`;
    try {
      const response = await axios.put(
        url,
        { label: tag },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllTags = createAsyncThunk(
  "tags/getAllTags",
  async (searchValue, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/labels?q=${searchValue}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTag = createAsyncThunk(
  "tags/deleteTag",
  async ({ tagId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/labels/${tagId}`;
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });

      toast.success("Tag deleted successfully");
      return { tagId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTag.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(createTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tagsList.push(action.payload);
      })
      .addCase(createTag.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllTags.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTags.fulfilled, (state, action) => {
        state.tagsList = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllTags.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteTag.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tagsList = state.tagsList.filter(
          (tag) => tag.id !== action.payload.tagId
        );
      })
      .addCase(deleteTag.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default tagSlice.reducer;
