import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  InputBase,
  IconButton,
  Button,
  Link,
  useTheme,
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";
import { getAllTags, deleteTag } from "../../redux/features/tag.js";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog.jsx";
import EmptyList from "../common/EmptyList.jsx";

const Tags = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const tagsList = useSelector((state) => state.tag.tagsList);
  const isLoading = useSelector((state) => state.tag.isLoading);

  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const handleEditTagClick = (event, tag) => {
    event.preventDefault();
    navigate(`/tags/edit-tag/${tag.id}`, { state: { tag } });
  };

  useEffect(() => {
    dispatch(getAllTags(searchValue));
  }, [dispatch, searchValue]);

  const handleDeleteTagClick = (event, tag) => {
    event.preventDefault();
    setSelectedTag(tag);
    setOpen(true);
  };

  const handleDeleteConfirmation = () => {
    if (selectedTag) {
      dispatch(deleteTag({ tagId: selectedTag.id }));
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedTag(null);
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Tags
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
          }}
        >
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              onChange={handleSearch}
              value={searchValue}
            />
          </Paper>
          <Link>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/tags/new-tag");
              }}
            >
              Create Tag
            </Button>
          </Link>
        </Box>
      </Box>

      {isLoading && !searchValue && tagsList.length === 0 ? (
        <GradientCircularProgress open={isLoading} />
      ) : tagsList && tagsList.length === 0 ? (
        <EmptyList
          messageHeader={searchValue ? "No tags found." : "No tags available."}
          message={
            searchValue
              ? "Try adjusting your search or"
              : "It looks like there are no tags added yet. To get started,"
          }
          buttonText="create a tag"
          route="/tags/new-tag"
        />
      ) : (
        <Grid item xs={8} md={6} sx={{ maxHeight: "75vh", overflow: "auto" }}>
          <Box
            sx={{
              maxHeight: "75vh",
              overflow: "auto",
              paddingTop: 2,
            }}
          >
            <List sx={{ width: "100%" }}>
              {tagsList &&
                tagsList.map((tag) => {
                  return (
                    <ListItem
                      key={tag.id}
                      disablePadding
                      sx={{
                        backgroundColor: "#F6F6F6",
                        marginBottom: 1,
                        borderRadius: "13px",
                      }}
                    >
                      <ListItemButton
                        role={undefined}
                        dense
                        sx={{
                          height: "55px",
                          cursor: "pointer",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            paddingLeft: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              lineHeight: "19.36px",
                            }}
                          >
                            {tag?.title}
                          </Typography>
                        </Box>
                      </ListItemButton>
                      <Box
                        sx={{ paddingRight: 2, display: "flex", columnGap: 1 }}
                      >
                        <IconButton
                          sx={{ p: "10px" }}
                          onClick={(event) => handleDeleteTagClick(event, tag)}
                          id={tag.id}
                        >
                          <DeleteOutlinedIcon sx={{ color: "red" }} />
                        </IconButton>
                        <IconButton
                          sx={{ p: "10px" }}
                          onClick={(event) => handleEditTagClick(event, tag)}
                          id={tag.id}
                        >
                          <ModeEditOutlineRoundedIcon
                            sx={{ color: theme.palette.primary.main }}
                          />
                        </IconButton>
                      </Box>
                    </ListItem>
                  );
                })}
            </List>
          </Box>
        </Grid>
      )}

      <DeleteConfirmationDialog
        open={open}
        handleCancel={handleCancel}
        titleText="Confirm Delete"
        contentText="Are you sure you want to delete"
        title={selectedTag?.title}
        suffixText="from tags list?"
        handleDelete={handleDeleteConfirmation}
      />
    </Box>
  );
};

export default Tags;
