import React, { useEffect, useRef, useState } from "react";
import { Stimulsoft } from "stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor";
import "stimulsoft-reports-js/Scripts/stimulsoft.designer";
import "stimulsoft-reports-js/Scripts/stimulsoft.reports";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getExam } from "../../redux/features/exam.js";
import { useParams } from "react-router-dom";

const ExamReportViewer = () => {
  const dispatch = useDispatch();
  const designerRef = useRef(null); // Initialize designerRef
  const { examId } = useParams();
  const currentExam = useSelector((state) => state.exam.currentExam);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const renderReport = () => {
    try {
      console.log("Initializing Stimulsoft viewer");
      const viewer = new Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);

      Stimulsoft.StiOptions.WebServer.url = "http://localhost:9615";

      console.log("Creating new report instance");
      const report = new Stimulsoft.Report.StiReport();

      // Ensure report_data is valid JSON before loading it
      if (currentExam?.report_data) {
        const reportData = JSON.parse(currentExam.report_data);
        console.log("Updated Merged Report", reportData);

        // Load the concatenated report data
        report.load(reportData);

        console.log("Assigning report to viewer");
        viewer.report = report;

        // Render viewer to the designerRef DOM element
        if (designerRef.current) {
          console.log("Rendering viewer in designerRef");
          viewer.renderHtml(designerRef.current);
        } else {
          console.error("DesignerRef is not set");
          toast.error("Failed to render report viewer");
        }
      } else {
        console.error("Report data is missing or invalid");
        toast.error("Report data is missing or invalid");
      }
    } catch (error) {
      console.error("Error initializing report viewer:", error);
      toast.error("Failed to initialize report viewer");
    }
  };

  const fetchExam = async () => {
    try {
      setLoading(true);
      await dispatch(getExam({ exam_id: examId })).unwrap(); // Wait for the exam to load
      setLoading(false); // After loading is complete
    } catch (err) {
      console.error("Error fetching exam:", err);
      setError("Failed to load exam"); // Set error if the fetch fails
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!currentExam) {
      fetchExam();
    }
  }, [dispatch, examId, currentExam]);

  // Use useEffect to ensure rendering happens only after loading is complete
  useEffect(() => {
    if (currentExam && !loading && !error) {
      renderReport(); // Render the report when data is ready
    }
  }, [currentExam, loading, error]);

  if (loading) {
    return <div>Loading exam data...</div>; // Show loading indicator
  }

  if (error) {
    return <div>{error}</div>; // Show error message if there's an issue
  }

  return (
    <div
      ref={designerRef} // Attach the ref to this div
      style={{ height: "100%", width: "100%", position: "relative" }}
    >
      {/* Report content will be rendered here */}
    </div>
  );
};

export default ExamReportViewer;
