import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import WorkerForm from "./WorkerForm.jsx";
import { Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { getUser } from "../../redux/features/user.js";
import { useDispatch, useSelector } from "react-redux";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";

const EditUser = () => {
  const theme = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const isLoading = useSelector((state) => state.user.isLoading); 

  useEffect(() => {
    dispatch(getUser({ user_id: id }));
  }, [dispatch, id]);

  return (
      <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Edit Worker
        </Typography>
      </Box>
      {isLoading ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <WorkerForm user={user} />
      )}
      </Box>
  );
};

export default EditUser;
