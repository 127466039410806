import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, useTheme, FormControlLabel } from "@mui/material";
import { palette } from "../../theme/palette.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  createFormTemplate,
  editFormTemplate,
} from "../../redux/features/form_template.js";
import Checkbox from "@mui/material/Checkbox";
import { getAllCompanies } from "../../redux/features/company.js";
import { getAllTags } from "../../redux/features/tag.js";
import ListItemText from "@mui/material/ListItemText";
import { testStatuses } from "../../constants/data.js";
import ReactQuill from "react-quill";

const extractAssociatedIds = (arrayList) => {
  return arrayList?.map((item) => item.id);
};

const TemplateForm = ({ formTemplate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const companies = useSelector((state) => state.company.companies);
  const tagsList = useSelector((state) => state.tag.tagsList);

  const [newFormTemplate, setNewFormTemplate] = useState({
    title: formTemplate?.title || "",
    description: formTemplate?.description || "",
    status: formTemplate?.status || "review",
    company_ids: extractAssociatedIds(formTemplate?.companies) || [],
    label_ids: extractAssociatedIds(formTemplate?.labels) || [],
    archive: formTemplate?.archive,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "archive") {
      setNewFormTemplate((prevState) => ({
        ...prevState,
        [name]: event.target.checked,
      }));

      return;
    }
    if (name === "company_ids") {
      setNewFormTemplate({
        ...newFormTemplate,
        company_ids: [value],
      });
    } else {
      setNewFormTemplate({
        ...newFormTemplate,
        [name]: value,
      });
    }
  };

  const handleDescriptionChange = (value) => {
    setNewFormTemplate((prevState) => ({
      ...prevState,
      description: value,
    }));
  };

  const handleTestSubmit = async (event) => {
    event.preventDefault();

    if (newFormTemplate.title === "") {
      toast.error("Please enter title");
    } else if (newFormTemplate.company_ids.length === 0) {
      toast.error("Please select a company");
    } else {
      try {
        if (formTemplate === null) {
          await dispatch(
            createFormTemplate({ formTemplate: newFormTemplate })
          ).unwrap();
          toast.success("Form Template added successfully");
        } else {
          await dispatch(
            editFormTemplate({
              formTemplate: newFormTemplate,
              formTemplateId: formTemplate.id,
            })
          ).unwrap();
          toast.success("Form Template updated successfully");
        }
        navigate("/form-templates");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  useEffect(() => {
    dispatch(getAllCompanies(""));
    dispatch(getAllTags(""));
  }, [dispatch]);

  return (
    <Box>
      <Grid
        container
        columnSpacing={10}
        rowSpacing={4}
        paddingRight={20}
        paddingTop={5}
      >
        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Title
          </Typography>
          <TextField
            value={newFormTemplate.title}
            fullWidth
            id="title"
            name="title"
            placeholder="Test Title"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>
        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Tags
          </Typography>

          <FormControl fullWidth>
            <Select
              displayEmpty
              multiple
              id="label_ids"
              name="label_ids"
              value={newFormTemplate.label_ids}
              onChange={handleChange}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <span style={{ color: theme.palette.primary.gray }}>
                      Select Tags
                    </span>
                  );
                }

                const selectedTags = tagsList
                  .filter((tag) => selected.includes(tag.id))
                  .map((tag) => tag.title)
                  .join(", ");
                return selectedTags;
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {tagsList &&
                tagsList.map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    <Checkbox
                      checked={newFormTemplate.label_ids.includes(tag.id)}
                    />
                    <ListItemText primary={tag.title} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8} md={12}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Description
          </Typography>

          <ReactQuill
            value={newFormTemplate.description}
            onChange={handleDescriptionChange}
            placeholder="Description"
            style={{ backgroundColor: "#fff", borderRadius: "4px" }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Account
          </Typography>

          <FormControl fullWidth>
            <Select
              displayEmpty
              id="company_ids"
              name="company_ids"
              value={newFormTemplate.company_ids[0] || ""}
              onChange={handleChange}
              renderValue={(selected) => {
                if (selected === "") {
                  return (
                    <span style={{ color: theme.palette.primary.gray }}>
                      Select Account
                    </span>
                  );
                }
                const selectedCompany = companies?.find(
                  (company) => company.id === selected
                );
                return selectedCompany ? selectedCompany.first_name : "";
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {companies &&
                companies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.first_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Status
          </Typography>
          <FormControl fullWidth>
            <Select
              displayEmpty
              id="status"
              name="status"
              variant="outlined"
              value={newFormTemplate.status}
              onChange={handleChange}
              inputProps={{ "aria-label": "Without label" }}
            >
              {testStatuses.map((status, index) => (
                <MenuItem key={index} value={status.value}>
                  {status.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={8}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                value={newFormTemplate.archive}
                checked={newFormTemplate.archive}
                id="archive"
                name="archive"
                color="primary"
              />
            }
            label="Archived"
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginRight: 10,
          marginTop: 10,
          minHeight: "100%",
          columnGap: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={handleTestSubmit}
          sx={{ width: 185 }}
        >
          Save Form Template
        </Button>
      </Box>
    </Box>
  );
};

export default TemplateForm;
