// FormTemplatePDF.js
import React from "react";
import { Page, Text, View, Document, StyleSheet, Image} from "@react-pdf/renderer";
import Checkbox from '@mui/material/Checkbox';
import { Grid, Box, Typography , ImageList, ImageListItem} from "@mui/material";
import Avatar from "../../avatar-svgrepo-com.svg";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },

  descriptionText: {
    display: "flex",
    flexDirection: "column",
    fontSize: 12,
    fontWeight: "bold",
    marginVertical: 5
  },
  testsContent: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    rowGap: 5,
  },

  testHeader: {
    backgroundColor: "black",
    display: "flex",
    justifyContent: "left",
    padding: 5
  },

  testHeaderText: {
    color: "white",
    fontSize: 14,
  },

  testFunctionsContent: {
    marginTop: 10,
  },

  content: {
    marginTop: 50, // adjust based on header height
    marginBottom: 50, // adjust based on footer height
  },

  labelText: {
    fontSize: 12,
    marginBottom: 3,
    fontWeight: "bold"
  },

  checkboxContainer: {
    padding: 0
  },

  pickListOption: {
    paddingRight: 50,
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10
  },

  textArea: {
    backgroundColor: "#f0f0f0",
    display: "flex",
    justifyContent: "left",
    padding: 2,
    fontSize: 12
  },

  textAnswer:{
    // display: "flex",
    justifyContent: "left",
    padding: 2,
    fontSize: 12
  },
  image: {
    width: 200,
    height: 200
  }
});

const TestTemplatePDF = ({ test }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <View style={styles.testsContent}>
                <View style={styles.testHeader}>
                  <Text style={styles.testHeaderText}>{test.title}</Text>
                </View>
                <View style={styles.testFunctionsContent} >
                  {test.test_functions?.map((testFunction, idx) => (
                    <View key={idx}>
                      {testFunction.data_type === "ReadOnlyText"? (
                      <Typography
                        component="div"
                        dangerouslySetInnerHTML={{ __html: testFunction.detail }}
                      />
                      ) : (
                      <Text style={styles.descriptionText}>
                        {testFunction.detail}
                      </Text>)
                      }
                    {testFunction.data_type === "Picklist" ? (
                      testFunction.picklist_options.map((option, idx) => (
                        <View key={idx} style={styles.pickListOption}>
                          <Checkbox  size="small" disabled/>
                          <Text>{option.option_text}</Text>
                        </View>
                        ))
                      ) : (testFunction.data_type === "Image" || testFunction.data_type === "EditableImage")? (
                        <ImageList>
                          <ImageListItem>
                            <img
                              src={Avatar}
                              style={styles.image}
                              alt="Avatar"
                            />
                          </ImageListItem>
                        </ImageList>
                      ): testFunction.data_type === "Integer" || testFunction.data_type=== "Decimal" || testFunction.data_type=== "String" ? (
                        <Text style={styles.textAnswer}>Answer</Text>
                      ): (
                        <Text style={styles.labelText}>Input Field: __________________</Text>
                        )}
                    </View>
                  ))}
                </View>
                <View>
                <View style={styles.textArea}>
                  <Text>Suggestions</Text>
                </View>
                </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default TestTemplatePDF;
