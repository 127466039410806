import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import defaultLogo from "../../images/report_default_logo.png";
import HtmlToReact from "html-to-react";

// Initialize the HTML to React parser
const htmlToReactParser = new HtmlToReact.Parser();

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingHorizontal: 20,
  },
  logoContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  textContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    // width: 60,
    height: 60,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 10,
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    left: 20,
    right: 20,
    height: 50,
  },
  footerText: {
    fontSize: 11,
  },
  footerContact: {
    display: "flex",
    flexDirection: "row",
    columnGap: 10,
  },
  descriptionSection: {},
  descriptionText: {
    fontSize: 11,
  },
  testsContent: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    rowGap: 5,
  },
  testHeader: {
    height: 30,
    backgroundColor: "black",
    display: "flex",
    justifyContent: "center",
    paddingHorizontal: 5,
  },
  testHeaderText: {
    color: "white",
    fontSize: 14,
  },
  testFunctionsContent: {
    marginTop: 5,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  inputField: {
    border: "1px solid #000",
    padding: 5,
    marginTop: 5,
    marginBottom: 10,
    fontSize: 12,
  },
  labelText: {
    fontSize: 12,
    marginBottom: 3,
  },
  list: {
    fontSize: 11,
    marginLeft: 10,
  },
  listItem: {
    fontSize: 11,
    marginBottom: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  listItemBullet: {
    marginRight: 5,
    marginBottom: 2,
    fontSize: 11,
  },
  listItemNumber: {
    marginRight: 5,
    fontSize: 11,
  },
  strong: {
    fontWeight: "bold",
  },
  underline: {
    textDecoration: "underline",
  },
});

// Convert HTML to React elements
const renderHTML = (html) => {
  const reactElements = htmlToReactParser.parse(html);

  const traverseReactTree = (element, index, listIndex = 1) => {
    if (typeof element === "string") {
      return <Text key={index}>{element}</Text>;
    }

    if (React.isValidElement(element)) {
      const { type, props } = element;
      const style = getTextStyles(type);
      const children = React.Children.map(props.children, (child, idx) =>
        traverseReactTree(child, idx + 1, listIndex)
      );

      if (type === "ul") {
        return (
          <View key={index} style={styles.list}>
            {React.Children.map(props.children, (child, idx) => (
              <View key={idx} style={styles.listItem}>
                <Text style={styles.listItemBullet}>•</Text>
                {traverseReactTree(child, idx + 1, listIndex)}
              </View>
            ))}
          </View>
        );
      }

      if (type === "ol") {
        return (
          <View key={index} style={styles.list}>
            {React.Children.map(props.children, (child, idx) => (
              <View key={idx} style={styles.listItem}>
                <Text style={styles.listItemNumber}>{`${idx + 1}. `}</Text>
                {traverseReactTree(child, idx + 1, listIndex)}
              </View>
            ))}
          </View>
        );
      }

      if (type === "li") {
        return (
          <View key={index} style={styles.listItem}>
            {children}
          </View>
        );
      }

      // Handle <strong> and <u> tags
      if (type === "strong") {
        return (
          <Text key={index} style={{ ...style, fontWeight: "bold" }}>
            {children}
          </Text>
        );
      }

      if (type === "u") {
        return (
          <Text key={index} style={{ ...style, textDecoration: "underline" }}>
            {children}
          </Text>
        );
      }

      return (
        <View key={index}>
          <Text style={style}>{children}</Text>
        </View>
      );
    }

    return null;
  };

  return (
    <View>
      {React.Children.map(reactElements, (element, index) =>
        traverseReactTree(element, index)
      )}
    </View>
  );
};

const getTextStyles = (type) => {
  switch (type) {
    case "h1":
      return { fontSize: 24, fontWeight: "bold" };
    case "h2":
      return { fontSize: 20, fontWeight: "bold" };
    case "p":
      return { fontSize: 12 };
    default:
      return { fontSize: 12 }; // Default style for other elements
  }
};

// Create Document Component
const ReportTemplatePDF = ({ reportTemplate }) => {
  let logoUrl = reportTemplate.logo
    ? `https://frontend.workerfit.com${reportTemplate.logo}`
    : defaultLogo;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            <Image src={logoUrl} style={styles.logo} />
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.title}>{reportTemplate.title}</Text>
            <Text style={styles.subtitle}>{reportTemplate.subtitle}</Text>
          </View>
        </View>

        <View style={styles.testsContent}>
          {reportTemplate?.visit_template?.tests?.map((test, index) => (
            <View key={index}>
              <View style={styles.testHeader}>
                <Text style={styles.testHeaderText}>{test.title}</Text>
              </View>

              <View style={styles.testFunctionsContent}>
                {test.test_functions?.map((testFunction, idx) => (
                  <View key={idx}>
                    <Text style={styles.descriptionText}>
                      {testFunction.display_name}
                    </Text>
                    {testFunction.data_type === "ReadOnlyText" ? (
                      renderHTML(testFunction.detail)
                    ) : (
                      <Text style={styles.labelText}>
                        Input Field: __________________
                      </Text>
                    )}
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>

        <View fixed style={styles.footer}>
          <View>
            <Text style={styles.footerText}>19-XXXXXX</Text>
          </View>
          <View style={styles.footerContact}>
            <Text style={styles.footerText}>Ph (513) 772-1026</Text>
            <Text style={styles.footerText}>Fax (513) 672-2552</Text>
          </View>

          <View>
            <Text
              style={styles.footerText}
              render={({ pageNumber, totalPages }) =>
                `Sample, Frank - Pg ${pageNumber}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ReportTemplatePDF;
