import React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import { handleDrawerClose } from "../redux/features/utils";
import { logout } from "../redux/features/user.js";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import { palette } from "../theme/palette.js";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import GroupsIcon from '@mui/icons-material/Groups';
import EngineeringIcon from '@mui/icons-material/Engineering';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ListButton = ({
  drawerOpen,
  selected,
  primaryText,
  handleSelectedItem,
  route,
  IconComponent,
}) => (
  <ListItem
    disablePadding
    sx={{ display: "block" }}
    onClick={() => handleSelectedItem(route)}
  >
    <ListItemButton
      sx={{
        minHeight: 48,
        justifyContent: drawerOpen ? "initial" : "center",
        px: 2.5,
        borderRadius: "13px",
        marginX: "15px",
        "&.Mui-selected": {
          backgroundColor: "white",
          color: "#3B4BB0",
        },
        "&.Mui-selected:hover": {
          backgroundColor: "white",
          color: "#3B4BB0",
        },
      }}
      selected={selected}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: drawerOpen ? 3 : "auto",
          justifyContent: "center",
          color: selected ? palette.primary.main : "#DCDCDC",
        }}
      >
        <IconComponent />
      </ListItemIcon>
      <ListItemText
        primary={primaryText}
        sx={{ opacity: drawerOpen ? 1 : 0 }}
      />
    </ListItemButton>
  </ListItem>
);

const NavigationDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);

  const { drawerOpen } = useSelector((state) => state.utils);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(handleDrawerClose());
  };

  const handleLogOut = () => {
    dispatch(logout());
  };

  const handleSelectedItem = (route) => {
    navigate(route);
  };

  return (
    <Drawer variant="permanent" open={drawerOpen}>
      <DrawerHeader
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>ExamFit</Typography>

        <IconButton onClick={handleClose} color="inherit">
          <MenuOpenOutlinedIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListButton
          drawerOpen={drawerOpen}
          selected={location.pathname === "/"}
          primaryText="Dashboard"
          handleSelectedItem={handleSelectedItem}
          route="/"
          IconComponent={DashboardIcon}
        />
        {user?.role === "uber_admin" && (
          <ListButton
            drawerOpen={drawerOpen}
            selected={location.pathname.includes("/organizations")}
            primaryText="Organization"
            handleSelectedItem={handleSelectedItem}
            route="/organizations"
            IconComponent={CorporateFareIcon}
          />
        )}
        <ListButton
          drawerOpen={drawerOpen}
          selected={location.pathname.includes("/companies")}
          primaryText="Accounts"
          handleSelectedItem={handleSelectedItem}
          route="/companies"
          IconComponent={BusinessIcon}
        />

        <ListButton
          drawerOpen={drawerOpen}
          selected={location.pathname.includes("/users")}
          primaryText="Users"
          handleSelectedItem={handleSelectedItem}
          route="/users"
          IconComponent={PeopleIcon}
        />

        <ListButton
          drawerOpen={drawerOpen}
          selected={location.pathname.includes("/tags")}
          primaryText="Tags"
          handleSelectedItem={handleSelectedItem}
          route="/tags"
          IconComponent={TagOutlinedIcon}
        />
        
        <ListButton
          drawerOpen={drawerOpen}
          selected={location.pathname.includes("/groups")}
          primaryText="Groups"
          handleSelectedItem={handleSelectedItem}
          route="/groups"
          IconComponent={GroupsIcon}
        />
        
        <ListButton
          drawerOpen={drawerOpen}
          selected={location.pathname.includes("/workers")}
          primaryText="Workers"
          handleSelectedItem={handleSelectedItem}
          route="/workers"
          IconComponent={EngineeringIcon}
        />

        <ListButton
          drawerOpen={drawerOpen}
          selected={location.pathname.includes("/tests")}
          primaryText="Tests"
          handleSelectedItem={handleSelectedItem}
          route="/tests"
          IconComponent={DescriptionOutlinedIcon}
        />

        <ListButton
          drawerOpen={drawerOpen}
          selected={location.pathname.includes("/form-templates")}
          primaryText="Forms"
          handleSelectedItem={handleSelectedItem}
          route="/form-templates"
          IconComponent={SummarizeIcon}
        />

        {/* <ListButton
          drawerOpen={drawerOpen}
          selected={location.pathname.includes("/report-templates")}
          primaryText="Report"
          handleSelectedItem={handleSelectedItem}
          route="/report-templates"
          IconComponent={ArticleOutlinedIcon}
        /> */}

        <ListItem
          key="logout"
          disablePadding
          sx={{ display: "block" }}
          onClick={handleLogOut}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: drawerOpen ? "initial" : "center",
              px: 2.5,
              marginX: "15px",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : "auto",
                justifyContent: "center",
                color: "white",
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              sx={{ opacity: drawerOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default NavigationDrawer;
