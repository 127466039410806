import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  useTheme,
  Card,
  CardContent,
  ToggleButton,
} from "@mui/material";
import {
  Text,
  View,
} from "@react-pdf/renderer";
import { palette } from "../../theme/palette.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  createReportTemplate,
  editReportTemplate,
} from "../../redux/features/report_template.js";
import { getAllFormTemplates } from "../../redux/features/form_template.js";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AssociatedTestsPanel from "../form_template/AssociatedTestsPanel";
import NewFormTemplate from "../form_template/NewFormTemplate.jsx";


const ReportTemplateForm = ({ reportTemplate }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const formTemplatesList = useSelector(
  //   (state) => state.formTemplate.formTemplatesList
  // );

  const [newReportTemplate, setNewReportTemplate] = useState({
    title: reportTemplate?.title || "",
    subtitle: reportTemplate?.subtitle || "",
    logo: reportTemplate?.logo || null,
    visit_template_id: reportTemplate?.visit_template_id || "",
    tests: reportTemplate?.visit_template?.tests || ""
  });

  const [selectedImageName, setSelectedImageName] = useState("");

  const [showHeaderDetails, setShowHeaderDetails] = useState(false);

  const handleChange = (event) => {
    setNewReportTemplate({
      ...newReportTemplate,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogoSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewReportTemplate((prevTest) => ({
        ...prevTest,
        logo: file,
      }));
      setSelectedImageName(file.name);
    }
  };

  useEffect(() => {
    dispatch(getAllFormTemplates({ searchQuery: "" }));
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newReportTemplate.title === "") {
      toast.error("Please enter report title");
    } else {
      const formData = new FormData();
      formData.append("report_template[title]", newReportTemplate.title);
      formData.append("report_template[subtitle]", newReportTemplate.subtitle);
      formData.append("report_template[logo]", newReportTemplate.logo);
      formData.append(
        "report_template[visit_template_id]",
        newReportTemplate.visit_template_id
      );

      try {
        if (reportTemplate === null) {
          await dispatch(
            createReportTemplate({ reportTemplate: formData })
          ).unwrap();
          toast.success("Report template added successfully");
        } else {
          await dispatch(
            editReportTemplate({
              reportTemplate: formData,
              reportTemplateId: reportTemplate.id,
            })
          ).unwrap();
          toast.success("Report template updated successfully");
        }
        navigate("/report-templates");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <Box sx={{ marginTop: 3 }}>
    <div>
      <Accordion style={{ marginBottom: '10px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          selected={showHeaderDetails}
          onClick={() => setShowHeaderDetails(!showHeaderDetails)}
        >
          Header Details
        </AccordionSummary>
        <AccordionDetails>
            <Grid
              container
              columnSpacing={10}
              rowSpacing={2}
              paddingRight={20}
              paddingTop={2}
            >
              <Grid item xs={8} md={6}>
                <Typography
                  color={palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700
                  }}
                >
                  Title
                </Typography>
                <TextField
                  value={newReportTemplate.title}
                  fullWidth
                  id="title"
                  name="title"
                  placeholder="Title"
                  onChange={handleChange}
                  sx={{
                    marginTop: 0,
                  }}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <Typography
                  color={palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700
                  }}
                >
                  Subtitle
                </Typography>
                <TextField
                  value={newReportTemplate.subtitle}
                  fullWidth
                  id="subtitle"
                  name="subtitle"
                  placeholder="Subtitle"
                  onChange={handleChange}
                  sx={{
                    marginTop: 0,
                  }}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <Typography
                  color={theme.palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700
                  }}
                >
                  {selectedImageName || "Logo"}
                </Typography>

                <Button variant="outlined" color="primary" component="label">
                  {selectedImageName ? "Change Logo" : "Select Logo"}
                  <input
                    hidden
                    id="logo"
                    name="logo"
                    accept="image/*"
                    type="file"
                    onChange={handleLogoSelect}
                  />
                </Button>
              </Grid>
            </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ marginBottom: '10px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Test
        </AccordionSummary>
        <AccordionDetails>
        {(newReportTemplate.tests) && (newReportTemplate.tests).map((test) => (
          <div key={test.id}>
            <FormControlLabel control={<Checkbox defaultChecked />} label={test.title} />
          </div>
        ))}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Footer Details
        </AccordionSummary>
        <AccordionDetails>
            <Grid
              container
              columnSpacing={10}
              rowSpacing={2}
              paddingRight={20}
              paddingTop={2}
            >
              <Grid item xs={8} md={6}>
                <Typography
                  color={palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700
                  }}
                >
                  Postal Code
                </Typography>
                <TextField
                  value="19-XXXXXX"
                  fullWidth
                  id="title"
                  name="title"
                  placeholder="19-XXXXXX"
                  disabled
                  sx={{
                    marginTop: 0,
                  }}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <Typography
                  color={palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700
                  }}
                >
                  Phone Number
                </Typography>
                <TextField
                  value="(513) 772-1026"
                  fullWidth
                  id="phone_number"
                  name="phone_number"
                  placeholder="(513) 772-1026"
                  disabled
                  sx={{
                    marginTop: 0,
                  }}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <Typography
                  color={palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700
                  }}
                >
                  FAX
                </Typography>
                <TextField
                  value="(513) 672-2552"
                  fullWidth
                  id="FAX"
                  name="FAX"
                  placeholder="(513) 672-2552"
                  disabled
                  sx={{
                    marginTop: 0,
                  }}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <Typography
                  color={palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700
                  }}
                >
                  Address
                </Typography>
                <TextField
                  value="Frank"
                  fullWidth
                  id="report_name"
                  name="report_name"
                  placeholder="Frank"
                  disabled
                  sx={{
                    marginTop: 0,
                  }}
                />
              </Grid>
            </Grid>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
        display: "flex",
        justifyContent: "right",
        marginTop: 3,
        minHeight: "100%",
        columnGap: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ width: 100 }}
        >
          Save
        </Button>
      </Box>

    </div>
    </Box>
  );
};

export default ReportTemplateForm;