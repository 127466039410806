import { Typography, Box, useTheme } from "@mui/material";
import EmptyListMessage from "../common/EmptyListMessage";

const UserProfile = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Profile
        </Typography>
      </Box>
      <EmptyListMessage
        messageHeader="Feature Under Development"
        message="The profile page feature is under development, and will be ready for testing soon."
      />
    </Box>
  );
};

export default UserProfile;
