import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import GroupForm from "./GroupForm.jsx";
import { Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { getGroup } from "../../redux/features/group.js";
import { useDispatch, useSelector } from "react-redux";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";

const EditGroup = () => {
  const theme = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();
  const group = useSelector((state) => state.group.currentGroup);
  const isLoading = useSelector((state) => state.group.isLoading); 

  useEffect(() => {
    dispatch(getGroup({ groupId: id }));
  }, [dispatch, id]);

  return (
      <Box>
        <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Edit Group
        </Typography>
      </Box>
      {isLoading ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <GroupForm group={group} />
      )}
      </Box>
  );
};

export default EditGroup;
