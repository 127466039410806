import { Navigate, Outlet, useLocation } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";

const PublicRoute = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  if (location.pathname.match(/^\/api\/v1\/exams\/\d+\/public_exam$/)) {
    return <Outlet />; // Allow both logged-in and non-logged-in users
  }
  return user ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoute;
