import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../constants/urls";
import { getToken } from "../../auth/AuthToken";
import { toast } from "react-toastify";

const initialState = {
  testsList: [],
  publicTestsList: [],
  functionFields: null, // Add this line to store function fields
  currentTest: null,
  isLoading: false,
  totalTestsCount: 0,
  selectedTab: 1,
  reportJson: "",
};

export const createNewTest = createAsyncThunk(
  "tests/createNewTest",
  async (test, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/tests`;

    try {
      const response = await axios.post(url, test, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editTest = createAsyncThunk(
  "tests/editTest",
  async ({ test, test_id }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/tests/${test_id}`;

    try {
      const response = await axios.put(url, test, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTest = createAsyncThunk(
  "tests/deleteTest",
  async ({ testId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/tests/${testId}`;

    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      toast.success("Test deleted successfully");
      return { testId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTest = createAsyncThunk(
  "tests/getTest",
  async ({ testId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/tests/${testId}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllTests = createAsyncThunk(
  "tests/getAllTests",
  async (
    { searchQuery, searchTags, page, rowsPerPage },
    { rejectWithValue }
  ) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/tests?q=${searchQuery}&tags=${searchTags}&page=${page + 1
      }&per_page=${rowsPerPage}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPublicTests = createAsyncThunk(
  "tests/getPublicTests",
  async ({ searchQuery, formTemplateId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/tests/public_tests?q=${searchQuery}&id=${formTemplateId}`;

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateOrder = createAsyncThunk(
  "tests/updateOrder",
  async ({ testId, order }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/tests/${testId}/update_order`;

    try {
      const response = await axios.patch(
        url,
        { test: { order_test_function_ids: order } },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async function to save a report template to the backend
export const saveReport = createAsyncThunk(
  'reports/saveReport',
  async ({ report, testId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/tests/${testId}/save_report`;

    try {
      const reportJson = report.saveToJsonString();
      const response = await axios.post(
        url,
        { reportJson },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`,
          },
        }
      );
      toast.success("Report saved successfully");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async function to load a report template from the backend
export const loadReport = createAsyncThunk(
  'reports/loadReport',
  async (testId, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/tests/${testId}/load_report`;

    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async function to fetch function fields for a specific test
export const fetchFunctionFields = createAsyncThunk(
  "tests/fetchFunctionFields",
  async ({ testId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/tests/${testId}/function_fields`;

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTests.fulfilled, (state, action) => {
        state.testsList = action.payload.tests;
        state.isLoading = false;
        state.totalTestsCount = action.payload.meta.total_count;
      })
      .addCase(getAllTests.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPublicTests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPublicTests.fulfilled, (state, action) => {
        state.publicTestsList = action.payload;
        state.isLoading = false;
      })
      .addCase(getPublicTests.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteTest.fulfilled, (state, action) => {
        state.testsList = state.testsList.filter(
          (test) => test.id !== action.payload.testId
        );
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.testFunctionsList = action.payload;
        toast.success("Order updated successfully");
      })
      .addCase(updateOrder.rejected, (state, action) => {
        toast.error(`Failed to update order: ${action.payload}`);
      })
      .addCase(getTest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTest.fulfilled, (state, action) => {
        state.currentTest = action.payload;
        state.isLoading = false;

        const { test_functions } = action.payload;
        state.functionFields = test_functions.flatMap(fn => fn.function_field);
      })
      .addCase(getTest.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(loadReport.pending, (state) => {
      })
      .addCase(loadReport.fulfilled, (state, action) => {
        console.log('action.payload', action.payload.data.reportJson);
        state.reportJson = action.payload.data.reportJson; // Store the JSON data in state
      })
      .addCase(loadReport.rejected, (state, action) => {
        toast.error(`Failed to load report: ${action.payload}`);
      })
      .addCase(fetchFunctionFields.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFunctionFields.fulfilled, (state, action) => {
        state.functionFields = action.payload; // Store the fetched function fields in the state
        state.isLoading = false;
      })
      .addCase(fetchFunctionFields.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(`Failed to fetch function fields: ${action.payload}`);
      });
  },
});
export const { setSelectedTab } = testSlice.actions;

export default testSlice.reducer;
