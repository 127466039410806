import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../constants/urls";
import { getToken } from "../../auth/AuthToken";
import { toast } from "react-toastify";

const initialState = {
  groupsList: [],
  currentGroup: null,
  isLoading: false,
  selectedTab: 1,
};

export const createNewGroup = createAsyncThunk(
  "groups/createNewGroup",
  async (group, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/groups`;
    console.log(group, 'postedGroup')
    try {
      const response = await axios.post(url, group, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editGroup = createAsyncThunk(
  "groups/editGroup",
  async ({ group, group_id }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/groups/${group_id}`;

    try {
      const response = await axios.put(url, group, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteGroup = createAsyncThunk(
  "tests/deleteGroup",
  async ({ groupId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/groups/${groupId}`;

    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      toast.success("Group deleted successfully");
      return { groupId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGroup = createAsyncThunk(
  "groups/getGroup",
  async ({ groupId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/groups/${groupId}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllGroups = createAsyncThunk(
  "groups/getAllGroups",
  async (
    { searchQuery, page, rowsPerPage },
    { rejectWithValue }
  ) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/groups?q=${searchQuery}&page=${page + 1
      }&per_page=${rowsPerPage}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      console.log(response.data)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllGroups.fulfilled, (state, action) => {
        state.groupsList = action.payload.groups;
        state.isLoading = false;
        state.totalGroupsCount = action.payload.meta.total_count;
      })
      .addCase(getAllGroups.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.groupsList = state.groupsList.filter(
          (group) => group.id !== action.payload.groupId
        );
      })
      .addCase(getGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGroup.fulfilled, (state, action) => {
        state.currentGroup = action.payload;
        state.isLoading = false;
      })
      .addCase(getGroup.rejected, (state) => {
        state.isLoading = false;
      })
  },
});
export const { setSelectedTab } = groupSlice.actions;

export default groupSlice.reducer;
