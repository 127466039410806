import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, useTheme } from "@mui/material";
import { palette } from "../../theme/palette.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  getFormTemplate,
  updateReportDetails,
} from "../../redux/features/form_template.js";

const ReportTemplateForm = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams(); // Extracting id from useParams
  const formTemplate = useSelector(
    (state) => state.formTemplate.currentFormTemplate
  );

  const [newReportTemplate, setNewReportTemplate] = useState({
    report_title: formTemplate?.report_title || "",
    report_subtitle: formTemplate?.report_subtitle || "",
    report_logo: formTemplate?.report_logo || null,
    visit_template_id: formTemplate?.visit_template_id || "",
    tests: formTemplate?.tests || [],
  });

  const [selectedImageName, setSelectedImageName] = useState(
    newReportTemplate.report_logo || ""
  );

  const [showHeaderDetails, setShowHeaderDetails] = useState(false);

  useEffect(() => {
    if (!formTemplate) {
      dispatch(
        getFormTemplate({
          formTemplateId: id,
        })
      );
    }
  }, [dispatch, formTemplate, id]);

  const handleChange = (event) => {
    setNewReportTemplate({
      ...newReportTemplate,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogoSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewReportTemplate((prevTemplate) => ({
        ...prevTemplate,
        report_logo: file,
      }));
      setSelectedImageName(file.name);
    }
  };

  // const handleCheckboxChange = (testId) => {
  //   setNewReportTemplate((prevTemplate) => {
  //     const updatedTests = prevTemplate.tests.filter(
  //       (test) => test.id !== testId
  //     );
  //     return { ...prevTemplate, tests: updatedTests };
  //   });
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newReportTemplate.report_title === "") {
      toast.error("Please enter title");
    } else {
      try {
        await dispatch(
          updateReportDetails({
            formTemplate: newReportTemplate,
            formTemplateId: formTemplate.id,
          })
        ).unwrap();
        toast.success("Form Template details updated successfully");
        navigate("/form-templates");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <Box sx={{ marginTop: 3 }}>
      <div>
        <Accordion style={{ marginBottom: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            selected={showHeaderDetails}
            onClick={() => setShowHeaderDetails(!showHeaderDetails)}
          >
            Header Details
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              columnSpacing={10}
              rowSpacing={2}
              paddingRight={20}
              paddingTop={2}
            >
              <Grid item xs={8} md={6}>
                <Typography
                  color={palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}
                >
                  Title
                </Typography>
                <TextField
                  value={newReportTemplate.report_title}
                  fullWidth
                  id="report_title"
                  name="report_title"
                  placeholder="Title"
                  onChange={handleChange}
                  sx={{
                    marginTop: 0,
                  }}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <Typography
                  color={palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}
                >
                  Subtitle
                </Typography>
                <TextField
                  value={newReportTemplate.report_subtitle}
                  fullWidth
                  id="report_subtitle"
                  name="report_subtitle"
                  placeholder="Subtitle"
                  onChange={handleChange}
                  sx={{
                    marginTop: 0,
                  }}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <Typography
                  color={theme.palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}
                >
                  {selectedImageName || "Logo"}
                </Typography>

                <Button variant="outlined" color="primary" component="label">
                  {selectedImageName ? "Change Logo" : "Select Logo"}
                  <input
                    hidden
                    id="report_logo"
                    name="report_logo"
                    accept="image/*"
                    type="file"
                    onChange={handleLogoSelect}
                  />
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ marginBottom: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            Test
          </AccordionSummary>
          <AccordionDetails>
            {newReportTemplate.tests &&
              newReportTemplate.tests.map((test) => (
                <div key={test.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        onChange={(e) => {
                          // if (!e.target.checked) {
                          //   handleCheckboxChange(test.id);
                          // }
                        }}
                      />
                    }
                    label={test.title}
                  />
                </div>
              ))}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            Footer Details
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              columnSpacing={10}
              rowSpacing={2}
              paddingRight={20}
              paddingTop={2}
            >
              <Grid item xs={8} md={6}>
                <Typography
                  color={palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}
                >
                  Postal Code
                </Typography>
                <TextField
                  value="19-XXXXXX"
                  fullWidth
                  id="title"
                  name="title"
                  placeholder="19-XXXXXX"
                  disabled
                  sx={{
                    marginTop: 0,
                  }}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <Typography
                  color={palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}
                >
                  Phone Number
                </Typography>
                <TextField
                  value="(513) 772-1026"
                  fullWidth
                  id="phone_number"
                  name="phone_number"
                  placeholder="(513) 772-1026"
                  disabled
                  sx={{
                    marginTop: 0,
                  }}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <Typography
                  color={palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}
                >
                  FAX
                </Typography>
                <TextField
                  value="(513) 672-2552"
                  fullWidth
                  id="FAX"
                  name="FAX"
                  placeholder="(513) 672-2552"
                  disabled
                  sx={{
                    marginTop: 0,
                  }}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <Typography
                  color={palette.primary.gray}
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}
                >
                  Address
                </Typography>
                <TextField
                  value="Frank"
                  fullWidth
                  id="report_name"
                  name="report_name"
                  placeholder="Frank"
                  disabled
                  sx={{
                    marginTop: 0,
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            marginTop: 3,
            minHeight: "100%",
            columnGap: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ width: 100 }}
          >
            Save
          </Button>
        </Box>
      </div>
    </Box>
  );
};

export default ReportTemplateForm;
