import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, useTheme, FormControlLabel } from "@mui/material";
import { palette } from "../../theme/palette.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createNewUser, editUser } from "../../redux/features/user.js";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { uberAdminUserRoles } from "../../constants/data.js";
import { getAllGroups } from "../../redux/features/group.js";
import Chip from "@mui/material/Chip";

const UserForm = ({ user }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const groups = useSelector((state) => state.group?.groupsList);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const [newUser, setNewUser] = useState({
    title: user?.title || "",
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    job_title: user?.job_title || "",
    credentials: user?.credentials || "",
    phone: user?.phone || "",
    phone_2: user?.phone_2 || "",
    fax: user?.fax || "",
    email: user?.email || "",
    role_ids: user?.role_ids || [],
    enabled: user?.enabled || false,
    users_group_ids: user?.users_group_ids || [],
    password: "asdfghjk",
  });

  useEffect(() => {
    dispatch(
      getAllGroups({
        searchQuery: "",
        page: "",
        rowsPerPage: "",
      })
    );
  }, [dispatch]);

  const handleRoleChange = (event) => {
    const {
      target: { value },
    } = event;
    setNewUser((prevUser) => {
      const newRoles = Array.isArray(value) ? value : [value];
      const uniqueRoles = [...new Set(newRoles)];

      return {
        ...prevUser,
        role_ids: uniqueRoles,
      };
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "enabled") {
      setNewUser((prevUser) => ({
        ...prevUser,
        [name]: event.target.checked,
      }));

      return;
    } else {
      setNewUser({
        ...newUser,
        [name]: value,
      });
    }
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(uberAdminUserRole, roles, theme) {
    return {
      fontWeight:
        roles.indexOf(uberAdminUserRole) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleUserSubmit = async (event) => {
    event.preventDefault();
    if (newUser.first_name === "") {
      toast.error("Please enter First Name");
    } else if (newUser.last_name === "") {
      toast.error("Please enter Last Name");
    } else if (newUser.email === "") {
      toast.error("Please enter Email");
    } else {
      const formData = new FormData();
      formData.append("user[title]", newUser.title);
      formData.append("user[first_name]", newUser.first_name);
      formData.append("user[last_name]", newUser.last_name);
      formData.append("user[job_title]", newUser.job_title);
      formData.append("user[credentials]", newUser.credentials);
      formData.append("user[phone]", newUser.phone);
      formData.append("user[phone_2]", newUser.phone_2);
      formData.append("user[fax]", newUser.fax);
      formData.append("user[email]", newUser.email);
      newUser.role_ids.forEach((role, index) => {
        formData.append(`user[role_ids][${index}]`, role);
      });

      if (newUser?.users_group_ids?.length > 0) {
        newUser?.users_group_ids?.forEach((id, index) => {
          formData.append(`user[users_group_ids][${index}]`, id);
        });
      } else {
        formData.append(`user[users_group_ids][]`, "");
      }

      formData.append("user[enabled]", newUser.enabled);
      formData.append("user[password]", newUser.password);
      try {
        if (user === null) {
          await dispatch(createNewUser(formData)).unwrap();
          toast.success("User added successfully");
        } else {
          await dispatch(
            editUser({ user: formData, user_id: user.id })
          ).unwrap();
          toast.success("User updated successfully");
        }
        navigate("/users");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const handleDelete = (event, name, value) => {
    event.preventDefault();
    setNewUser((prev) => ({
      ...prev,
      [name]: prev[name].filter((id) => id !== value), // Remove the deleted value
    }));
  };

  return (
    <Box>
      <Accordion style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          selected={showUserDetails}
          onClick={() => setShowUserDetails(!showUserDetails)}
        >
          User Info
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            columnSpacing={10}
            rowSpacing={4}
            paddingRight={20}
            paddingTop={2}
          >
            {/* <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Title
              </Typography>
              <TextField
                value={newUser.title}
                fullWidth
                id="title"
                name="title"
                placeholder="Title"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid> */}
            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                First Name
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: "2px" }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                value={newUser.first_name}
                fullWidth
                id="first_name"
                name="first_name"
                placeholder="First Name"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Last Name
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: "2px" }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                value={newUser.last_name}
                fullWidth
                id="last_name"
                name="last_name"
                placeholder="Last Name"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Credentials
              </Typography>
              <TextField
                value={newUser.credentials}
                fullWidth
                id="credentials"
                name="credentials"
                placeholder="Credentials"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Job/Occupation Title
              </Typography>
              <TextField
                value={newUser.job_title}
                fullWidth
                id="job_title"
                name="job_title"
                placeholder="Job Title"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Mobile Phone
              </Typography>
              <TextField
                value={newUser.phone}
                fullWidth
                id="phone"
                name="phone"
                placeholder="Phone"
                type="text"
                inputProps={{ maxLength: 14 }}
                // onChange={handleChange}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  if (value.length <= 10) {
                    if (value.length > 6) {
                      value = `(${value.slice(0, 3)}) ${value.slice(
                        3,
                        6
                      )}-${value.slice(6, 10)}`;
                    } else if (value.length > 3) {
                      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
                    } else if (value.length > 0) {
                      value = `(${value.slice(0, 3)}`;
                    }
                    handleChange({ target: { name: "phone", value } });
                  }
                }}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Other Phone
              </Typography>
              <TextField
                value={newUser.phone_2}
                fullWidth
                id="phone_2"
                name="phone_2"
                placeholder="Phone Number"
                type="text"
                inputProps={{ maxLength: 14 }}
                // onChange={handleChange}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  if (value.length <= 10) {
                    if (value.length > 6) {
                      value = `(${value.slice(0, 3)}) ${value.slice(
                        3,
                        6
                      )}-${value.slice(6, 10)}`;
                    } else if (value.length > 3) {
                      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
                    } else if (value.length > 0) {
                      value = `(${value.slice(0, 3)}`;
                    }
                    handleChange({ target: { name: "phone_2", value } });
                  }
                }}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Email
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: "2px" }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                value={newUser.email}
                fullWidth
                id="email"
                name="email"
                placeholder="email"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                FAX
              </Typography>
              <TextField
                value={newUser.fax}
                fullWidth
                id="fax"
                name="fax"
                placeholder="FAX"
                type="text"
                inputProps={{ maxLength: 14 }}
                // onChange={handleChange}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  if (value.length <= 10) {
                    if (value.length > 6) {
                      value = `(${value.slice(0, 3)}) ${value.slice(
                        3,
                        6
                      )}-${value.slice(6, 10)}`;
                    } else if (value.length > 3) {
                      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
                    } else if (value.length > 0) {
                      value = `(${value.slice(0, 3)}`;
                    }
                    handleChange({ target: { name: "fax", value } });
                  }
                }}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <FormControl fullWidth>
                <Typography
                  color={palette.primary.gray}
                  id="demo-multiple-name-label"
                  sx={{
                    fontSize: "13.74px",
                    lineHeight: "16.63px",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}
                >
                  Account Role
                </Typography>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={newUser.role_ids}
                  onChange={handleRoleChange}
                  input={<OutlinedInput label="Roles" />}
                  MenuProps={MenuProps}
                >
                  {uberAdminUserRoles.map((uberAdminUserRole) => (
                    <MenuItem
                      key={uberAdminUserRole.value}
                      value={uberAdminUserRole.value}
                      style={getStyles(
                        uberAdminUserRole.value,
                        newUser.role_ids,
                        theme
                      )}
                    >
                      {uberAdminUserRole.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={8}
              md={6}
              sx={{
                marginTop: 4,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleChange(e, null)}
                    value={newUser.enabled}
                    checked={newUser.enabled}
                    id="enabled"
                    name="enabled"
                    color="primary"
                  />
                }
                label="Enabled"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          selected={showGroups}
          onClick={() => setShowGroups(!showGroups)}
        >
          Groups
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} paddingRight={2} paddingTop={2}>
            <Grid item xs={12} md={6}>
              <Typography
                color={theme.palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Group
              </Typography>

              <FormControl fullWidth>
                <Select
                  id="users_group_ids"
                  name="users_group_ids"
                  multiple // Enable multiple selections
                  value={newUser?.users_group_ids || []} // Ensure value is an array
                  input={<OutlinedInput label="Groups" />}
                  onChange={handleChange}
                  renderValue={(selected) => (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        maxWidth: "100%",
                        overflow: "hidden",
                      }}
                    >
                      {selected.map((value) => {
                        const group = groups.find((g) => g.id === value);
                        return (
                          <Chip
                            key={value}
                            label={group?.name} // Display the name of the group
                            style={{
                              margin: 2,
                              maxWidth: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            onDelete={(event) => {
                              event.stopPropagation(); // Prevent Select from closing
                              handleDelete(event, "users_group_ids", value);
                            }}
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        );
                      })}
                    </div>
                  )}
                  MenuProps={MenuProps} // Ensure you have defined MenuProps for proper styling
                >
                  {groups.map((group) => (
                    <MenuItem
                      key={group.id}
                      value={group.id}
                      style={getStyles(
                        group.id,
                        newUser.users_group_ids,
                        theme
                      )}
                    >
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginTop: 5,
          minHeight: "100%",
          columnGap: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={handleUserSubmit}
          sx={{ width: 160 }}
        >
          Save User
        </Button>
      </Box>
    </Box>
  );
};

export default UserForm;
