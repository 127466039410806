import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import ReportTemplateForm from "./ReportTemplateForm.jsx";
import { useSelector } from "react-redux";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";

const EditReportDesign = () => {
  const theme = useTheme();
  const isLoading = useSelector((state) => state.formTemplate.isLoading);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Edit Report
        </Typography>
      </Box>
      {isLoading ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <ReportTemplateForm />
      )}
    </Box>
  );
};

export default EditReportDesign;
