import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user";
import utilsReducer from "./features/utils";
import companyReducer from "./features/company";
import testReducer from "./features/test";
import testFunctionReducer from "./features/test_function";
import tagReducer from "./features/tag";
import formTemplateReducer from "./features/form_template";
import reportTemplateReducer from "./features/report_template";
import organizationReducer from "./features/organization";
import breadcrumbsReducer from './features/breadcrumbsSlice';
import groupReducer from './features/group';

export const store = configureStore({
  reducer: {
    user: userReducer,
    utils: utilsReducer,
    company: companyReducer,
    organization: organizationReducer,
    test: testReducer,
    testFunction: testFunctionReducer,
    tag: tagReducer,
    group: groupReducer,
    formTemplate: formTemplateReducer,
    reportTemplate: reportTemplateReducer,
    breadcrumbs: breadcrumbsReducer,
  },
});
