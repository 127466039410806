import { useTheme, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const DeleteConfirmationDialog = ({
  open,
  handleCancel,
  titleText,
  contentText,
  title,
  suffixText,
  handleDelete,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography component="span">{contentText}</Typography>&nbsp;
          <Typography
            component="span"
            sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
          >
            {title?.trim()}
          </Typography>
          &nbsp;
          <Typography component="span">{suffixText}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          sx={{ color: theme.palette.primary.main, fontSize: "15px" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          autoFocus
          sx={{ color: "red", fontSize: "15px" }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
