import React from "react";
import { Box, Button, Paper, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";
import { setSelectedTab } from "../../redux/features/test.js";

const TestLayout = ({
  children,
  tabButtons,
  selectedTab,
  showAddTestFieldsButton,
  setEditingTestFunction,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.test.isLoading);
  const theme = useTheme();

  const handleTabChange = (value, route) => {
    if (value === 3) {
      setEditingTestFunction(null);
    }

    dispatch(setSelectedTab(value));
    if (route) {
      navigate(route);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          columnGap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
          }}
        >
          {tabButtons.map(({ value, label, route }) => (
            <Button
              key={value}
              variant="contained"
              onClick={() => handleTabChange(value, route)}
              sx={{
                boxShadow: "none",
                width: 150,
                backgroundColor:
                  selectedTab === value
                    ? theme.palette.primary.main
                    : "#E7E8EC",
                color:
                  selectedTab === value ? "white" : theme.palette.primary.main,
                ":hover": {
                  backgroundColor:
                    selectedTab === value
                      ? theme.palette.primary.main
                      : "#E7E8EC",
                  color:
                    selectedTab === value
                      ? "white"
                      : theme.palette.primary.main,
                },
              }}
            >
              {label}
            </Button>
          ))}
        </Box>

        {showAddTestFieldsButton && selectedTab === 2 && (
          <Button
            variant="contained"
            onClick={() => handleTabChange(3)}
            sx={{
              boxShadow: "none",
              width: 168,
            }}
          >
            Add Test Fields
          </Button>
        )}
      </Box>

      {isLoading ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <Box
          sx={{
            padding: 3,
            marginTop: 3,
            height: "72vh",
            borderRadius: "16px",
            boxShadow: "0 0 15px 0 #0000001a",
            overflow: "scroll",
          }}
          component={Paper}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default TestLayout;
