import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  useTheme,
  Button,
  Grid,
  Slider,
  TextField,
  Radio,
  RadioGroup,
  Input,
  Tooltip,
  IconButton,
  Checkbox,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SignatureCanvas from "react-signature-canvas";
import { penColors } from "../../constants/data";
import ImageEditor from "./ImageEditor";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getTest, setSelectedTab } from "../../redux/features/test.js";
import TestLayout from "../common/TestLayout.jsx";
import { styled } from "@mui/material/styles";

const TestPreview = () => {
  const theme = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTest({ testId: id }));
    dispatch(setSelectedTab(1));
  }, [dispatch, id]);

  const tabButtons = [
    { value: 1, label: "Edit Test", route: `/tests/test-details/${id}` },
    { value: 2, label: "Test Fields", route: `/tests/test-details/${id}` },
    { value: 4, label: "Preview" },
    { value: 5, label: "PDF Preview", route: `/tests/test-details/${id}` },
  ];

  const signatureCanvasRef = useRef(null);
  const [signaturePenColor, setSignaturePenColor] = useState(penColors[0]);
  const [signaturePenWidth, setSignaturePenWidth] = useState(1);
  const [cleared, setCleared] = useState(false);

  const currentDate = dayjs();
  const formattedDate = currentDate.format("MMMM DD, YYYY");

  const handleSignatureSliderChange = (event, width) => {
    setSignaturePenWidth(width);
  };

  const clearSignatureCanvas = () => {
    signatureCanvasRef.current.clear();
  };

  const isLoading = useSelector((state) => state.test.isLoading);
  const test = useSelector((state) => state.test.currentTest);

  const [selectedImages, setSelectedImages] = useState({});

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  const handleImageSelect = (event, id) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImages((prevState) => ({
        ...prevState,
        [id]: reader.result,
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleChangeImage = (id) => {
    setSelectedImages((prevState) => ({
      ...prevState,
      [id]: null,
    }));
  };
  const ResizableTextarea = styled("textarea")({
    resize: "both",
    width: "100%",
    height: "100px", // Set a default height if needed
    padding: "8px",
    fontSize: "14px",
    borderColor: "#ccc",
    borderRadius: "4px",
    overflow: "auto",
  });

  return (
    <TestLayout tabButtons={tabButtons} selectedTab={4}>
      <Box>
        <Box>
          <Box>
            <Typography
              color="primary"
              sx={{
                fontSize: "30px",
                lineHeight: "28px",
                fontWeight: 700,
              }}
            >
              {test.title}
            </Typography>
          </Box>

          <Box>
            <Typography
              color="primary"
              sx={{
                fontSize: "14px",
                lineHeight: "16.94px",
                fontWeight: 300,
                marginTop: 1.5,
              }}
              dangerouslySetInnerHTML={{
                __html: test?.description,
              }}
            ></Typography>
          </Box>

          <Grid
            container
            columnSpacing={10}
            rowSpacing={4}
            paddingRight={20}
            paddingTop={3}
          >
            {test &&
              test?.test_functions &&
              test?.test_functions.map((testFunction) => (
                <Grid
                  item
                  md={
                    testFunction.line_break ||
                    testFunction.data_type === "EditableImage"
                      ? 12
                      : 6
                  }
                  key={testFunction.id}
                >
                  {testFunction.data_type !== "Boolean" && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        color={theme.palette.primary.gray}
                        sx={{
                          fontSize: "14px",
                          lineHeight: "16.63px",
                          marginBottom: "10px",
                          fontWeight: 700,
                          marginTop: 2,
                        }}
                      >
                        {`${testFunction.display_name}${
                          testFunction.required ? "*" : ""
                        }`}
                      </Typography>
                      {testFunction?.detail && (
                        <Tooltip
                          title={
                            testFunction.data_type === "ReadOnlyText"
                              ? testFunction.read_only_detail || ""
                              : testFunction.detail || ""
                          }
                          arrow
                          placement="right-start"
                        >
                          <IconButton>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  )}

                  {testFunction.data_type === "Picklist" ? (
                    <FormControl fullWidth>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="company_id"
                        id="company_id"
                      >
                        {testFunction?.picklist_options &&
                          testFunction?.picklist_options.map((option) => (
                            <FormControlLabel
                              value={option.id}
                              control={<Radio />}
                              label={
                                <Typography
                                  color={theme.palette.primary.gray}
                                  sx={{
                                    fontSize: "13px", // Smaller font size for options
                                    lineHeight: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {option.option_text}
                                </Typography>
                              }
                              key={option.id}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  ) : testFunction.data_type === "Image" ? (
                    selectedImages[testFunction.id] ? (
                      <Box>
                        <Box
                          component="img"
                          sx={{
                            maxWidth: "300px",
                            maxHeight: "300px",
                            marginTop: 2,
                          }}
                          alt="Selected"
                          src={selectedImages[testFunction.id]}
                        />
                        <Button
                          sx={{
                            width: "fit-content",
                            paddingX: 2,
                            boxShadow: "none",
                            backgroundColor: theme.palette.secondary.gray,
                            color: theme.palette.primary.main,
                            ":hover": {
                              backgroundColor: theme.palette.secondary.gray,
                              color: theme.palette.primary.main,
                            },
                            marginTop: 2,
                          }}
                          variant="contained"
                          onClick={() => handleChangeImage(testFunction.id)}
                        >
                          Change Image
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        sx={{
                          width: "fit-content",
                          paddingX: 2,
                          boxShadow: "none",
                          backgroundColor: theme.palette.secondary.gray,
                          color: theme.palette.primary.main,
                          ":hover": {
                            backgroundColor: theme.palette.secondary.gray,
                            color: theme.palette.primary.main,
                          },
                        }}
                        variant="contained"
                        component="label"
                        startIcon={<FileUploadOutlinedIcon />}
                      >
                        Select File
                        <input
                          type="file"
                          hidden
                          onChange={(event) =>
                            handleImageSelect(event, testFunction.id)
                          }
                        />
                      </Button>
                    )
                  ) : testFunction.data_type === "EditableImage" ? (
                    <ImageEditor />
                  ) : testFunction.data_type === "ReadOnlyText" ? (
                    <Typography
                      // color="primary"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "16.94px",
                        fontWeight: 300,
                        marginTop: 1.5,
                      }}
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: testFunction.detail,
                      }}
                    />
                  ) : testFunction.data_type === "Boolean" ? (
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={
                              testFunction.default_value === "true"
                            }
                            onChange={(e) =>
                              console.log("Checked:", e.target.checked)
                            }
                          />
                        }
                        label={
                          <Typography
                            color={theme.palette.primary.gray}
                            sx={{
                              fontSize: "13px", // Smaller font size for options
                              lineHeight: "14px",
                              fontWeight: 400,
                            }}
                          >
                            {testFunction.display_name}
                            {testFunction?.detail && (
                              <Tooltip
                                title={
                                  testFunction.data_type === "ReadOnlyText"
                                    ? testFunction.read_only_detail || ""
                                    : testFunction.detail || ""
                                }
                                arrow
                                placement="right-start"
                              >
                                <IconButton>
                                  <InfoOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Typography>
                        }
                      />
                    </FormControl>
                  ) : testFunction.data_type === "Signature" ? (
                    <Box
                      sx={{
                        width: "50%",
                      }}
                    >
                      <SignatureCanvas
                        ref={signatureCanvasRef}
                        penColor={signaturePenColor}
                        backgroundColor="#f5f7fa"
                        minWidth={signaturePenWidth}
                        maxWidth={signaturePenWidth}
                        canvasProps={{
                          width: 500,
                          height: 200,
                          className: "sigCanvas",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mt: 1,
                          width: 500,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            color={theme.palette.primary.gray}
                            sx={{
                              mr: 1,
                              fontSize: "12px",
                              lineHeight: "16.63px",
                              marginBottom: "10px",
                              fontWeight: 600,
                              marginTop: 1,
                            }}
                          >
                            Print Name:
                          </Typography>
                          <Input
                            sx={{
                              marginBottom: 1,
                              fontSize: "12px",
                              lineHeight: "16.63px",
                              marginBottom: "10px",
                              fontWeight: 600,
                            }}
                            defaultValue=""
                          />
                        </Box>
                        <Typography
                          color={theme.palette.primary.gray}
                          sx={{
                            fontSize: "12px",
                            lineHeight: "16.63px",
                            marginBottom: "10px",
                            fontWeight: 600,
                            marginTop: 1,
                          }}
                        >
                          {formattedDate}
                        </Typography>
                      </Box>
                      <Box sx={{ width: 500, mt: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Slider
                            step={1}
                            min={1}
                            max={5}
                            marks
                            value={signaturePenWidth}
                            onChange={handleSignatureSliderChange}
                            valueLabelDisplay="auto"
                          />
                          <RadioGroup
                            row
                            value={signaturePenColor}
                            onChange={(e) =>
                              setSignaturePenColor(e.target.value)
                            }
                            sx={{
                              width: "60%",
                              width: "60%",
                              padding: "0px",
                              ml: 2,
                            }}
                          >
                            {penColors.map((color) => (
                              <FormControlLabel
                                key={color}
                                value={color}
                                control={
                                  <Radio
                                    sx={{
                                      color: color,

                                      padding: "0px",
                                      "&.Mui-checked": { color: color },
                                    }}
                                  />
                                }
                              />
                            ))}
                          </RadioGroup>
                          <Button
                            variant="outlined"
                            onClick={clearSignatureCanvas}
                            sx={{ width: "fit-content", paddingY: 0 }}
                          >
                            Clear
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  ) : testFunction.data_type === "Date" ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        slotProps={{
                          field: {
                            clearable: true,
                            onClear: () => setCleared(true),
                          },
                        }}
                      />
                    </LocalizationProvider>
                  ) : testFunction.data_type === "Notes" ? (
                    <ResizableTextarea
                      placeholder={testFunction.detail}
                      required={testFunction.required}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      placeholder={testFunction.detail}
                      required={testFunction.required}
                      type={
                        testFunction.data_type === "Integer" ||
                        testFunction.data_type === "Decimal"
                          ? "number"
                          : "text"
                      }
                      sx={{
                        marginTop: 0,
                      }}
                      multiline={testFunction.data_type === "Notes"}
                      rows={3}
                      maxRows={4}
                    />
                  )}
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </TestLayout>
  );
};

export default TestPreview;
