import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { palette } from "../../theme/palette.js";
import CompanyForm from "./CompanyForm.jsx";

const NewCompany = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Create Account
        </Typography>
      </Box>

      <CompanyForm company={null} />
    </Box>
  );
};

export default NewCompany;
