import "./App.css";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import PrivateRoute from "./PrivateRoute";
import Companies from "./components/company/Companies.jsx";
import Users from "./components/user/Users.jsx";
import NewCompany from "./components/company/NewCompany.jsx";
import NewUser from "./components/user/NewUser.jsx";
import EditUser from "./components/user/EditUser.jsx"
import lightTheme from "./theme";
import PublicRoute from "./PublicRoute.js";
import { ToastContainer } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import Workers from "./components/worker/Workers.jsx"
import NewWorker from "./components/worker/NewWorker.jsx"
import EditWorker from "./components/worker/EditWorker.jsx"
import Groups from "./components/group/Groups.jsx"
import NewGroup from "./components/group/NewGroup.jsx"
import EditGroup from "./components/group/EditGroup.jsx"
import Tests from "./components/test/Tests.jsx";
import NewTest from "./components/test/NewTest.jsx";
import TestDetails from "./components/test/TestDetails.jsx";
import EditTest from "./components/test/EditTest.jsx";
import Tags from "./components/tags/Tags.jsx";
import NewTag from "./components/tags/NewTag.jsx";
import EditTag from "./components/tags/EditTag.jsx";
import FormTemplates from "./components/form_template/FormTemplates.jsx";
import NewFormTemplate from "./components/form_template/NewFormTemplate.jsx";
import EditFormTemplate from "./components/form_template/EditFormTemplate.jsx";
import TestPreview from "./components/test/TestPreview.jsx";
import LinkTests from "./components/form_template/LinkTests.jsx";
import EditCompany from "./components/company/EditCompany.jsx";
import Exams from "./components/exam/Exams.jsx"
import NewExam from "./components/exam/NewExam.jsx"
import EditExam from "./components/exam/EditExam.jsx"
import FormTemplateDetails from "./components/form_template/FormTemplateDetails.jsx";
import ReportTemplates from "./components/report_template/ReportTemplates.jsx";
import NewReportTemplate from "./components/report_template/NewReportTemplate.jsx";
import EditReportTemplate from "./components/report_template/EditReportTemplate.jsx";
import EditReportDesign from './components/form_template/EditReportDesign.jsx'
import ReportTemplateDetails from "./components/report_template/ReportTemplateDetails.jsx";
import Organizations from './components/organization/Organizations.jsx'
import NewOrganization from './components/organization/NewOrganization.jsx'
import EditOrganization from './components/organization/EditOrganization.jsx'
import Error404 from "./Pages/errors/Error404.jsx";
import UserProfile from "./components/user/UserProfile.jsx";
import ResetPassword from './components/ResetPassword';
import ExamSurvey from './components/exam/ExamSurvey.jsx'
import ExamReportViewer from './components/exam/ExamStimulsoftReport.jsx'

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <ToastContainer />
      <CssBaseline />
      <Router>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
            <Route path="/api/v1/password_resets/edit" element={<ResetPassword />} />
            <Route path="/api/v1/exams/:id/public_exam" element={<ExamSurvey />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/add-user" element={<NewUser />} />
            <Route path="/users/edit-user/:id" element={<EditUser />} />
            <Route path="/users/profile" element={<UserProfile />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/companies/add-company" element={<NewCompany />} />
            <Route
              path="/companies/edit-company/:id"
              element={<EditCompany />}
            />
            <Route path="/organizations" element={<Organizations />} />
            <Route path="/organizations/new-organization" element={<NewOrganization />} />
            <Route path="/organizations/edit-organization/:id" element={<EditOrganization />} />
            <Route path="/exams" element={<Exams />} />
            <Route path="/exams/new-exam" element={<NewExam />} />
            <Route path="/exams/edit-exam/:id" element={<EditExam />} />
            <Route path="/exam-report-viewer/:examId" element={<ExamReportViewer />} />
            <Route path="/workers" element={<Workers />} />
            <Route path="/workers/new-worker" element={<NewWorker />} />
            <Route path="/workers/edit-worker/:id" element={<EditWorker />} />
            <Route path="/groups" element={<Groups />} />
            <Route path="/groups/new-group" element={<NewGroup />} />
            <Route path="/groups/edit-group/:id" element={<EditGroup />} />
            <Route path="/tests" element={<Tests />} />
            <Route path="/tests/new-test" element={<NewTest />} />
            <Route path="/tests/edit-test/:id" element={<EditTest />} />
            <Route path="/tests/test-details/:id" element={<TestDetails />} />
            <Route path="/tests/test-preview/:id" element={<TestPreview />} />
            <Route path="/tags" element={<Tags />} />
            <Route path="/tags/new-tag" element={<NewTag />} />
            <Route path="/tags/edit-tag/:id" element={<EditTag />} />
            <Route path="/form-templates" element={<FormTemplates />} />
            <Route path="/form-templates/edit-report-design/:id" element={<EditReportDesign />} />
            <Route
              path="/form-templates/new-form-template"
              element={<NewFormTemplate />}
            />
            <Route
              path="/form-templates/edit-form-template/:id"
              element={<EditFormTemplate />}
            />
            <Route
              path="/form-templates/form-template-details/:id"
              element={<FormTemplateDetails />}
            />
            <Route
              path="/form-templates/link-tests-to-form-template/:id"
              element={<LinkTests />}
            />
            {/* <Route path="/report-templates" element={<ReportTemplates />} />
            <Route
              path="/report-templates/new-report-template"
              element={<NewReportTemplate />}
            /> */}
            <Route
              path="/report-templates/edit-report-template/:id"
              element={<EditReportTemplate />}
            />
            {/* <Route
              path="/report-templates/report-template-details/:id"
              element={<ReportTemplateDetails />}
            /> */}
          </Route>

          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
