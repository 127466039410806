import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, Link } from "@mui/material";
import { useSelector } from "react-redux";

const BreadCrumbs = () => {
  const location = useLocation();
  const currentFormTemplate = useSelector(
    (state) => state.formTemplate.currentFormTemplate
  ); // Example selector

  const currentTest = useSelector((state) => state.test.currentTest); // Example selector
  const currentButton = useSelector((state) => state.breadcrumbs.currentButton);

  const routeNameMap = {
    "": "Dashboard",
    users: "Users",
    "add-user": "Add User",
    profile: "User Profile",
    companies: "Accounts",
    "add-company": "Add Account",
    "edit-company": "Update Account",
    organizations: "Organizations",
    "new-organization": "Add Organization",
    "edit-organization": "Update Organization",
    tests: "Tests",
    "new-test": "Add Test",
    "edit-test": "Update Test",
    "test-details": "Test Details",
    "test-preview": "Test Preview",
    groups: "Groups",
    tags: "Tags",
    "new-tag": "Add Tag",
    "edit-tag": "Update Tag",
    "form-templates": "Forms",
    "new-form-template": "New Form",
    "edit-form-template": "Edit Form",
    "form-template-details": "Form Details",
    "link-tests-to-form-template": "Link Tests",
  };

  const pathnames = location.pathname.split("/").filter((x) => x);
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {pathnames.map((value, index) => {
        // Skip segments that are likely IDs (numeric or UUIDs)
        if (/^\d+$/.test(value) || /^[a-f0-9-]{36}$/i.test(value)) {
          return null;
        }

        const last = index === pathnames.length - 1;
        let to = "";

        if (
          ["form-template-details", "test-details", "test-preview"].includes(
            value
          )
        ) {
          to = `${location.pathname}`;
        } else {
          to = `/${pathnames.slice(0, index + 1).join("/")}`;
        }
        let name = routeNameMap[value] || value;

        // Handle dynamic titles for specific routes
        if (value === "form-template-details" && currentFormTemplate) {
          name = currentFormTemplate.title || name;
        }

        if (
          (value === "test-details" || value === "test-preview") &&
          currentTest
        ) {
          name = currentTest.title || name;
        }

        return last ? (
          <Typography key={to} color="white">
            {name}
          </Typography>
        ) : (
          <React.Fragment key={to}>
            <Link
              onClick={() => (window.location.href = to)}
              sx={{ color: "white", cursor: "pointer" }}
            >
              {name}
            </Link>
            <Typography color="white">/</Typography>
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default BreadCrumbs;
