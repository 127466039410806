import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { getPublicTests } from "../../redux/features/test";
import { useDispatch, useSelector } from "react-redux";
import {
  editFormTemplate,
  getFormTemplate,
} from "../../redux/features/form_template";
import { useParams } from "react-router-dom";
import { updateAssociatedTestsOrder } from "../../redux/features/form_template";
import AvailableTestsPanel from "./AvailableTestsPanel";
import AssociatedTestsPanel from "./AssociatedTestsPanel";
import GradientCircularProgress from "../../elements/GradientCircularProgress";

const extractAssociatedTestsIds = (tests) => {
  return tests.map((test) => test.id);
};

const LinkTests = ({ formTemplate }) => {
  const dispatch = useDispatch();

  const publicTestsList = useSelector((state) => state.test.publicTestsList);
  const isLoadingTests = useSelector((state) => state.test.isLoading);
  const currentFormTemplate = useSelector(
    (state) => state.formTemplate.currentFormTemplate
  );
  const isLoadingFormTemplate = useSelector(
    (state) => state.formTemplate.isLoading
  );

  const [test_ids, set_test_ids] = useState([]);
  const [associatedTests, setAssociatedTests] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = test_ids.indexOf(value);
    const newChecked = [...test_ids];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    set_test_ids(newChecked);
  };

  const handleButtonClick = async () => {
    const updatedFormTemplate = {
      ...currentFormTemplate,
      test_ids: test_ids,
    };
    await dispatch(
      editFormTemplate({
        formTemplate: updatedFormTemplate,
        formTemplateId: formTemplate.id,
      })
    ).unwrap();
    dispatch(
      getPublicTests({
        searchQuery: "",
        formTemplateId: formTemplate.id,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getPublicTests({
        searchQuery: "",
        formTemplateId: currentFormTemplate.id,
      })
    );
  }, []);

  useEffect(() => {
    if (!currentFormTemplate) {
      dispatch(
        getFormTemplate({
          formTemplateId: formTemplate.id,
        })
      );
    }
  }, [dispatch, currentFormTemplate, formTemplate.id]);

  useEffect(() => {
    if (currentFormTemplate && currentFormTemplate.tests) {
      set_test_ids(extractAssociatedTestsIds(currentFormTemplate.tests));
      setAssociatedTests(currentFormTemplate.tests);
    }
  }, [currentFormTemplate]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedList = Array.from(associatedTests);
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);

    setAssociatedTests(reorderedList);

    const updatedOrder = reorderedList.map((item) => item.id);
    dispatch(
      updateAssociatedTestsOrder({
        formTemplateId: formTemplate.id,
        order: updatedOrder,
      })
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Edit Form Tests
        </Typography>
      </Box>

      {isLoadingTests || isLoadingFormTemplate ? (
        <GradientCircularProgress open={true} />
      ) : (
        <>
          <Grid
            container
            columnSpacing={10}
            rowSpacing={4}
            paddingRight={20}
            paddingTop={2}
          >
            <AvailableTestsPanel
              publicTestsList={publicTestsList}
              handleToggle={handleToggle}
              test_ids={test_ids}
            />
            <AssociatedTestsPanel
              onDragEnd={onDragEnd}
              associatedTests={associatedTests}
              handleToggle={handleToggle}
              test_ids={test_ids}
            />
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: 2,
            }}
          >
            <Button
              sx={{ width: 160, mr: 8 }}
              variant="contained"
              color="primary"
              onClick={handleButtonClick}
            >
              Save Form
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default LinkTests;
