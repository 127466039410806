import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  InputBase,
  IconButton,
  Button,
  Link,
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/features/user";
import EmptyList from "../common/EmptyList";
import GradientCircularProgress from "../../elements/GradientCircularProgress";
import TableList from "./TableList";

const Users = () => {
  const users = useSelector((state) => state.user.usersList);
  const isLoading = useSelector((state) => state.user.isLoading);
  const totalUsersCount = useSelector((state) => state.user.totalUsersCount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    dispatch(
      getAllUsers({
        searchValue: searchValue,
        page: page,
        rowsPerPage: rowsPerPage,
      })
    );
  }, [dispatch, page, rowsPerPage, searchValue]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleUserListItemClick = (user) => {
    navigate(`/users/edit-user/${user.id}`)
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Users
        </Typography>
        <Box sx={{ display: "flex", columnGap: 2 }}>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search users" }}
              onChange={handleSearchChange}
              value={searchValue}
            />
          </Paper>
          <Link>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/users/add-user");
              }}
            >
              Add New User
            </Button>
          </Link>
        </Box>
      </Box>

      {isLoading && !searchValue && users.length === 0 ? (
        <GradientCircularProgress open={isLoading} />
      ) : users && users.length === 0 ? (
        <EmptyList
          messageHeader={
            searchValue ? "No users found." : "No users available."
          }
          message={
            searchValue
              ? "Try adjusting your search or"
              : "It looks like there are no users added yet. To get started,"
          }
          buttonText="create a user"
          route="/users/add-user"
        />
      ) : (
        <Box sx={{ mt: 3 }}>
          <TableList
            users={users}
            page={page}
            rowsPerPage={rowsPerPage}
            totalUsersCount={totalUsersCount}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleUserListItemClick={handleUserListItemClick}
          />
        </Box>
      )}
    </Box>
  );
};

export default Users;
