import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../constants/urls";
import { getToken } from "../../auth/AuthToken";
import { toast } from "react-toastify";

const initialState = {
  reportTemplatesList: [],
  currentReportTemplate: null,
  isLoading: false,
};

export const createReportTemplate = createAsyncThunk(
  "report_templates/createReportTemplate",
  async ({ reportTemplate }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/report_templates`;

    try {
      const response = await axios.post(url, reportTemplate, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editReportTemplate = createAsyncThunk(
  "report_templates/editReportTemplate",
  async ({ reportTemplate, reportTemplateId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/report_templates/${reportTemplateId}`;

    try {
      const response = await axios.put(url, reportTemplate, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteReportTemplate = createAsyncThunk(
  "report_templates/deleteReportTemplate",
  async ({ reportTemplateId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/report_templates/${reportTemplateId}`;

    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });

      toast.success("Report template deleted successfully");
      return { reportTemplateId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllReportTemplates = createAsyncThunk(
  "report_templates/getAllReportTemplates",
  async ({ searchQuery }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/report_templates?q=${searchQuery}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getReportTemplate = createAsyncThunk(
  "report_templates/getReportTemplate",
  async ({ reportTemplateId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/report_templates/${reportTemplateId}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reportTemplateSlice = createSlice({
  name: "formTemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllReportTemplates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllReportTemplates.fulfilled, (state, action) => {
        state.reportTemplatesList = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllReportTemplates.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editReportTemplate.pending, (state) => { })
      .addCase(editReportTemplate.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(editReportTemplate.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getReportTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReportTemplate.fulfilled, (state, action) => {
        state.currentReportTemplate = action.payload;
        state.isLoading = false;
      })
      .addCase(getReportTemplate.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteReportTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteReportTemplate.fulfilled, (state, action) => {
        state.reportTemplatesList = state.reportTemplatesList.filter(
          (template) => template.id !== action.payload.reportTemplateId
        );
        state.isLoading = false;
      })
      .addCase(deleteReportTemplate.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default reportTemplateSlice.reducer;
