import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { palette } from "../../theme/palette.js";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createTag, editTag } from "../../redux/features/tag.js";

const TagForm = ({ tag }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newTag, setNewTag] = useState({
    title: tag?.title || "",
  });

  const handleChange = (event) => {
    setNewTag({
      ...newTag,
      [event.target.name]: event.target.value,
    });
  };

  const handleTagSubmit = async (event) => {
    event.preventDefault();

    if (newTag.title === "") {
      toast.error("Please enter tag title");
    } else {
      try {
        if (tag === null) {
          await dispatch(createTag({ tag: newTag })).unwrap();
          toast.success("Tag added successfully");
        } else {
          await dispatch(editTag({ tag: newTag, tag_id: tag.id })).unwrap();
          toast.success("Tag updated successfully");
        }
        navigate("/tags");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <Box>
      <Grid
        container
        columnSpacing={10}
        rowSpacing={4}
        paddingRight={20}
        paddingTop={5}
      >
        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Title
          </Typography>
          <TextField
            value={newTag.title}
            fullWidth
            id="title"
            name="title"
            placeholder="Title"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginRight: 10,
          marginTop: 10,
          minHeight: "100%",
          columnGap: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={handleTagSubmit}
          sx={{ width: 175 }}
        >
          Save Tag
        </Button>
      </Box>
    </Box>
  );
};

export default TagForm;
