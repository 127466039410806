import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import ReportTemplateForm from "./ReportTemplateForm.jsx";

const NewReportTemplate = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          New Report Template
        </Typography>
      </Box>
      <ReportTemplateForm reportTemplate={null} />
    </Box>
  );
};

export default NewReportTemplate;
