import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, useTheme } from "@mui/material";
import { palette } from "../../theme/palette.js";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  createOrganization,
  deleteOrganization,
  editOrganization,
} from "../../redux/features/organization.js";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog.jsx";

const OrganizationForm = ({ organization }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [newOrganization, setNewOrganization] = useState({
    name: organization?.name || "",
    email: organization?.email || "", // Initialize email state
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setNewOrganization({
      ...newOrganization,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newOrganization.name === "" || newOrganization.email === "") {
      toast.error("Please enter name and email");
    } else {
      const formData = new FormData();
      formData.append("organization[name]", newOrganization.name);
      formData.append("organization[email]", newOrganization.email); // Add email to form data

      try {
        if (organization == null) {
          await dispatch(
            createOrganization({ organization: formData })
          ).unwrap();
          toast.success("Organization added successfully");
        } else {
          await dispatch(
            editOrganization({
              organization: formData,
              organizationId: organization.id,
            })
          ).unwrap();
          toast.success("Organization updated successfully");
        }
        navigate("/organizations");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const handleDeleteOrganizationClick = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDeleteConfirmation = async () => {
    if (organization) {
      await dispatch(deleteOrganization({ organizationId: organization.id }));
      navigate("/organizations");
      setOpen(false);
    }
  };

  return (
    <Box>
      <Grid
        container
        columnSpacing={10}
        rowSpacing={4}
        paddingRight={20}
        paddingTop={5}
      >
        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Name
          </Typography>
          <TextField
            value={newOrganization.name}
            fullWidth
            id="name"
            name="name"
            placeholder="Organization Name"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
              fontWeight: 700,
            }}
          >
            Email
          </Typography>
          <TextField
            value={newOrganization.email}
            fullWidth
            id="email"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginRight: 10,
          marginTop: 10,
          minHeight: "100%",
          columnGap: 2,
        }}
      >
        {organization && (
          <Button
            variant="contained"
            onClick={handleDeleteOrganizationClick}
            id={newOrganization.id}
            sx={{
              width: 160,
              backgroundColor: "#f8d7da",
              color: "#721c24",
              "&:hover": {
                backgroundColor: "#f5c6cb",
              },
              borderColor: "#f5c6cb",
            }}
          >
            Delete Organization
          </Button>
        )}

        <Button variant="contained" onClick={handleSubmit} sx={{ width: 160 }}>
          Save Organization
        </Button>
      </Box>

      <DeleteConfirmationDialog
        open={open}
        handleCancel={handleCancel}
        titleText="Confirm Delete"
        contentText="Are you sure you want to delete"
        title={organization?.title}
        suffixText="from organization list?"
        handleDelete={handleDeleteConfirmation}
      />
    </Box>
  );
};

export default OrganizationForm;
