import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Tooltip,
  TableRow,
  TablePagination,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { deleteUser } from "../../redux/features/user";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog.jsx";

const TableList = ({
  users,
  page,
  rowsPerPage,
  totalUsersCount,
  handleChangePage,
  handleChangeRowsPerPage,
  handleUserListItemClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleDeleteUserClick = (event, user) => {
    event.preventDefault();
    setSelectedUser(user);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleDeleteConfirmation = async () => {
    if (selectedUser) {
      await dispatch(deleteUser({ userId: selectedUser.id }));
      setOpen(false);
      navigate("/users");
    }
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 460 }}>
        <Table stickyHeader aria-label="tests table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  width: "40%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Email
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Role
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "40%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Created At
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "40%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={user.id}
                  onClick={() => handleUserListItemClick(user)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <TableCell align="left" sx={{ width: "20%" }}>
                    {user.email}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "20%" }}>
                    {user.roles.join(", ")}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "30%" }}>
                    {dayjs(user.created_at).format("MMMM DD, YYYY")}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "20%" }}>
                    <Tooltip title="Delete">
                      <IconButton
                        sx={{ p: "10px" }}
                        onClick={(event) =>{
                          event.stopPropagation();
                          handleDeleteUserClick(event, user)
                        }}
                        id={user.id}
                      >
                        <DeleteOutlinedIcon sx={{ color: "red" }} />
                      </IconButton>
                    </Tooltip>
                </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteConfirmationDialog
        open={open}
        handleCancel={handleCancel}
        titleText="Confirm Delete"
        contentText="Are you sure you want to delete"
        title={selectedUser?.title}
        suffixText="from users list?"
        handleDelete={handleDeleteConfirmation}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalUsersCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
          }}
        />
      </Box>
    </Box>
  );
};

export default TableList;
