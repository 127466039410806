import React, { useEffect } from "react";
import { Stimulsoft } from "stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor";
import "stimulsoft-reports-js/Scripts/stimulsoft.viewer";
import "stimulsoft-reports-js/Scripts/stimulsoft.reports";

const CombinedReportViewer = () => {
  useEffect(() => {
    // Initialize the Stimulsoft report and viewer
    const report = new Stimulsoft.Report.StiReport();
    const viewer = new Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);

    // Load the first report template
    const report1 = new Stimulsoft.Report.StiReport();
    report1.loadFile("/report/Report.mrt");
    const report2 = new Stimulsoft.Report.StiReport();
    report2.loadFile("../reports/ScndReport.mrt");

    // Merge the second report's pages into the first report
    report1.renderAsync(() => {
      report2.renderAsync(() => {
        // // Iterate over the pages of the second report and add them to the first report
        // for (let i = 0; i < report2.pages.count; i++) {
        //   report1.pages.add(report2.pages.getByIndex(i));
        // }

        // Assign the combined report to the viewer
        viewer.report = report1;
        // Render the combined report in the viewer
        viewer.renderHtml("viewerContent");
      });
    });
  }, []);

  return <div id="viewerContent"></div>;
};

export default CombinedReportViewer;
