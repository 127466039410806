import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import ReportTemplateForm from "./ReportTemplateForm.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";
import { getReportTemplate } from "../../redux/features/report_template.js";

const EditReportTemplate = () => {
  const { id } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();

  const reportTemplate = useSelector(
    (state) => state.reportTemplate.currentReportTemplate
  );
  const isLoading = useSelector((state) => state.reportTemplate.isLoading);

  useEffect(() => {
    dispatch(getReportTemplate({ reportTemplateId: id }));
  }, [dispatch, id]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Edit Report
        </Typography>
      </Box>
      {isLoading ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <ReportTemplateForm reportTemplate={reportTemplate} />
      )}
    </Box>
  );
};

export default EditReportTemplate;
