import React, { useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
  Paper,
  InputBase,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EmptyListMessage from "../common/EmptyListMessage";

const AvailableTestsPanel = ({ publicTestsList, handleToggle, test_ids }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredTests = publicTestsList.filter((test) =>
    test.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  return (
    <Grid item xs={8} md={6}>
      <Typography>Available Tests</Typography>
      <Paper
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton type="button" sx={{ p: "10px" }} disabled>
          <SearchIcon />
        </IconButton>
        <InputBase
          placeholder="Search associated tests..."
          inputProps={{ "aria-label": "search associated tests" }}
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          endAdornment={
            searchQuery && (
              <IconButton onClick={handleClearSearch}>
                <CloseOutlinedIcon />
              </IconButton>
            )
          }
        />
      </Paper>

      {filteredTests.length === 0 ? (
        <EmptyListMessage
          messageHeader={
            searchQuery ? "No public tests found." : "No tests available."
          }
          message={
            searchQuery
              ? "No public tests match your search query. Please try again with a different search term."
              : "It looks like there are no public tests available."
          }
        />
      ) : (
        <Box
          sx={{
            maxHeight: "60vh",
            overflow: "auto",
            paddingTop: 2,
          }}
        >
          <List>
            {filteredTests.map((test) => {
              const labelId = `checkbox-list-label-${test.id}`;

              return (
                <ListItem
                  key={test.id}
                  disablePadding
                  sx={{
                    backgroundColor: "#F6F6F6",
                    marginBottom: 1,
                    borderRadius: "13px",
                  }}
                >
                  <ListItemButton
                    role={undefined}
                    onClick={handleToggle(test.id)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={test_ids.indexOf(test.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={test.title} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </Grid>
  );
};

export default AvailableTestsPanel;
