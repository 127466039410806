import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  useTheme,
} from "@mui/material";

const TableList = ({
  organizationList,
  page,
  rowsPerPage,
  totalOrganizationCount,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOrganizationClick,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 460 }}>
        <Table stickyHeader aria-label="form templates table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  width: "30%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizationList?.map((organization) => {
              return (
              <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={organization.id}
                  onClick={(event) =>
                    handleOrganizationClick(event, organization)
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <TableCell align="left" sx={{ width: "30%" }}>
                    {organization.name}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalOrganizationCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
          }}
        />
      </Box>
    </Box>
  );
};

export default TableList;
