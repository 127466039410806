import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog.jsx";
import { deleteTest } from "../../redux/features/test";
import { deleteFormTemplate } from "../../redux/features/form_template.js";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const TableList = ({
  filteredTests,
  page,
  rowsPerPage,
  totalTests,
  handleChangePage,
  handleChangeRowsPerPage,
  handleTestListItemClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const renderTags = (tags) => {
    return tags.map((tag) => tag.title).join(", ");
  };

  const handleDeleteTestClick = (event, test) => {
    event.preventDefault();
    setSelectedTest(test);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedTest(null);
  };

  const handleDeleteConfirmation = async () => {
    if (selectedTest) {
      await dispatch(deleteTest({ testId: selectedTest.id }));
      setOpen(false);
      navigate("/tests");
    }
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 830 }}>
        <Table stickyHeader aria-label="tests table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  width: "30%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Title
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "30%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Version
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Status
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Tags
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: "20%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTests.map((test) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={test.id}
                onClick={() => handleTestListItemClick(test)}
                sx={{
                  cursor: "pointer",
                }}
              >
                <TableCell align="left" sx={{ width: "20%" }}>
                  {test.title}
                </TableCell>
                <TableCell align="left" sx={{ width: "20%" }}>
                  {test.version}
                </TableCell>
                <TableCell align="left" sx={{ width: "20%" }}>
                  {test.status}
                </TableCell>
                <TableCell align="left" sx={{ width: "20%" }}>
                  {renderTags(test.labels)}
                </TableCell>
                <TableCell align="left" sx={{ width: "20%" }}>
                  <Tooltip title="Delete">
                    <IconButton
                      sx={{ p: "10px" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteTestClick(event, test);
                      }}
                      id={test.id}
                    >
                      <DeleteOutlinedIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteConfirmationDialog
        open={open}
        handleCancel={handleCancel}
        titleText="Confirm Delete"
        contentText="Are you sure you want to delete"
        title={selectedTest?.title}
        suffixText="from tests list?"
        handleDelete={handleDeleteConfirmation}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalTests}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
          }}
        />
      </Box>
    </Box>
  );
};

export default TableList;
