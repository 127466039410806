import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  InputBase,
  IconButton,
  Button,
  Link,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllTests } from "../../redux/features/test.js";
import GradientCircularProgress from "../../elements/GradientCircularProgress";
import EmptyList from "../common/EmptyList.jsx";
import { getAllTags } from "../../redux/features/tag.js";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import TableList from "./TableList.jsx";

const Tests = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tests = useSelector((state) => state.test.testsList);
  const isLoading = useSelector((state) => state.test.isLoading);
  const totalTestsCount = useSelector((state) => state.test.totalTestsCount);
  const tagsList = useSelector((state) => state.tag.tagsList);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchTags, setSearchTags] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [focused, setFocused] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleTagChange = (event, selectedTag) => {
    if (selectedTag) {
      setSelectedTag(selectedTag);
      setSearchTags(true);
    } else {
      setSelectedTag(null);
      setSearchTags(false);
    }
  };

  const handleTestListItemClick = (test) => {
    navigate(`/tests/test-details/${test.id}`);
  };

  useEffect(() => {
    dispatch(
      getAllTests({
        searchQuery: selectedTag ? selectedTag.title : searchQuery,
        searchTags: searchTags,
        page: page,
        rowsPerPage: rowsPerPage,
      })
    );
  }, [dispatch, page, rowsPerPage, searchQuery, searchTags, selectedTag]);

  useEffect(() => {
    dispatch(getAllTags(""));
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query === "") {
      setSearchTags(false);
    }
  };

  const handleArchivedChange = () => {
    setShowArchived(!showArchived);
  };

  const filteredTests = tests.filter((test) =>
    showArchived ? test.archive : true
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Tests
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
          }}
        >
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search test"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search test" }}
              type="search"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Paper>

          <Link>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/tests/new-test");
              }}
            >
              Create Test
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          columnGap: 2,
          marginTop: 2,
        }}
      >
        <Autocomplete
          id="tags-filters"
          options={tagsList}
          componentsProps={{ popper: { style: { width: 300 } } }}
          getOptionLabel={(option) => option.title}
          value={selectedTag}
          onChange={handleTagChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Filter by tags"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterListOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: focused ? 250 : 150,
                transition: "width 0.3s",
                "& .MuiOutlinedInput-root": {
                  border: "none",
                  "& fieldset": {
                    border: "none",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "0px !important",
                  },
                },
                "& .MuiAutocomplete-popupIndicator": {
                  display: "none",
                },
              }}
            />
          )}
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "0px !important",
              paddingRight: (focused || selectedTag) && "32px !important",
            },
          }}
        />
        <FormControlLabel
          control={
            <Checkbox checked={showArchived} onChange={handleArchivedChange} />
          }
          label="Archived"
        />
      </Box>

      {isLoading && !searchQuery && filteredTests.length === 0 ? (
        <GradientCircularProgress open={isLoading} />
      ) : filteredTests && filteredTests.length === 0 ? (
        <EmptyList
          messageHeader={
            searchQuery ? "No tests found." : "No tests available."
          }
          message={
            searchQuery
              ? "Try adjusting your search or"
              : "It looks like there are no tests added yet. To get started,"
          }
          buttonText="create a test"
          route="/tests/new-test"
        />
      ) : (
        <TableList
          filteredTests={filteredTests}
          page={page}
          rowsPerPage={rowsPerPage}
          totalTests={totalTestsCount}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleTestListItemClick={handleTestListItemClick}
        />
      )}
    </Box>
  );
};

export default Tests;
