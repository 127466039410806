import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EmptyListMessage from "./common/EmptyListMessage";

const Dashboard = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>
        Dashboard
      </Typography>
      <EmptyListMessage
        messageHeader="Feature Under Development"
        message="The dashboard page feature is under development, and will be ready for testing soon."
      />
    </Box>
  );
};

export default Dashboard;
