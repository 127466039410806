import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../constants/urls";
import { getToken } from "../../auth/AuthToken";
import { toast } from "react-toastify";

// Initial state
const initialState = {
  organizationList: [],
  currentOrganization: null,
  isLoading: false,
  totalOrganizationCount: 0,
};

// Async thunk for creating a new organization
export const createOrganization = createAsyncThunk(
  "organizations/createOrganization",
  async ({ organization }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/organizations`;

    try {
      const response = await axios.post(url, organization, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for updating an existing organization
export const editOrganization = createAsyncThunk(
  "organizations/editOrganization",
  async ({ organization, organizationId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/organizations/${organizationId}`;

    try {
      const response = await axios.put(url, organization, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteOrganization = createAsyncThunk(
  "organizations/deleteOrganization",
  async ({ organizationId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/organizations/${organizationId}`;

    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      toast.success("Organization deleted successfully");
      return { organizationId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOrganization = createAsyncThunk(
  "organizations/getOrganization",
  async ({ organizationId }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = apiBaseUrl + `/organizations/${organizationId}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for getting all organizations
export const getAllOrganization = createAsyncThunk(
  "organizations/getAllOrganization",
  async ({ searchQuery, page, rowsPerPage }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = apiBaseUrl + `/organizations?q=${searchQuery}&page=${page + 1
      }&per_page=${rowsPerPage}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Organization slice
export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllOrganization.fulfilled, (state, action) => {
        state.organizationList = action.payload.organizations;
        state.totalOrganizationCount = action.payload.meta.total_count;
        state.isLoading = false;
      })
      .addCase(getAllOrganization.rejected, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createOrganization.pending, (state) => { })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.organizationList.push(action.payload);
        state.isLoading = false;
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        state.organizationList = state.organizationList.filter(
          (organization) => organization.id !== action.payload.organizationId
        );
      })
      .addCase(getOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.currentOrganization = action.payload;
        state.isLoading = false;
      })
      .addCase(getOrganization.rejected, (state, action) => {
        state.isLoading = true;
      });
  },
});

export default organizationSlice.reducer;
