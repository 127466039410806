import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { getToken } from "../../auth/AuthToken";
import { apiBaseUrl } from "../../constants/urls";
import { Stimulsoft } from "stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor";
import "stimulsoft-reports-js/Scripts/stimulsoft.designer";
import "stimulsoft-reports-js/Scripts/stimulsoft.reports";

const StimulsoftReportDesigner = ({ reportFile }) => {
  const designerRef = useRef(null);
  const [reportData, setReportData] = useState(null);
  const bearerToken = getToken();
  const url = `${apiBaseUrl}/tests/8`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        setReportData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (reportData) {
      const designer = new Stimulsoft.Designer.StiDesigner(
        null,
        "StiDesigner",
        false
      );

      const report = new Stimulsoft.Report.StiReport();
      report.loadFile(reportFile);

      const dataSet = new Stimulsoft.System.Data.DataSet();
      dataSet.readJson(reportData);

      report.regData('DataSource', 'DataSource', dataSet);
      // report.dictionary.synchronize();

      designer.report = report;
      designer.renderHtml(designerRef.current);
    }
  }, [reportData, reportFile]);

  return <div ref={designerRef} style={{ width: "100%", height: "100vh" }} />;
};

export default StimulsoftReportDesigner;
