import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { palette } from "../../theme/palette.js";
import CompanyForm from "./CompanyForm.jsx";
import { getCompany } from "../../redux/features/company.js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GradientCircularProgress from "../../elements/GradientCircularProgress.jsx";

const EditCompany = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const company = useSelector((state) => state.company.currentCompany);
  const isLoading = useSelector((state) => state.company.isLoading);

  useEffect(() => {
    dispatch(getCompany({ companyId: id }));
  }, [dispatch, id]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Edit Account
        </Typography>
      </Box>
      {isLoading ? (
        <GradientCircularProgress open={isLoading} />
      ) : (
        <CompanyForm company={company} />
      )}
    </Box>
  );
};

export default EditCompany;
