import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import ReactQuill from "react-quill";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, FormControlLabel } from "@mui/material";
import { palette } from "../../theme/palette.js";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createNewUser, editUser } from "../../redux/features/user.js";
import { getAllGroups } from "../../redux/features/group.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { states, gender } from "../../constants/data.js";
import { DateField } from "@mui/x-date-pickers/DateField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTheme } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";

const WorkerForm = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDemographics, setShowDemographics] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const groups = useSelector((state) => state.group?.groupsList);
  const theme = useTheme();

  useEffect(() => {
    dispatch(
      getAllGroups({
        searchQuery: "",
        page: "",
        rowsPerPage: "",
      })
    );
  }, [dispatch]);

  const [newWorker, setNewWorker] = useState({
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    subject_code: user?.subject_code || "",
    gender: user?.gender || "",
    date_of_birth: user?.date_of_birth || "",
    comments: user?.comments || "",
    enabled: user?.enabled || false,
    email: user?.email || "",
    pin_number: user?.pin_number || "",
    confirm_pin: user?.confirm_pin || "",
    address: user?.address || "",
    address_2: user?.address_2 || "",
    city: user?.city || "",
    state: user?.state || "",
    zip_code: user?.zip_code || "",
    phone: user?.phone || "",
    phone_2: user?.phone_2 || "",
    fax: user?.fax || "",
    password: "ashdhsfs",
    role_ids: ["8"],
    subjects_group_ids: user?.subjects_group_ids,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "enabled") {
      setNewWorker((prevWorker) => ({
        ...prevWorker,
        [name]: event.target.checked,
      }));

      return;
    } else {
      setNewWorker({
        ...newWorker,
        [name]: value,
      });
    }
  };

  const handleDateChange = (newValue) => {
    setNewWorker((prevWorker) => ({
      ...prevWorker,
      date_of_birth: newValue ? newValue.format("MM/DD/YYYY") : null,
    }));
  };

  const handleCommentsChange = (value) => {
    setNewWorker((prevState) => ({
      ...prevState,
      comments: value,
    }));
  };

  const handleWorkerSubmit = async (event) => {
    event.preventDefault();

    if (newWorker.first_name === "") {
      toast.error("Please enter First Name");
    } else if (newWorker.last_name === "") {
      toast.error("Please enter Last Name");
    } else if (newWorker.gender === "") {
      toast.error("Please select Gender");
    } else if (newWorker.date_of_birth === "") {
      toast.error("Please enter DOB");
    } else {
      const formData = new FormData();
      formData.append("user[first_name]", newWorker.first_name);
      formData.append("user[last_name]", newWorker.last_name);
      formData.append("user[subject_code]", newWorker.subject_code);
      formData.append("user[gender]", newWorker.gender);
      formData.append("user[date_of_birth]", newWorker.date_of_birth);
      formData.append("user[comments]", newWorker.comments);
      formData.append("user[enabled]", newWorker.enabled);
      formData.append("user[email]", newWorker.email);
      formData.append("user[pin_number]", newWorker.pin_number);
      formData.append("user[confirm_pin]", newWorker.confirm_pin);
      formData.append("user[address]", newWorker.address);
      formData.append("user[address_2]", newWorker.address_2);
      formData.append("user[city]", newWorker.city);
      formData.append("user[state]", newWorker.state);
      formData.append("user[zip_code]", newWorker.zip_code);
      formData.append("user[phone]", newWorker.phone);
      formData.append("user[phone_2]", newWorker.phone_2);
      formData.append("user[fax]", newWorker.fax);
      formData.append("user[password]", newWorker.password);

      newWorker.role_ids.forEach((role, index) => {
        formData.append(`user[role_ids][${index}]`, role);
      });

      if (newWorker?.subjects_group_ids?.length > 0) {
        newWorker?.subjects_group_ids?.forEach((id, index) => {
          formData.append(`user[subjects_group_ids][${index}]`, id);
        });
      } else {
        formData.append(`user[subjects_group_ids][]`, "");
      }

      try {
        if (user === null) {
          await dispatch(createNewUser(formData)).unwrap();
          toast.success("Worker added successfully");
        } else {
          await dispatch(
            editUser({ user: formData, user_id: user.id })
          ).unwrap();
          toast.success("Worker updated successfully");
        }
        navigate("/workers");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const handleDelete = (event, name, value) => {
    event.preventDefault();
    setNewWorker((prev) => ({
      ...prev,
      [name]: prev[name].filter((id) => id !== value), // Remove the deleted value
    }));
  };

  function getStyles(examiner, examiners, theme) {
    return {
      fontWeight:
        examiners?.indexOf(examiner) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <Box>
      <Accordion style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          selected={showDemographics}
          onClick={() => setShowDemographics(!showDemographics)}
        >
          Demographics
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            columnSpacing={10}
            rowSpacing={4}
            paddingRight={20}
            paddingTop={2}
          >
            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                First Name
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: "2px" }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                value={newWorker.first_name}
                fullWidth
                id="first_name"
                name="first_name"
                placeholder="First Name"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>
            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Last Name
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: "2px" }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                value={newWorker.last_name}
                fullWidth
                id="last_name"
                name="last_name"
                placeholder="Last Name"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Worker Code
              </Typography>
              <TextField
                value={newWorker.subject_code}
                fullWidth
                id="subject_code"
                name="subject_code"
                placeholder="Worker Code"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Gender
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: "2px" }}
                >
                  *
                </Typography>
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={newWorker.gender}
                  label="Gender"
                  onChange={handleChange}
                >
                  {gender &&
                    gender.map((g) => (
                      <MenuItem key={g.value} value={g.value}>
                        {g.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "2px",
                  fontWeight: 700,
                }}
              >
                DOB
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: "2px" }}
                >
                  *
                </Typography>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateField"]}>
                  <DateField
                    value={
                      newWorker.date_of_birth
                        ? dayjs(newWorker.date_of_birth, "MM/DD/YYYY")
                        : null
                    }
                    onChange={handleDateChange}
                    fullWidth
                    id="date_of_birth"
                    name="date_of_birth"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Comments
              </Typography>
              <ReactQuill
                value={newWorker.comments}
                fullWidth
                id="comments"
                name="comments"
                placeholder="Comments"
                onChange={handleCommentsChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleChange(e, null)}
                    value={newWorker.enabled}
                    checked={newWorker.enabled}
                    id="enabled"
                    name="enabled"
                    color="primary"
                  />
                }
                label="Enabled"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          selected={showContactInfo}
          onClick={() => setShowContactInfo(!showContactInfo)}
        >
          Contact Info
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            columnSpacing={10}
            rowSpacing={4}
            paddingRight={20}
            paddingTop={2}
          >
            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Email
                <Typography
                  component="span"
                  sx={{ color: "red", marginLeft: "2px" }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                value={newWorker.email}
                fullWidth
                id="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>
            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                PIN Number
              </Typography>
              <TextField
                value={newWorker.pin_number}
                fullWidth
                id="pin_number"
                name="pin_number"
                placeholder=" PIN Number"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Confirm PIN
              </Typography>
              <TextField
                value={newWorker.confirm_pin}
                fullWidth
                id="confirm_pin"
                name="confirm_pin"
                placeholder="Confirm PIN"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>
            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Address
              </Typography>
              <TextField
                value={newWorker.address}
                fullWidth
                id="address"
                name="address"
                placeholder="Address"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Address 2
              </Typography>
              <TextField
                value={newWorker.address_2}
                fullWidth
                id="address_2"
                name="address_2"
                placeholder="Address 2"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                City
              </Typography>
              <TextField
                value={newWorker.city}
                fullWidth
                id="city"
                name="city"
                placeholder="City"
                onChange={handleChange}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>
            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                State
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="state"
                  id="state"
                  name="state"
                  value={newWorker.state}
                  label="State"
                  onChange={handleChange}
                >
                  {states &&
                    states.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Zip Code
              </Typography>
              <TextField
                value={newWorker.zip_code}
                fullWidth
                type="text"
                id="zip_code"
                name="zip_code"
                placeholder="Zip Code"
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  if (value.length <= 9) {
                    const formattedValue =
                      value.length > 5
                        ? `${value.slice(0, 5)}-${value.slice(5, 9)}`
                        : value;
                    handleChange({
                      target: { name: "zip_code", value: formattedValue },
                    });
                  }
                }}
                inputProps={{ maxLength: 10 }}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Phone
              </Typography>
              <TextField
                value={newWorker.phone}
                fullWidth
                id="phone"
                name="phone"
                placeholder="Phone Number"
                type="text"
                inputProps={{ maxLength: 14 }}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  if (value.length <= 10) {
                    if (value.length > 6) {
                      value = `(${value.slice(0, 3)}) ${value.slice(
                        3,
                        6
                      )}-${value.slice(6, 10)}`;
                    } else if (value.length > 3) {
                      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
                    } else if (value.length > 0) {
                      value = `(${value.slice(0, 3)}`;
                    }
                    handleChange({ target: { name: "phone", value } });
                  }
                }}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Phone 2
              </Typography>
              <TextField
                value={newWorker.phone_2}
                fullWidth
                id="phone_2"
                name="phone_2"
                placeholder="Phone Number"
                type="text"
                inputProps={{ maxLength: 14 }}
                // onChange={handleChange}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  if (value.length <= 10) {
                    if (value.length > 6) {
                      value = `(${value.slice(0, 3)}) ${value.slice(
                        3,
                        6
                      )}-${value.slice(6, 10)}`;
                    } else if (value.length > 3) {
                      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
                    } else if (value.length > 0) {
                      value = `(${value.slice(0, 3)}`;
                    }
                    handleChange({ target: { name: "phone_2", value } });
                  }
                }}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                FAX
              </Typography>
              <TextField
                value={newWorker.fax}
                fullWidth
                id="fax"
                name="fax"
                placeholder="FAX"
                type="text"
                inputProps={{ maxLength: 14 }}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  if (value.length <= 10) {
                    if (value.length > 6) {
                      value = `(${value.slice(0, 3)}) ${value.slice(
                        3,
                        6
                      )}-${value.slice(6, 10)}`;
                    } else if (value.length > 3) {
                      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
                    } else if (value.length > 0) {
                      value = `(${value.slice(0, 3)}`;
                    }
                    handleChange({ target: { name: "fax", value } });
                  }
                }}
                sx={{
                  marginTop: 0,
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          selected={showGroups}
          onClick={() => setShowGroups(!showGroups)}
        >
          Groups
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} paddingRight={2} paddingTop={2}>
            <Grid item xs={12} md={6}>
              <Typography
                color={theme.palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  fontWeight: 700,
                }}
              >
                Group
              </Typography>

              <FormControl fullWidth>
                <Select
                  id="subjects_group_ids"
                  name="subjects_group_ids"
                  multiple // Enable multiple selections
                  value={newWorker?.subjects_group_ids || []} // Ensure value is an array
                  input={<OutlinedInput label="Groups" />}
                  onChange={handleChange}
                  renderValue={(selected) => (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        maxWidth: "100%",
                        overflow: "hidden",
                      }}
                    >
                      {selected.map((value) => {
                        const group = groups.find((g) => g.id === value);
                        return (
                          <Chip
                            key={value}
                            label={group?.name} // Display the name of the group
                            style={{
                              margin: 2,
                              maxWidth: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            onDelete={(event) => {
                              event.stopPropagation(); // Prevent Select from closing
                              handleDelete(event, "subjects_group_ids", value);
                            }}
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        );
                      })}
                    </div>
                  )}
                  MenuProps={MenuProps} // Ensure you have defined MenuProps for proper styling
                >
                  {groups.map((group) => (
                    <MenuItem
                      key={group.id}
                      value={group.id}
                      style={getStyles(
                        group.id,
                        newWorker.subjects_group_ids,
                        theme
                      )}
                    >
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginTop: 5,
          minHeight: "100%",
          columnGap: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={handleWorkerSubmit}
          sx={{ width: 160 }}
        >
          Save Worker
        </Button>
      </Box>
    </Box>
  );
};

export default WorkerForm;
